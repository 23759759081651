import React from 'react';
import { ICardReservationDetails } from './CardReservationDetails.interfaces';
import {
  StyledFlightCard,
  StyledContainer,
  TopSectionContainer,
  SmallDivider,
  IconText,
  AircraftContainer,
  AircraftTextContainer,
  ButtonContainer,
  StyledButton,
  StyledIcon,
} from './CardReservationDetails.styles';
import { Typography, ImageImgix, Button } from '@atoms';
import { typography } from '@constants/styles/typography.constants';
import { solidColors } from '@constants/styles/colors.constants';
import FlightTripSection from '../FlightTripSection/FlightTripSection';
import { saveDataInLocalStorage } from '@components/utils/storage';
import { useNavigate } from 'react-router-dom';
import { useViewport } from '@hooks';
import FlightItemsSection from '../FlightItemsSection/FlightItemsSection';
import { IItemObj } from '../FlightItemsSection/FlightItemsSection.interfaces';

export default function CardReservationDetails({
  id,
  flight,
  items,
  centerIconImage,
  date,
  tripType,
  multiFlightCopy,
  button,
  surfaceAction,
}: ICardReservationDetails) {
  const navigate = useNavigate();
  const { width } = useViewport();

  const aircraftImage =
    flight.aircraft && flight.aircraft.image
      ? flight.aircraft.image.url
      : '';

  const aircraftImageTint =
    flight.aircraft && flight.aircraft.image
      ? flight.aircraft.image.tintColor
      : '';

  const upperTripType = tripType.toUpperCase();

  const itemsObj: IItemObj = {};
  items.map(
    (item) =>
      (itemsObj[item.type] = {
        title: item.title ?? '',
        copy: item.copy,
        image: item.image,
        isBlue: true,
      }),
  );

  if (!flight || !items) {
    return null;
  }

  const handleOnClick = (_event?: React.MouseEvent) => {
    _event?.stopPropagation();
    saveDataInLocalStorage(
      'tripDetails',
      surfaceAction.data as string,
    );
    if (
      surfaceAction.data?.includes('//reservationDetails')
    ) {
      const btnUrl = new URL(surfaceAction.data || '');
      const tripId = btnUrl.searchParams.get('id');
      navigate(`/my-trips/${tripId}`);
    }
  };

  return (
    <StyledFlightCard
      data-name="CardReservationDetails"
      className="CardReservationDetails"
      onClick={surfaceAction?.data ? handleOnClick : null}
      isClickable={surfaceAction?.data ? true : false}
    >
      <TopSectionContainer>
        <Typography
          variant={typography.body2Bold}
          color={solidColors.midnight}
        >
          {upperTripType}
        </Typography>
        <SmallDivider />
        <IconText
          variant={typography.body2}
          color={solidColors.midnight}
        >
          {date}
        </IconText>
      </TopSectionContainer>
      <StyledContainer>
        <FlightTripSection
          {...flight}
          centerIconImage={centerIconImage}
          isBlue
          multiFlightCopy={multiFlightCopy}
        />
        {width > 960 && (
          <FlightItemsSection {...itemsObj} />
        )}
        {width > 600 && (
          <>
            <AircraftContainer>
              <ImageImgix
                alt="aircraft"
                src={aircraftImage}
                filter={aircraftImageTint}
                width={103}
                height={37}
              />
              <AircraftTextContainer>
                <Typography
                  variant={typography.body2}
                  color={solidColors.midnight}
                >
                  {flight.aircraft?.category}
                </Typography>
                <Typography variant={typography.body2}>
                  {flight.aircraft?.name}
                </Typography>
                {button && <StyledButton {...button} />}
              </AircraftTextContainer>
            </AircraftContainer>
            <>
              {surfaceAction?.data && (
                <>
                  <ButtonContainer onClick={handleOnClick}>
                    {/*
                                            leaving the below commented out on purpose
                                            This was a request by design and to the best of my knowledge,
                                            this is never updated in athena and we have been showing the default
                                        */}
                    {/* {button?.image ?
                                            <ImageImgix
                                                alt="button"
                                                src={
                                                    buttonImage
                                                }
                                                filter={
                                                    buttonImageTint
                                                }
                                                width={20}
                                                height={20}
                                            />
                                        : ( */}
                    <Button
                      width="20px !important"
                      styleType="icon_no_padding"
                      nativeIcon={
                        <StyledIcon
                          color={solidColors.upBlue.color}
                        />
                      }
                      action={{
                        actionMethod: handleOnClick,
                      }}
                    />
                  </ButtonContainer>
                </>
              )}
            </>
          </>
        )}
      </StyledContainer>
    </StyledFlightCard>
  );
}
