import { pageInitialState } from '@components/types';
import { pegasusClient } from '@services/apiService';
import {
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';
import { ISliceFactoryArgs } from '@services/sliceFactoryService/sliceFactoryService.types';

interface IProps {
  resPayload?: unknown;
  queryParams?: string;
  token?: string;
  retry?: boolean;
}

export function postBookOpportunity(
  endpoint1: ISliceFactoryArgs,
  endpoint2: ISliceFactoryArgs,
) {
  return createAsyncThunk(
    `${endpoint1.pageName}/post${endpoint1.pageName}`,
    async (props: IProps) => {
      const { resPayload, token } = props;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          WUToken: token,
        },
      };
      try {
        const postBookOpportunity = (
          await pegasusClient.post(
            `${endpoint1.endpoint}?version=${endpoint1.version}&platform=web`,
            resPayload,
            config,
          )
        ).data;

        const data = (
          await pegasusClient.post(
            `${endpoint2.endpoint}?version=${endpoint2.version}&platform=web`,
            postBookOpportunity.data,
            config,
          )
        ).data;
        return data;
      } catch (err) {
        return err;
      }
    },
  );
}
export function bookOpportunity({
  pageName,
  endpoint,
  version,
}: ISliceFactoryArgs) {
  const bookOpportunityThunk = postBookOpportunity(
    {
      endpoint: endpoint,
      pageName: pageName,
      version: version,
    },
    {
      endpoint: endpoint,
      pageName: pageName,
      version: version,
    },
  );

  const confirmationSlice = createSlice({
    name: 'requestQuote',
    initialState: pageInitialState,
    reducers: {
      getPage(state, action) {
        const { payload } = action;
        state.data = payload;
      },
    },
    extraReducers: (builder) => {
      builder.addCase(
        bookOpportunityThunk.pending,
        (state) => {
          state.isLoading = true;
        },
      );
      builder.addCase(
        bookOpportunityThunk.fulfilled,
        (state, action) => {
          const { payload } = action;
          state.isLoading = false;
          state.data = payload.data;
        },
      );
      builder.addCase(
        bookOpportunityThunk.rejected,
        (state, action) => {
          const { payload } = action;
          state.isLoading = false;
          state.error = payload;
        },
      );
    },
  });
  return confirmationSlice.reducer;
}
