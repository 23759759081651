import { InputAdornment } from '@components/library';
import PageBackground from '@atoms/PageBackground/PageBackground';
import styled from 'styled-components';
import { CardAtom, TextField } from '@atoms';
import { solidColors } from '@constants/styles/colors.constants';

export const PassengerSelectionSection = styled.section`
  display: flex;
  flex-direction: column;
`;

export const PageBackgroundStyled = styled(PageBackground)`
  text-align: center;
  align: center;
`;

export const TitleContainer = styled.div`
  padding-bottom: 1rem;
  border-bottom: 1px solid ${solidColors.mediumGray.color};
`;

export const ListPaperContainer = styled(CardAtom)`
  width: 100%;
  padding: 0 2rem 2rem 2rem;
  ${(props) => props.theme.breakpoints.down('sm')} {
    padding: 0 1.5rem 1.5rem 1.5rem;
  }
  align: center;
  margin: 1rem auto 0 auto;
  min-width: 100%;
`;

export const ContainerStyled = styled.div`
  padding-top: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: center;
  height: auto;
  margin: 0 auto 0 auto;
  width: 100%;
`;

export const SearchTextFieldStyled = styled(TextField)`
  width: 100%;
`;

export const LeadPassengerButtonContainer = styled.div`
  margin-left: 32px;
  display: flex;
  justify-content: center;
`;

export const PageActionButtonContainer = styled.div`
  width: 100%;
  margin: 3rem 0;
  display: flex;
  gap: 1rem;
  justify-content: flex-end;

  > div {
    width: 12.5rem;
  }

  button {
    cursor: pointer;
  }

  ${(props) => props.theme.breakpoints.down('sm')} {
    > div {
      width: 100%;
    }
  }
`;

export const PassengerActionButtonsContainer = styled.div`
  width: 101%;
  position: sticky;
  bottom: 0;
  padding: 2rem 0;
  margin-top: 3rem;
  background-color: ${solidColors.upWhite.color};
  border-top: 1px solid ${solidColors.mediumGray.color};
  align-self: center;
  display: flex;
  gap: 1rem;
  justify-content: flex-end;

  > div {
    width: 12.5rem;
    cursor: pointer;
  }

  button {
    cursor: pointer;
  }

  ${(props) => props.theme.breakpoints.down('sm')} {
    > div {
      width: 100%;
    }
  }
`;

export const RemoveButtonContainer = styled.div`
  width: 100%;
  align: center;
  display: flex;
  justify-content: center;
`;

export const StyledAdornment = styled(InputAdornment)<{
  $focused?: boolean;
}>`
    color: ${(props) =>
      props.$focused
        ? solidColors.midnight.color
        : solidColors.darkGray.color};
    };
`;

export const StyledEndAdornment = styled(InputAdornment)<{
  $display?: string;
}>`
  display: ${(props) => props.$display};
  color: ${solidColors.upBlue.color};
`;
