import CheckboxChecked from '@atoms/CheckboxChecked/CheckboxChecked';
import CheckboxUnchecked from '@atoms/CheckboxUnchecked/CheckboxUnchecked';
import { FormControlLabel, Checkbox } from '@library';
import { ICheckbox } from './Checkbox.interfaces';
import { solidColors } from '@constants/styles/colors.constants';

const CustomCheckbox = ({
  checked,
  label,
  disabled = false,
  onChange,
}: ICheckbox) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          id={label}
          checked={checked}
          requried={true}
          onChange={onChange}
          icon={
            <CheckboxUnchecked
              borderColor={
                disabled
                  ? solidColors.mediumGray.color
                  : solidColors.midnight.color
              }
            />
          }
          checkedIcon={<CheckboxChecked />}
          disabled={disabled}
        />
      }
      label={label}
    />
  );
};

export default CustomCheckbox;
