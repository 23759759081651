import { IImgTitleCopyStepperData } from '../ImageTitleSubtitleStepperListItemView/ImageTitleSubtitleStepperListItemView.interfaces';

export function shouldShowLuggageInput(luggage: IImgTitleCopyStepperData): boolean {
    const isInCorrectLocation = window.location.pathname === '/fly/search-trip-details' || window.location.pathname.includes('manage-luggage');
    const isCorrectLuggageType = luggage.data.codeValue === 'OVERSIZE' || luggage.data.codeValue === 'MISC';

    return isInCorrectLocation && isCorrectLuggageType;
}

export function setLuggageErrorData(luggage: IImgTitleCopyStepperData, count: number): boolean {
    const isCorrectLuggageType = luggage.data.codeValue === 'OVERSIZE' || luggage.data.codeValue === 'MISC';
    const hasNoNote = !luggage.data.note;
    const hasCountGreaterThanZero = count > 0;

    return isCorrectLuggageType && hasNoNote && hasCountGreaterThanZero;
}

