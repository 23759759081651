import { useAppSelector } from '@app/hooks';
import { setAirmedLegalFlag } from '@features/Fly/Payment/PaymentStatusSlice';
import { useDispatch } from 'react-redux';
import CustomCheckbox from '../Checkbox/Checkbox';
import TexGroupBodyCopyLink from '../TextGroupBodyCopyLink/TextGroupBodyCopyLink';
import { ICheckboxBodyCopyLink } from './TextGroupCheckboxRichLink.interfaces';

const TextGroupCheckboxRichLink = ({
  titleTextGroup,
  onChange,
  isPreselected,
  isEnabled = true,
  keys,
}: ICheckboxBodyCopyLink) => {
  const dispatch = useDispatch();

  const { airmedLegalFlag } = useAppSelector(
    (state) => state.paymentStatus,
  );

  const onCheck = () => {
    if (keys && airmedLegalFlag.includes(keys[0])) {
      const newAirmedLegalFlag = airmedLegalFlag.filter(
        (flag) => flag !== keys[0],
      );
      dispatch(setAirmedLegalFlag(newAirmedLegalFlag));
    } else {
      dispatch(
        setAirmedLegalFlag([
          ...airmedLegalFlag,
          ...(keys || []),
        ]),
      );
    }
  };

  const isChecked = (isPreselected: boolean) => {
    if (keys) {
      return (
        isPreselected || airmedLegalFlag.includes(keys[0])
      );
    } else {
      return isPreselected;
    }
  };

  return (
    <CustomCheckbox
      onChange={onCheck}
      checked={isChecked(isPreselected || false)}
      disabled={!isEnabled}
      label={
        <TexGroupBodyCopyLink
          copy={titleTextGroup.title}
          textAttributes={{
            links: titleTextGroup.textAttributes?.links,
          }}
        />
      }
    />
  );
};

export default TextGroupCheckboxRichLink;
