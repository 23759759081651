import React from 'react';
import {
  CardActionArea,
  CardActions,
  CardContent,
} from '@material-ui/core';
import {
  BaseButton,
  CardAtom,
  CardMedia,
  Typography,
} from '../atoms';
import { typography } from '../../constants/styles/typography.constants';

function Card(props: any) {
  return (
    <CardAtom>
      <CardActionArea>
        <CardMedia {...props} />
        <CardContent>
          <Typography variant={typography.heading01Large}>
            {props.headline}
          </Typography>
          {props.description}
        </CardContent>
      </CardActionArea>
      <CardActions>
        <BaseButton
          size="small"
          color="primary"
          {...props}
        />
      </CardActions>
    </CardAtom>
  );
}

export default Card;
