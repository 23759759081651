import React, { useEffect, useState } from 'react';
import {
  ICardRequestedBookedFlightStatusCard,
  IItemObj,
} from './CardRequestedBookedFlightStatusCard.interfaces';
import {
  StyledFlightCard,
  StyledContainer,
  TopSectionContainer,
  SmallDivider,
  FlightStatus,
  IconText,
  AircraftContainer,
  AircraftTextContainer,
  StyledAircraftDetailsButton,
  TripCTAContainer,
  StyledIcon,
} from './CardRequestedBookedFlightStatusCard.styles';
import { Typography, ImageImgix, Button } from '@atoms';
import { typography } from '@constants/styles/typography.constants';
import { solidColors } from '@constants/styles/colors.constants';
import { useNavigate } from 'react-router-dom';
import FlightTripSection from '../FlightTripSection/FlightTripSection';
import FlightItemsSection from '../FlightItemsSection/FlightItemsSection';
import { saveDataInLocalStorage } from '@components/utils/storage';
import { useViewport } from '@hooks';
import QuickAccessBar from './QuickAccessBar';
import { useDispatch } from 'react-redux';
import { handleShowAircraftDetails } from '@components/organisms/CardAircraftDetailsSubpage/AircraftDetailsSlice';
import { setIsCancelOfferPopUpOpen } from '@features/MyTrips/MyTripsCancelOfferSlice';
import { ColorStyleTranslation } from '@components/utils/newStyleTranslation';
import { theme } from '@constants/styles/theme.constants';

export default function CardRequestedBookedFlightStatusCard({
  id,
  flight,
  flightStatus,
  flightStatusFillColor,
  aircraftTypeLine1,
  aircraftTypeLine2,
  peakDayStatus,
  items,
  centerIconImage,
  button,
  surfaceAction,
  leftQuickAccessButtons,
  rightQuickAccessButtons,
  operatorCopy,
}: ICardRequestedBookedFlightStatusCard) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { width } = useViewport();
  const [inCarousel, setInCarousel] = useState(false);
  const didDepartureDateChange =
    flight.didDepartureDateChange;
  const itemsObj: IItemObj = {};
  let aircraftImage = '';
  let aircraftImageTint = '';
  if (flight?.aircraft?.image) {
    aircraftImage = flight.aircraft.image.url;
    aircraftImageTint =
      flight.aircraft.image.tintColor ?? aircraftImageTint;
  }
  items &&
    items.map((item) => {
      return (itemsObj[item.type] = {
        title: item.title,
        copy: item.copy,
        image: item.image,
        isBlue: true,
      });
    });

  if (!flight || !items) {
    return null;
  }

  useEffect(() => {
    const el = document.getElementById(id ?? '');
    if (el) {
      const parent = el.parentElement;
      if (parent) {
        setInCarousel(
          parent.className.includes(
            'CarouselSmallArrowWebOnlySlide',
          ),
        );
      }
    }
  }, []);

  let showQuickAccessBar = false;
  if (
    (leftQuickAccessButtons &&
      leftQuickAccessButtons.length > 0) ||
    (rightQuickAccessButtons &&
      rightQuickAccessButtons.length > 0)
  ) {
    showQuickAccessBar = true;
  }

  if (!flight || !items) {
    return null;
  }

  const handleOnClick = (_event?: React.MouseEvent) => {
    _event?.stopPropagation();
    saveDataInLocalStorage(
      'tripDetails',
      surfaceAction?.data as string,
    );

    if (
      surfaceAction?.data?.includes('//reservationDetails')
    ) {
      const btnUrl = new URL(surfaceAction?.data || '');
      const tripId = btnUrl.searchParams.get('id');
      navigate(`/my-trips/${tripId}`);
    }
  };

  const handleAircraftDetailsClick = () => {
    if (button?.action.data !== undefined) {
      const dealId = button.action.data.split('=')[1];
      if (
        button.action.data?.includes(
          '//cancelPendingFlight',
        )
      ) {
        dispatch(
          setIsCancelOfferPopUpOpen({
            isOpen: true,
            dealId,
          }),
        );
      }
    } else {
      dispatch(handleShowAircraftDetails());
    }
  };

  const buttonProps = {
    ...button,
    action: {
      actionMethod: handleAircraftDetailsClick,
    },
  };

  return (
    <StyledFlightCard
      id={id}
      data-name="CardRequestedBookedFlightStatusCard"
      className="CardRequestedBookedFlightStatusCard"
      onClick={surfaceAction?.data ? handleOnClick : null}
      $isClickable={surfaceAction?.data ? true : false}
      $inCarousel={inCarousel}
    >
      <div>
        {flight.flightStatus ? (
          <FlightStatus
            $backgroundColor={
              flightStatusFillColor
                ? solidColors[
                    ColorStyleTranslation(
                      flightStatusFillColor,
                    )
                  ].color
                : ''
            }
          >
            {flight.flightStatus && (
              <Typography
                variant={typography.tagLabel}
                libraryColor='interactivePrimary'
              >
                {flight.flightStatus.toUpperCase()}
              </Typography>
            )}
          </FlightStatus>
        ) : (
          <div style={{ marginTop: '32px' }} />
        )}
        <TopSectionContainer data-id="TopSectionContainer">
          <Typography
            variant={typography.body2a}
            color={
              didDepartureDateChange
                ? solidColors.jam
                : solidColors.c18
            }
          >
            {flight.departureDateDisplayValue}
          </Typography>
          <SmallDivider />
          {peakDayStatus && (
            <>
              <Typography
                variant={typography.body2a}
                color={solidColors.c14}
              >
                {peakDayStatus}
              </Typography>
              <SmallDivider />
            </>
          )}
          {itemsObj?.tailNumber?.image?.url && (
            <ImageImgix
              src={
                itemsObj.tailNumber &&
                itemsObj.tailNumber.image
                  ? itemsObj.tailNumber.image.url
                  : ''
              }
              alt="icon"
              width={20}
              height={20}
              filter={
                itemsObj.tailNumber &&
                ColorStyleTranslation(
                  itemsObj.tailNumber.image?.tintColor
                    ? itemsObj.tailNumber.image.tintColor
                    : '',
                )
              }
            />
          )}
          <IconText
            variant={typography.body2a}
            color={
              itemsObj?.tailNumber?.image?.tintColor
                ? solidColors[
                    ColorStyleTranslation(
                      itemsObj.tailNumber.image.tintColor,
                    )
                  ]
                : solidColors.c18
            }
          >
            {itemsObj.tailNumber
              ? itemsObj.tailNumber.copy
              : ''}
          </IconText>
        </TopSectionContainer>
      </div>
      <StyledContainer id="CardRequestedBookedFlightStatusCardFlightTripSection">
        <FlightTripSection
          {...flight}
          centerIconImage={centerIconImage}
          isBlue
        />
        {width > theme.breakpoints.values.md && (
          <FlightItemsSection {...itemsObj} />
        )}
        {width > theme.breakpoints.values.sm && (
          <>
            <AircraftContainer data-test-id="AircraftContainer">
              <ImageImgix
                alt="aircraft"
                src={aircraftImage}
                filter={aircraftImageTint}
                width={103}
                height={37}
              />
              <AircraftTextContainer>
                <Typography
                  variant={typography.body2a}
                  color={solidColors.c18}
                >
                  {aircraftTypeLine1}
                </Typography>
                <Typography variant={typography.body2a}>
                  {aircraftTypeLine2}
                </Typography>
                <Typography
                  variant={typography.body2a}
                  libraryColor='secondary'
                >
                  {operatorCopy ?? ''}
                </Typography>
                {button && (
                  <StyledAircraftDetailsButton
                    data-test-id="StyledAircraftDetailsButton"
                    {...buttonProps}
                    styleType="text_with_icon_trailing"
                    image={undefined}
                  />
                )}
              </AircraftTextContainer>
            </AircraftContainer>
            {surfaceAction && (
              <TripCTAContainer>
                <Button
                  width="20px !important"
                  styleType="icon_no_padding"
                  nativeIcon={<StyledIcon />}
                  action={{
                    actionMethod: handleOnClick,
                  }}
                />
              </TripCTAContainer>
            )}
          </>
        )}
      </StyledContainer>
      {showQuickAccessBar && (
        <QuickAccessBar
          flight={flight}
          tailNumber={itemsObj.tailNumber.copy ?? ''}
          leftQuickAccessButtons={
            leftQuickAccessButtons || []
          }
          rightQuickAccessButtons={
            rightQuickAccessButtons || []
          }
        />
      )}
    </StyledFlightCard>
  );
}
