import { CardAtom } from '@atoms';
import styled from 'styled-components';

export const CardStyled = styled(CardAtom)`
  padding: 1.5rem 2rem;
  margin-bottom: 2rem;
  border-radius: 8px;
`;

export const HeadingStyled = styled.div`
  margin-bottom: 0.5rem;
`;
