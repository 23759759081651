import styled from 'styled-components';
import { Button } from '@atoms';
import { solidColors } from '@constants/styles/colors.constants';

export const StyledSubPageCheckWireTransfer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  border-radius: 4px;
  background-color: #ffffff;
  position: relative;
  height: fit-content;
  min-width: 50%;
  margin: 0px 0px;
`;

export const StyledTitleContainer = styled.div`
  margin: 10px 0px 20px 0px;
`;

export const StyledLine = styled.div<{
  separator?: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  min-width: 600px;

  padding: 8px 0px 10px 0px;

  ${(props) =>
    props.separator &&
    `
        padding-bottom: 10px;
        border-bottom: 1px solid ${solidColors.mediumGray.color};
    `}
`;

export const StyledInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 12px 12px;
  width: 100%;
`;

export const StyledAddEditButton = styled(Button)`
  background-color: ${solidColors.upWhite.color};
  color: ${solidColors.upBlue.color};
`;

export const StyledButtonContainer = styled.div<{
  $first?: boolean;
}>`
  ${({$first}) => $first && `border-top: 1px solid ${solidColors.mediumGray.color};`}
  :last-of-type {
    margin-bottom: 25px;
  }
  width: 100%;
  justify-content: flex-start;
  padding: 20px 10px 20px 1rem;
  border-bottom: 1px solid ${solidColors.mediumGray.color};
  
`;


export const StyledInput = styled.div`
  width: 90px;
`;
