import { Button, Typography } from '@atoms';
import { DialogContent, Modal } from '@components/library';
import styled from 'styled-components';

export const ImageTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 60px 40px;
`;

export const DialogStyle = styled(DialogContent)`
  border: 1px solid green;
`;

export const ImageSection = styled.div`
  margin-right: 10px;
`;

export const HeaderSection = styled.div`
  display: flex;
  padding-right: 50px;
  margin-bottom: 20px;
  justify-content: center;
`;

export const TextSection = styled.div`
  margin-bottom: 20px;
  height: 400px;
`;

export const CheckboxSection = styled.div`
  display: flex;
  text-align: center;
  align-content: center;
`;

export const ButtonContainer = styled.div`
  margin: 10px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

export const OnboardingButton = styled(Button)`
  min-height: 50px;
  margin: 10px 20px;
`;

export const StyledModal = styled(Modal)`
  padding: 10px;
`;

export const CopyTypography = styled(Typography)`
  margin-bottom: 20px;
  font-size: 14px;
  letter-spacing: -0.3px;
  line-height: 24px;
  margin-top: 20px;
`;

export const TermsTypography = styled(Typography)`
  margin-bottom: 20px;
  font-size: 14px;
  letter-spacing: -0.3px;
  line-height: 24px;
  margin-top: 20px;
  margin-left: -15px;
`;

export const StepsTypography = styled(Typography)`
  font-size: 14px;
`;

export const HeadlineTypography = styled(Typography)`
  height: 34px;
  width: auto;
  font-size: 28px;
  letter-spacing: -0.48px;
  line-height: 34px;
`;

export const CloseContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
