import { useState, useEffect, useRef } from 'react';
import { IContainerTripPassengerEditor } from './ContainerTripPassengerEditor.interfaces';
import { Typography } from '@atoms';
import { typography } from '@constants/styles/typography.constants';
import { solidColors } from '@constants/styles/colors.constants';
import {
  StyledCard,
  StyledSubitle,
  PaxContainer,
  StyledCardSearchTripDetails,
} from './ContainerTripPassengerEditor.styles';
import { useAppSelector } from '@app/hooks';
import {
  FlightLegItemPassengerInfo,
  TextGroupPillText,
} from '@molecules';

import { useDispatch } from 'react-redux';
import {
  setShowPassengerListAsCarousel,
  setSamePaxFlag,
  setSelectedPassengers,
  setSelectedPets,
  setAllSelectedPassengers,
  setAllSelectedPets,
  ISelectedPassengerLegs,
  ISelectedPetsLegs,
  setIsEditingPassengers,
} from '@features/Fly/FlySearchTripDetailsSlice';
import CarouselScrollHorizontal from '@components/organisms/CarouselScrollHorizontal/CarouselScrollHorizontal';
import { useViewport } from '@hooks';
import { cloneDeep } from 'lodash';
import { saveDataInLocalStorage } from '@components/utils/storage';

const ContainerTripPassengerEditor = ({
  title,
  subtitle,
  maxNumberOfPassengers,
  isWithinChangeWindow,
  leadPassengerIcon,
  listOfPassengerInfoForEachLeg,
}: IContainerTripPassengerEditor) => {
  const dispatch = useDispatch();
  const { width } = useViewport();
  const ref = useRef<HTMLDivElement>(null);
  const passengersStateData = useAppSelector(
    (state) => state.searchTripDetails,
  );
  const [
    isSamePassengerChecked,
    setIsSamePassengerChecked,
  ] = useState(
    !passengersStateData.showPassengerListAsCarousel,
  );
  const [activeIndex, setActiveIndex] = useState(0);
  const [load, setLoad] = useState(false);
  const [parentWidth, setParentWidth] = useState(0);
  // Fallback pax is used to store the pax data when the user clicks on the same leg button
  const paxClone = cloneDeep(
    passengersStateData.selectedPassengersLeg,
  );
  const petsClone = cloneDeep(
    passengersStateData.selectedPetsLegs,
  );
  const [fallbackPax, setFallbackPax] = useState(paxClone);
  const [fallbackPets, setFallbackPets] = useState(
    petsClone,
  );

  useEffect(() => {
    const parent = document.getElementById(
      'SubPageTripDetailsWebOnly',
    );
    if (parent) {
      setParentWidth(parent.clientWidth);
    }
  }, [width]);

  const handleLegClick = (
    e: React.MouseEvent<HTMLDivElement>,
  ) => {
    e.preventDefault();
    const { id } = e.currentTarget;
    const index = parseInt(id.split('-')[1]);
    setActiveIndex(index);
  };
  
    useEffect(() => {
      if (listOfPassengerInfoForEachLeg === undefined) return;

      /**
       * The logic below is applied either when the user is not in the booking flow
       * or if the users pax are not loaded
       */
      const resetPax = () => {
        const pax: ISelectedPassengerLegs[] = [];
        const pets: ISelectedPetsLegs[] = [];
        listOfPassengerInfoForEachLeg.forEach(({defaultSelectedPassengers, defaultSelectedPets}) => {
          pax.push({selectedPassengers: defaultSelectedPassengers});
          pets.push({selectedPets: defaultSelectedPets});
        });
        dispatch(
          setAllSelectedPassengers(pax),
        );
        dispatch(
          setAllSelectedPets(pets ?? []),
        );
        // adding this to avoid issues in the reservation details flow
        dispatch(setSamePaxFlag(false));
      };

      if (!passengersStateData.isEditingPassengers) {
        // If the user is not editing the pax, then we need to reset the pax with what we get from pegasus
        resetPax();
      } else if (
        passengersStateData.selectedPassengersLeg.length === 0
      ) {
        // If the user is editing the pax, but the pax are not loaded, then we need to reset the pax with what we get from pegasus
        dispatch(setIsEditingPassengers(false));
        saveDataInLocalStorage('isEditingPassengers', false);
        resetPax();
      } 
      else if (passengersStateData.isEditingPassengers && passengersStateData.samePaxFlag) {
        // If the user is editing the pax, and the pax are loaded, then we need to set the pax with what we get from the first leg
        listOfPassengerInfoForEachLeg.forEach(
          (leg, index) => {
            if (index > 0) {
              dispatch(
                setSelectedPassengers({
                  index,
                  selectedPassengers:
                    passengersStateData
                      .selectedPassengersLeg[0],
                }),
              );
              dispatch(
                setSelectedPets({
                  index,
                  selectedPets:
                    passengersStateData.selectedPetsLegs[0],
                }),
              );
            }
          },
        );
        
      }
  
      if (
        listOfPassengerInfoForEachLeg[0]?.flightId !==
          undefined &&
        listOfPassengerInfoForEachLeg?.length > 1
      ) {
        dispatch(setShowPassengerListAsCarousel(true));
        setIsSamePassengerChecked(false);
      }
      setLoad(true);
    }, []);

  const legNames = listOfPassengerInfoForEachLeg?.map(
    (leg, index) => {
      const key = `passengerSlide-${index}`;
      return (
        <div
          key={key}
          id={key}
          onClick={handleLegClick}
        >
          <TextGroupPillText
            copy={leg.flightLegTitle || ''}
            selected={index === activeIndex}
          />
        </div>
      );
    },
  );

  const handleSwitch = () => {
    const samePaxFlag = passengersStateData.samePaxFlag;
    dispatch(setSamePaxFlag(!samePaxFlag));
    if (!samePaxFlag) {
      setActiveIndex(0);
      setFallbackPax(
        passengersStateData.selectedPassengersLeg,
      );
      setFallbackPets(passengersStateData.selectedPetsLegs);
      listOfPassengerInfoForEachLeg.forEach(
        (leg, index) => {
          if (index > 0) {
            dispatch(
              setSelectedPassengers({
                index,
                selectedPassengers:
                  passengersStateData
                    .selectedPassengersLeg[0],
              }),
            );
            dispatch(
              setSelectedPets({
                index,
                selectedPets:
                  passengersStateData.selectedPetsLegs[0],
              }),
            );
          }
        },
      );
    } else {
      dispatch(setAllSelectedPassengers(fallbackPax));
      dispatch(setAllSelectedPets(fallbackPets));
    }
  };

  const Card = window.location.pathname.includes('fly')
    ? StyledCardSearchTripDetails
    : StyledCard;

  return (
    <>
      {load && (
        <Card
          data-name="ContainerTripPassengerEditor"
          id="ContainerTripPassengerEditor"
          ref={ref}
          $width={parentWidth}
          $isWithinChangeWindow={isWithinChangeWindow}
        >
          <Typography
            variant={typography.heading03Large}
            color={solidColors.midnight}
          >
            {title}
          </Typography>
          <StyledSubitle
            variant={typography.body2a}
            color={solidColors.darkGray}
          >
            {subtitle}
          </StyledSubitle>
          {listOfPassengerInfoForEachLeg?.length > 1 && (
            <CarouselScrollHorizontal
              parentWidth={ref?.current?.clientWidth || 1}
              inComponent
            >
              {legNames}
            </CarouselScrollHorizontal>
          )}
          {listOfPassengerInfoForEachLeg.map((leg, idx) => {
            const key = `passengerSlide-${idx}`;
            return (
              activeIndex === idx && (
                <PaxContainer key={key}>
                  <FlightLegItemPassengerInfo
                    flightLegTitle={leg?.flightLegTitle}
                    addPassengerCopy={leg?.addPassengerCopy}
                    addPassengerLeadingIcon={
                      leg?.addPassengerLeadingIcon
                    }
                    defaultSelectedPassengers={
                      leg?.defaultSelectedPassengers
                    }
                    defaultSelectedPets={
                      leg?.defaultSelectedPets
                    }
                    leadPassengerIcon={leadPassengerIcon}
                    samePassengersForAllLegsCopy={
                      leg?.samePassengersForAllLegsCopy
                    }
                    passengerCheckedAction={handleSwitch}
                    showPassengerListAsCarousel={
                      isSamePassengerChecked
                    }
                    quantityOfLegs={
                      listOfPassengerInfoForEachLeg.length
                    }
                    index={activeIndex}
                    maxNumberOfPassengers={
                      maxNumberOfPassengers
                    }
                    flightId={leg?.flightId}
                    isWithinChangeWindow={
                      isWithinChangeWindow
                    }
                  />
                </PaxContainer>
              )
            );
          })}
        </Card>
      )}
    </>
  );
};

export default ContainerTripPassengerEditor;
