import React from 'react';
import { Typography } from '@atoms';
import { typography } from '@constants/styles/typography.constants';
import { solidColors } from '@constants/styles/colors.constants';
import { ITextGroupPillText } from './TextGroupPillText.interfaces';
import { StyledTextGroupPillText } from './TextGroupPillText.styles';

function TextGroupPillText({
  id = 'text-group-pill-text',
  copy,
  selected = true,
  width = 'min-content',
  onClick = () => null,
}: ITextGroupPillText) {
  return (
    <StyledTextGroupPillText
      id={id}
      data-id="text-group-pill-text"
      $selected={selected}
      $width={width}
      onClick={onClick}
    >
      <Typography
        variant={typography.body2Bold}
        color={
          selected
            ? solidColors.upBlue
            : solidColors.midnight
        }
      >
        {copy}
      </Typography>
    </StyledTextGroupPillText>
  );
}

export default TextGroupPillText;
