import { makeStyles } from '@components/library';
import { solidColors } from '@constants/styles/colors.constants';
import { Theme } from '@material-ui/core/styles';

export const useSwitchStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      width: 42,
      height: 26,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(16px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: `${solidColors.upBlue.color}`,
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: `${solidColors.upBlue.color}`,
        border: '6px solid #fff',
      },
    },
    thumb: {
      width: 24,
      height: 24,
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create([
        'background-color',
        'border',
      ]),
    },
    checked: {},
    focusVisible: {},
  }),
);
