import { useState } from 'react';
import { Grid } from '@components/library';
import {
  CustomCheckbox,
} from '@components/molecules';
import TextGroupSwitch from '@components/molecules/TextGroupSwitch/TextGroupSwitch';
import {
  getFromLocalStorage,
  saveDataInLocalStorage,
} from '@components/utils/storage';
import { solidColors } from '@constants/styles/colors.constants';
import { typography } from '@constants/styles/typography.constants';
import {  ModalWithTitle } from '@wheelsup/wu-react-components';
import { Typography } from '@wheelsup/wu-react-components';
import { useFeatureFlags } from '@services/featureFlagService/featureFlagService.services';
import { queryClient } from '@app/App';
import styled from 'styled-components';
import { useKeyboardShortcut } from '@hooks';

const StyledModal = styled(ModalWithTitle)`
  width: 600px;
`;

const DevSettings = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const {data: flags} = useFeatureFlags();

  useKeyboardShortcut({
    keys: ['D'], ctrl: true, shift: true,},
    () => { setModalOpen(!modalOpen); }
  );

  const reduxLoggerEnabledLocal = getFromLocalStorage(
    'REDUX_LOGGER_ENABLED_KEY',
  );
  const [
    reduxLoggerEnabled,
    setReduxLoggerEnabled,
  ] = useState(reduxLoggerEnabledLocal);

  const setFlag = (flag: string) => {
    if (flags && flag in flags) {
      const currFlags = flags as {[key: string]: boolean};
      queryClient.setQueryData(['featureFlags'], {...currFlags, [flag]: !currFlags[flag]});
    }
  };

  return (
    <StyledModal
      state={{
        isOpen: modalOpen,
        setOpen: setModalOpen,
        open: () => setModalOpen(true),
        close: () => setModalOpen(false),
        toggle: () => setModalOpen(!modalOpen),
      }}
      title='Dev Settings'
    >
      <div style={{height: '100%', width: '100%', padding: '1rem 2rem'}}>
        <Typography
          variant='heading04'
        >
          Redux Logger
        </Typography>
          <CustomCheckbox
            label={'Redux Logger Enabled'}
            checked={reduxLoggerEnabled}
            onChange={(e) => {
              setReduxLoggerEnabled(e.target.checked);
              saveDataInLocalStorage(
                'REDUX_LOGGER_ENABLED_KEY',
                e.target.checked,
              );
              document.location.reload();
            }}
          />
      </div>
      <div style={{height: '100%', width: '100%', padding: '1rem 2rem'}}>
        <Typography
          variant='heading04'
        >
          Feature Flags
        </Typography>
        {flags && Object.entries(flags).map(
          ([flag, value]) => {
            return (
              <Grid
                key={flag}
                item
                xs={12}
              >
                <TextGroupSwitch
                  copy={flag}
                  checked={value}
                  onChange={() => setFlag(flag)}
                  direction="right"
                  textStyle={typography.body1}
                  color={solidColors.midnight}
                />
              </Grid>
            );
          },
        )}
        <Typography variant='body02Bold'>A hard refresh will reset the flags.</Typography>
      </div>
      <hr />
    </StyledModal>
  );
};

export default DevSettings;
