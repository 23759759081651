import styled from 'styled-components';
import { Button, CardAtom, Typography } from '@atoms';
import { solidColors } from '@constants/styles/colors.constants';
import { ICardImageCarouselContainer } from './CardImageCarouselHeadlineSubcopyCTA.interfaces';

export const CardMainContainer = styled.div<ICardImageCarouselContainer>`
  display: flex;
  flex-direction: row;
  height: ${({ heightRem }) => heightRem}rem;
  width: ${({ widthRem }) => widthRem}rem;
`;

export const CardContainer = styled.div`
  margin: 3rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: calc(100% - 6rem);
`;

export const CardBody = styled(Typography)`
  margin-top: 2rem;
  flex-grow: 1;
`;

export const CardCTAButton = styled(Button)`
  margin: 0;
`;
export const LeftSideCard = styled(CardAtom)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px 0 0 5px;
  width: 40rem;
  max-width: 40rem;
`;

export const RightSideCard = styled(CardAtom)`
  display: flex;
  background-color: ${solidColors.mediumGray.color};
  border-radius: 0 5px 5px 0;

  width: calc(100% - 18rem);
  max-width: calc(100% - 18rem);
`;
