import { Typography, Button } from '@atoms';
import { solidColors } from '@constants/styles/colors.constants';
import { typography } from '@constants/styles/typography.constants';
import { ICardRequestedQuote } from './CardRequestedQuote.interfaces';
import {
  ButtonContainer,
  FlightStatus,
  FlightTripSectionContainer,
  StyledContainer,
  StyledFlightCard,
  TopSectionContainer,
} from './CardRequestedQuote.styles';
import FlightTripSection from '../FlightTripSection/FlightTripSection';
import { useDispatch } from 'react-redux';
import { IFlightLeg } from '@components/types';
import {
  getUserLocalTime,
  updateEditSearchTab,
  updateLeg,
} from '@features/Fly/searchFlightSlice';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useViewport } from '@hooks';

export default function CardRquestedQuote({
  id,
  flight,
  requestedDate,
  requestedDateFillColor,
  centerIconImage,
  button,
  surfaceAction,
}: ICardRequestedQuote) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { width } = useViewport();

  const handleSearchAgain = (_event: React.MouseEvent) => {
    _event.stopPropagation();
    const flightLeg: IFlightLeg = {
      id: '0',
      departureAirport: flight.fromAirport,
      arrivalAirport: flight.toAirport,
      flightType: 'one-way',
      steps: 3,
      date: new Date(),
      departTime: getUserLocalTime(),
      errors: [],
      numberOfPassengers: 1,
      numberOfPets: 0,
    };

    dispatch(updateEditSearchTab('One way'));
    dispatch(updateLeg([flightLeg]));
    navigate('/fly');
  };

  return (
    <StyledFlightCard
      data-name="CardRquestedQuote"
      onClick={(_event: React.MouseEvent) =>
        handleSearchAgain(_event)
      }
    >
      <FlightStatus
        $backgroundColor={
          requestedDateFillColor
            ? solidColors[requestedDateFillColor].color
            : ''
        }
      >
        {requestedDate && (
          <Typography
            variant={typography.captionBold}
            color={solidColors.upWhite}
          >
            {requestedDate}
          </Typography>
        )}
      </FlightStatus>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <TopSectionContainer>
          <Typography
            variant={typography.body2}
            color={solidColors.darkGray}
          >
            {flight.departureDateDisplayValue}
          </Typography>
        </TopSectionContainer>
      </div>
      <StyledContainer>
        <FlightTripSectionContainer>
          <FlightTripSection
            {...flight}
            centerIconImage={centerIconImage}
            isBlue={false}
          />
        </FlightTripSectionContainer>
        <ButtonContainer>
          <Button
            title={button?.title}
            action={{
              actionMethod: () => null,
            }}
            image={button?.image}
            styleType={
              width > 600
                ? 'card_secondary_web'
                : 'card_primary'
            }
          />
        </ButtonContainer>
      </StyledContainer>
    </StyledFlightCard>
  );
}
