import { BaseButton, Button } from '@atoms';
import { Icons } from '@components/library';
import { solidColors } from '@constants/styles/colors.constants';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const CarouselContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: hidden;
  flex-wrap: nowrap;
  width: 100%;
  height: fit-content;
`;

export const CarouselChild = styled.div<{
  $transformAmount: number;
  $sideMargin: number;
}>`
  margin: 0 10px;
  scroll-snap-align: start;
  ${({ $transformAmount }) => {
    return `
            transform: translateX(${`${$transformAmount}px`});
            transition: transform 0.5s ease;
        `;
  }}
  //  first child should not have left margin
    &:first-child {
    // below change for add/edit pet page. If this causes other issues,
    // use a prop to determine if this should be applied
    margin-left: 0.5px; // using .5px instead of 0 to avoid a bug with the element-in-view function
  }
`;

export const CarouselButton = styled(BaseButton)<{
  $isLeft?: boolean;
}>`
  transform: ${({ $isLeft }) =>
    $isLeft ? 'rotate(180deg)' : 'rotate(0deg)'};
  width: 20px;
  min-width: 0;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;

export const Arrow = styled(Icons.ChevronRight)`
  color: ${solidColors.upBlue.color};
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;

export const CarouselSlide = styled.div<{
  $translateX: number;
  $marginRight: number;
}>`
  margin-right: ${({ $marginRight }) =>
    `${$marginRight}px`};
  transform: translateX(
    ${({ $translateX }) => $translateX}px
  );
  display: inline-flex;
  transition: transform 1s ease;
  scroll-snap-align: start;
`;
