import {
  getAuthHeaders,
  pegasusClient,
} from '@services/apiService';
import axios from 'axios';

/* This was added by Michael in this commit 8efac050af1f53dd28f1cf7625600254b267117a , it's not being used anywhere though.
But still keeping it in case someone else from team might expect this in future */
const getApiClient = (url: string | undefined) => {
  const userAgent = `WheelsUp/${process.env.REACT_APP_VERSION} ${window.navigator.userAgent}`;
  const apiClient = axios.create({
    baseURL: `${url}`,
    headers: {
      'Content-Type': 'application/json',
      'Wu-User-Agent': userAgent,
      'WU-UUID': 'WEB_APP',
    },
  });

  return apiClient;
};

export const useAxios = <TData, TVariables>(
  query: string,
): ((variables?: TVariables) => Promise<TData>) => {
  return async (variables?: TVariables) => {
    return pegasusClient
      .post<{ data: TData; errors: unknown }>(
        'graphql',
        { query, variables },
        {
          headers: getAuthHeaders(),
        },
      )
      .then((res) => {
        if (res.data.errors)
          throw new Error(
            JSON.stringify(res.data.errors),
          );
        return res.data.data;
      }).catch((error) => {
        return error;
      }
    );
  };
};
