import { solidColors } from '@constants/styles/colors.constants';
import styled from 'styled-components';

export const StyledTextGroupPillText = styled.div<{
  $selected: boolean;
  $width: string;
  $disabled?: boolean;
}>`
  border: 1px solid
    ${({ $selected }) =>
      $selected
        ? solidColors.upBlue.color
        : solidColors.mediumGray.color};
  border-radius: 24px;
  width: ${({ $width }) => $width};
  height: 40px;
  cursor: pointer;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;

  ${(props) => props.theme.breakpoints.down('sm')} {
    font-size: 1rem;
  }
`;
