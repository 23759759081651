import { Icon } from '@wheelsup/wu-react-components';
import styled from 'styled-components';

export const CardContainer = styled.div`
  background-color: #ffffff;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  border-radius: 8px;
  justify-content: space-between;
`;

export const ImageAndAircraftInfo = styled.div`
  display: flex;
`;

export const AircraftInformation = styled.div`
  padding: 32px;
  @media screen and (max-width: 960px) {
    padding: 16px;
  }
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
`;

export const FlightInfoLineItem = styled.div`
  display: flex;
  margin-bottom: 18px;
  @media screen and (max-width: 960px) {
    margin-bottom: 10px;
  }
  align-items: center;
`;

export const MemberInformation = styled.div`
  display: flex;
  cursor: pointer;
  padding: 32px;
  @media screen and (max-width: 960px) {
    padding: 16px;
  }
  flex-direction: column;
  justify-content: space-between;
  align-items: end;
`;

export const PriceAndTaxInfo = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: column;
  justify-content: space-between;
  align-items: end;
`;

export const IconWithMargin = styled(Icon)`
  margin-right: 8px;
  margin-top: -2px;
`;
