import { Icons } from '@components/library';
import { solidColors } from '@constants/styles/colors.constants';
import {
  ContainerStyled,
  LeadPassengerButtonContainer,
  ListPaperContainer,
  PassengerActionButtonsContainer,
  StyledAdornment,
  StyledEndAdornment,
  PassengerSelectionSection,
} from './SubPagePassengerSelection.styles';
import {
  IBasePassengerDisplay,
  IPassenger,
  IPet,
} from '@components/types';
import { ISelectPassengersSubPage } from './SubPagePassengerSelection.interfaces';
import {
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { Button } from '@wheelsup/wu-react-components';
import {
  HeadlineContainerAtom,
  PillInput,
  Typography,
} from '@atoms';
import { Typography as SharedLibraryTypography } from '@wheelsup/wu-react-components';
import { hostedImage } from '@constants/images/images.constants';
import PassengerListGroup from '@components/molecules/PassengerListGroup/PassengerListGroup';
import { typography } from '@constants/styles/typography.constants';
import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import PassengerListCard from './PassengerListCard/PassengerListCard';
import LeadPassengerSelection from '@molecules/LeadPassengerSelector/LeadPassengerSelector';
import { useDispatch } from 'react-redux';
import {
  setSelectedPassengers,
  setSelectedPets,
} from '@features/Fly/FlySearchTripDetailsSlice';
import { paths } from '@routing/routerPaths';
import { useAppSelector } from '@app/hooks';
import { isEmpty, xorBy } from 'lodash';
import { passengerSelectionSliceInstance } from '@app/rootReducer';
import { asyncThunkFactory } from '@services/sliceFactoryService/requestSlice';
import { dataSliceOptions } from '@services/sliceFactoryService/sliceFactoryService.types';
import {
  IErrorPayload,
  setErrorPayload,
} from '@molecules/Error/ErrorSlice';
import { getFromLocalStorage } from '@components/utils/storage';
import { replaceText } from '@components/utils';
import ContainerSubNav from '../ContainerSubNav/ContainerSubNav';
import { trackAnalytics } from '@services/mixpanelService/mixpanel.service';
import TextGroupSqaurePill from '@components/molecules/TextGroupSqaurePill/TextGroupSqaurePill';
import { HeadlineSubtitleWithBackArrow } from '@components/molecules';

export default function SelectPassengersSubPage({
  flightItemTitle,
  subtitleTextView,
  searchFormViewModel,
  allTitle,
  leadTitle,
  favoriteTitle,
  allPassengerList,
  leadPassengerList,
  favoritePassengerList,
  selectedPassengers,
  selectedPets,
}: ISelectPassengersSubPage) {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const { id, max } = useParams<{
    id: string;
    max: string;
  }>();

  const dispatch = useDispatch();
  const [searchFocus, setSearchFocus] = useState(false);
  const [searchEnterLeave, setSearchEnterLeave] = useState(
    false,
  );

  const passengersStateData = useAppSelector(
    (state) => state.searchTripDetails,
  );

  const { data: selectPassengersData } = useAppSelector(
    (state) => state.selectPassengers,
  );

  const {
    selectedPassengersLeg,
    selectedPetsLegs,
  } = useAppSelector((state) => state.searchTripDetails);

  const {
    isSuccessful,
    errorPayload,
    error,
  } = useAppSelector(
    (state) => state.supPagePassengerSelection,
  );

  const passengersLoaded =
    selectedPassengersLeg.length > 0 && id !== undefined;

  const petsLoaded =
    selectedPetsLegs.length > 0 && id !== undefined;

  const [searchValue, setSearchValue] = useState('');

  const [popUpOpen, setPopUpOpen] = useState(false);

  const [flightLead, setFlightLead] = useState<
    IPassenger | undefined
  >(
    // determine based on the redux state who is the lead.
    passengersLoaded
      ? selectedPassengersLeg[
          Number(id)
        ]?.selectedPassengers.filter((x) => {
          return x.isLeadPassenger ? x : null;
        })[0]
      : getFromLocalStorage('leadPassenger'),
  );

  const [
    passengersTabList,
    setPassengersTabList,
  ] = useState<IBasePassengerDisplay[]>();
  const [currTab, setCurrTab] = useState(0);

  const [petsTabList, setPetsTabList] = useState<IPet[]>(
    allPassengerList.data.pets ?? [],
  );
  const [hasNewLead, setHasNewLead] = useState(false);

  const [
    selectedListPassengers,
    setSelectedListPassengers,
  ] = useState(() => {
    if (passengersLoaded)
      return selectedPassengersLeg[Number(id)]
        .selectedPassengers;
    else {
      if (
        (selectedPassengers as IPassenger[]).length > 0 &&
        (selectedPassengers !== undefined ||
          selectedPassengers !== null)
      )
        return (selectedPassengers as IPassenger[]) ?? [];
      else {
        const localSelectedPassengers = getFromLocalStorage(
          'selectedPassengersLegs',
        );
        return localSelectedPassengers !== null
          ? (localSelectedPassengers[Number(id)]
              .selectedPassengers as IPassenger[])
          : [];
      }
    }
  });

  const [selectedListPets, setSelectedListPets] = useState(
    () => {
      if (petsLoaded)
        return selectedPetsLegs[Number(id)].selectedPets;
      else {
        if (
          (selectedPets as IPet[]).length > 0 &&
          (selectedPets !== undefined ||
            selectedPets !== null)
        )
          return (selectedPets as IPet[]) ?? [];
        else {
          const localSelectedPets = getFromLocalStorage(
            'selectedPetsLegs',
          );
          return localSelectedPets !== null
            ? (localSelectedPets[Number(id)]
                .selectedPets as IPet[])
            : [];
        }
      }
    },
  );

  const [
    remainingSelectionCounter,
    setRemainingSelectionCounter,
  ] = useState(() => {
    const paxLeft =
      Number(max) -
      (selectedListPassengers.filter(
        (p) => p.ageGroupType !== 'lapInfant',
      ).length +
        (selectedListPets ?? []).filter(
          (p) => (p.weight ?? 0) > 49,
        ).length);
    return paxLeft >= 0 ? paxLeft : 0;
  });

  const handlePassengerSearch = (
    e?: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = (e?.target.value ?? '').toLowerCase();
    setSearchValue(value);
    const filteredPassengersList = allPassengerList.data.passengers.filter(
      (s) => {
        const completeName = `${s.firstName} ${s.middleName} ${s.lastName}`.toLowerCase();
        return completeName.includes(value);
      },
    );

    const filteredPetsList = allPassengerList.data.pets?.filter(
      (s) => {
        const completeName = s.name.toLowerCase();
        return completeName.includes(value);
      },
    );
    setPassengersTabList(
      mapPassengersToDisplayValues(filteredPassengersList),
    );
    setPetsTabList(filteredPetsList ?? []);
  };

  const handleSubnavChange = (newValue?: number) => {
    if (searchValue !== '') return;
    setCurrTab(newValue ?? 0);
    switch (newValue) {
      case 0:
        setPetsTabList(allPassengerList.data.pets ?? []);
        setPassengersTabList(
          mapPassengersToDisplayValues(
            allPassengerList.data
              .passengers as IPassenger[],
          ),
        );
        break;
      case 1:
        setPetsTabList(leadPassengerList.data.pets ?? []);
        setPassengersTabList(
          mapPassengersToDisplayValues(
            leadPassengerList.data
              .passengers as IPassenger[],
          ),
        );
        break;
      case 2:
        setPetsTabList(
          favoritePassengerList.data.pets ?? [],
        );
        setPassengersTabList(
          mapPassengersToDisplayValues(
            favoritePassengerList.data
              .passengers as IPassenger[],
          ),
        );
        break;
      default:
        break;
    }
  };

  const handlePassengerSelection = (
    passenger: IBasePassengerDisplay,
  ) => {
    // Below helps determine mixpanel tracking
    let paxAge = 'Adult';
    switch (passenger.ageGroupType) {
      case 'kid':
        paxAge = 'Child';
        break;
      case 'lapInfant':
        paxAge = 'Lap Infant';
        break;
      default:
        break;
    }

    const filteredPassenger = allPassengerList.data.passengers.filter(
      (p) => p.id === passenger.id,
    )[0];

    const filteredSelected = selectedListPassengers.filter(
      (p) => p.id === filteredPassenger.id,
    )[0];
    // filteredSelected is undefined if passenger is not already selected
    // Used for mixpanel tracking
    const mpTitle = !filteredSelected
      ? 'Select_AddPassengerToFlightResPage_ListItem'
      : 'Select_RemovePassengerFromFlightResPage_ListItem';
    let canTrack = true;
    // selecting passenger
    if (
      filteredSelected === undefined &&
      (remainingSelectionCounter > 0 ||
        filteredPassenger.ageGroupType === 'lapInfant')
    ) {
      setSelectedListPassengers([
        ...(selectedListPassengers ?? []),
        filteredPassenger,
      ]);
      if (filteredPassenger.ageGroupType !== 'lapInfant')
        setRemainingSelectionCounter(
          remainingSelectionCounter - 1,
        );
      // deselecting passenger - works even if lead
    } else if (
      passenger.id !== flightLead?.id &&
      selectedListPassengers.find(
        (p) => p.id === passenger.id,
      ) !== undefined
    ) {
      setSelectedListPassengers(
        selectedListPassengers.filter((p) => {
          return p.id !== passenger.id;
        }),
      );
      if (filteredPassenger.ageGroupType !== 'lapInfant')
        setRemainingSelectionCounter(
          remainingSelectionCounter + 1,
        );
    } else if (
      passenger.id === flightLead?.id &&
      selectedListPassengers.find(
        (p) => p.id === passenger.id,
      ) !== undefined
    ) {
      canTrack = false;
      setPopUpOpen(true);
    }
    if (canTrack) {
      trackAnalytics({
        trackingTitle: mpTitle,
        properties: [
          {
            key: 'Passenger Type',
            value: paxAge,
          },
        ],
      });
    }
  };

  const handlePetsSelection = (
    pet: IBasePassengerDisplay,
  ) => {
    const localSelectedListPetsWithFallback =
      selectedListPets ?? [];
    const filteredPet = allPassengerList.data.pets?.filter(
      (p) => {
        return p.id === pet.id;
      },
    )[0];

    const filteredSelected = localSelectedListPetsWithFallback.filter(
      (p) => {
        return p.id === filteredPet?.id;
      },
    )[0];

    const mpTitle = !filteredSelected
      ? 'Select_AddPassengerToFlightResPage_ListItem'
      : 'Select_RemovePassengerFromFlightResPage_ListItem';

    if (
      filteredSelected === undefined &&
      (remainingSelectionCounter > 0 ||
        (filteredPet?.weight ?? 0) < 50)
    ) {
      setSelectedListPets([
        ...localSelectedListPetsWithFallback,
        filteredPet ? filteredPet : ({} as IPet),
      ]);

      if ((filteredPet?.weight ?? 0) > 49)
        setRemainingSelectionCounter(
          remainingSelectionCounter - 1,
        );
    } else if (
      localSelectedListPetsWithFallback.find(
        (p) => p.id === pet.id,
      ) !== undefined
    ) {
      setSelectedListPets(
        localSelectedListPetsWithFallback.filter((p) => {
          return p.id !== pet.id;
        }),
      );
      if ((filteredPet?.weight ?? 0) > 49)
        setRemainingSelectionCounter(
          remainingSelectionCounter + 1,
        );
    }
    trackAnalytics({
      trackingTitle: mpTitle,
      properties: [{ key: 'Passenger Type', value: 'Pet' }],
    });
  };

  const selectAlternateLead = (
    leadPassenger: IPassenger,
    oldLeadPassenger?: IPassenger,
  ) => {
    if (leadPassenger.id !== flightLead?.id) {
      const newLead = {
        ...leadPassenger,
        isLeadPassenger: true,
      };

      const oldLead = {
        ...oldLeadPassenger,
        isLeadPassenger: false,
      } as IPassenger;
      const newPassengers = [
        ...(selectedListPassengers.filter(
          (p) => p.id !== newLead.id && p.id !== oldLead.id,
        ) ?? []),
        newLead,
      ];
      setFlightLead(newLead);
      // TODO: This logic will need to change when we allow editing selected pax from multiple legs on 1 screen
      const index = id ? parseInt(id, 10) : 0;
      dispatch(
        setSelectedPassengers({
          selectedPassengers: {
            selectedPassengers: [...newPassengers, oldLead],
          },
          index,
        }),
      );
      setSelectedListPassengers([
        ...newPassengers,
        oldLead,
      ]);
      if (
        selectedListPassengers.find(
          (p) => p.id === leadPassenger.id,
        ) === undefined
      ) {
        setSelectedListPassengers(newPassengers);
      } else if (!selectedListPassengers.find((p) => p.id === oldLead.id)){
        setRemainingSelectionCounter(
          remainingSelectionCounter + 1,
        );
      }
      setHasNewLead(true);
    }
    setPopUpOpen(false);
  };

  const mapPassengersToDisplayValues = (
    list: IPassenger[],
  ): IBasePassengerDisplay[] => {
    const selectedPassengersIds = selectedListPassengers?.map(
      (p) => p.id,
    );
    return list.map((x) => {
      return {
        id: x.id,
        profileImage: x.profileImage,
        isLeadPassenger: x.isLeadPassenger,
        displayName: `${x.prefix ?? ''}
                ${x.firstName} ${x.middleName ?? ''} ${
          x.lastName
        }
                ${x.suffix ?? ''}`,
        isLead: x.id === flightLead?.id,
        isSelected:
          selectedPassengersIds?.indexOf(x.id) >= 0,
        ageGroupType: x.ageGroupType,
      } as IBasePassengerDisplay;
    });
  };

  const mapPetsToDisplayValues = (
    list: IPet[],
  ): IBasePassengerDisplay[] => {
    const localSelectedListPetsWithFallback =
      selectedListPets ?? [];
    const selectedPetsIds = localSelectedListPetsWithFallback?.map(
      (p) => p.id,
    );
    return list.map((x) => {
      return {
        id: x.id,
        profileImage: x.profileImage,
        displayName: x.name,
        isSelected: selectedPetsIds?.indexOf(x.id) >= 0,
      } as IBasePassengerDisplay;
    }) as IBasePassengerDisplay[];
  };

  const handleOnSave = () => {
    if (flightLead === undefined) {
      return;
    }

    const availabilityId = getFromLocalStorage(
      'availabilityId',
    );

    const cleanedSelectedListPassengers = selectedListPassengers.map(
      (p) => {
        return {
          ...p,
          isLeadPassenger:
            p.id === flightLead?.id ? true : false,
        };
      },
    );

    trackAnalytics({
      trackingTitle:
        'Select_SaveUpdatedPassengerListResPage_Button',
      properties: [],
    });
    const samePaxFlag = passengersStateData.samePaxFlag;
    if (samePaxFlag) {
      // We set the first leg to the new pax list which will be used to update the other legs in ContainerTripPassengerEditor
      dispatch(
        setSelectedPassengers({
          index: 0,
          selectedPassengers: {
            selectedPassengers: cleanedSelectedListPassengers,
          },
        }),
      );

      dispatch(
        setSelectedPets({
          index: 0,
          selectedPets: {
            selectedPets: selectedListPets,
          },
        }),
      );
    } else {
      // We set the current leg to the new pax list
      dispatch(
        setSelectedPassengers({
          index: Number(id),
          selectedPassengers: {
            selectedPassengers: cleanedSelectedListPassengers,
          },
        }),
      );

      dispatch(
        setSelectedPets({
          index: Number(id),
          selectedPets: {
            selectedPets: selectedListPets,
          },
        }),
      );
    }

    if (searchParams.get('flightId') !== null) {
      dispatch(
        asyncThunkFactory(
          dataSliceOptions.passengerSelection,
        )({
          resPayload: {
            flightId: searchParams.get('flightId') ?? '',
            passengers: cleanedSelectedListPassengers,
            pets: selectedListPets,
          },
        }),
      );
      return;
    }

    if (availabilityId) {
      navigate(
        `/flight-detail/one-way/${availabilityId}/request-book`,
      );
    } else {
      navigate(-1);
    }
  };

  const handleCancelChanges = () => {
    trackAnalytics({
      trackingTitle:
        'Select_CancelUpdatedPassengerListResPage_Button',
      properties: [],
    });
    navigate(-1);
  };

  const handleOpenPopUp = () => setPopUpOpen(true);

  const handleClosePopUp = () => setPopUpOpen(false);

  const handleDisabledValidation = useCallback(() => {
    let petChange = false;
    let paxChange = false;
    if (passengersLoaded) {
      paxChange = isEmpty(
        xorBy(
          selectedListPassengers,
          selectedPassengersLeg[Number(id)]
            ?.selectedPassengers,
          'id',
        ),
      );
      petChange = isEmpty(
        xorBy(
          selectedListPets,
          selectedPetsLegs[Number(id)]?.selectedPets,
          'id',
        ),
      );
    } else return true;

    if (hasNewLead) {
      return false;
    } else if (!paxChange) {
      return false;
    } else if (!petChange) {
      return false;
    } else return true;
  }, [
    passengersLoaded,
    selectedListPassengers,
    selectedPassengersLeg,
    selectedListPets,
    selectedPetsLegs,
  ]);

  useEffect(() => {
    const localSelectedPassengers = getFromLocalStorage(
      'selectedPassengersLegs',
    );
    const localSelectedPets = getFromLocalStorage(
      'selectedPetsLegs',
    );

    if (
      localSelectedPassengers !== null &&
      (selectedPassengers as IPassenger[]).length < 1
    ) {
      dispatch(
        setSelectedPassengers({
          index: Number(id),
          selectedPassengers: {
            selectedPassengers:
              localSelectedPassengers[Number(id)]
                .selectedPassengers,
          },
        }),
      );
    }

    if (
      localSelectedPets !== null &&
      (selectedPets as IPet[]).length < 1
    ) {
      dispatch(
        setSelectedPets({
          index: Number(id),
          selectedPets: {
            selectedPets:
              localSelectedPets[Number(id)].selectedPets,
          },
        }),
      );
    }
  }, []);

  useEffect(() => {
    let paxList = allPassengerList;
    switch (currTab) {
      case 1:
        paxList = leadPassengerList;
        break;
      case 2:
        paxList = favoritePassengerList;
        break;
      default:
        break;
    }
    setPassengersTabList(
      mapPassengersToDisplayValues(
        paxList.data.passengers as IPassenger[],
      ),
    );
  }, [selectedListPassengers, flightLead]);

  useEffect(() => {
    if (isSuccessful) navigate(-1);

    return function cleanup() {
      dispatch(
        passengerSelectionSliceInstance.actions.cleanState(),
      );
    };
  }, [isSuccessful]);

  useEffect(() => {
    if (error) {
      dispatch(
        setErrorPayload(errorPayload as IErrorPayload),
      );
    }
  }, [error]);

  useEffect(() => {
    if (handleDisabledValidation()) return;
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      return (e.returnValue = '');
    };

    window.addEventListener(
      'beforeunload',
      handleBeforeUnload,
    );

    return () => {
      window.removeEventListener(
        'beforeunload',
        handleBeforeUnload,
      );
    };
  }, [handleDisabledValidation]);

  const handleHeadlineBack = () => {
    if (handleDisabledValidation()) {
      return navigate(-1);
    }

    if (
      window.confirm('Changes you made may not be saved.')
    ) {
      navigate(-1);
    }
  };

  const handleHeadlineSubtitle = () => {
    const flightDetailId = getFromLocalStorage(
      'flightDetailId',
    );
    const subTitle =
      selectPassengersData?.metadata.pageSubtitle;

    if (flightDetailId) {
      return subTitle ?? 'Back to Flight Detail';
    }
    return subTitle ?? 'Back to Trip details';
  };

  const handleCancelSearch = () => {
    setSearchFocus(false);
    handlePassengerSearch();
  };

  const handleMouseEnterLeave = () => {
    setSearchEnterLeave(!searchEnterLeave);
  };

  const PillInputStylesJS = {
    backgroundColor: `${
      searchEnterLeave
        ? solidColors.lightGray.color
        : solidColors.upWhite.color
    }`,
    borderRadius: '24px',
    borderWidth: '0px',
  };

  const isHotflight =
    flightItemTitle && flightItemTitle.length > 0;
  const flightLegTitle = isHotflight
    ? flightItemTitle[0]
    : getFromLocalStorage('flightLegTitle');

  return (
    <>
      <HeadlineContainerAtom>
        <HeadlineSubtitleWithBackArrow
          headline="Manage Passengers"
          subtitle={handleHeadlineSubtitle()}
          action={handleHeadlineBack}
          color={solidColors.midnight}
          subtitleColor={solidColors.upBlue}
        />
      </HeadlineContainerAtom>
      <PassengerSelectionSection data-name="SubPagePassengerSelection">
        <Typography
          variant={typography.body1a}
          color={solidColors.darkGray}
        >
          {replaceText({
            textCopy: subtitleTextView?.copy ?? '',
            textToReplace: `${remainingSelectionCounter} passengers`,
            replaceKeyword: 'maxNumberOfPassengers',
          })}
        </Typography>
        {flightLegTitle.length > 0 && (
          <div style={{ margin: '40px 0 18px 0' }}>
            <TextGroupSqaurePill
              id={flightLegTitle}
              backgroundColor={solidColors.midnight}
            >
              <Typography
                variant={typography.captionBold}
                color={solidColors.upWhite}
              >
                {flightLegTitle ?? ''}
              </Typography>
            </TextGroupSqaurePill>
          </div>
        )}
        <ContainerStyled>
          <SharedLibraryTypography
            variant='heading02'
            color='primary'
          >
            Passengers
          </SharedLibraryTypography>
          <div style={{ width: '285px' }}>
            <PillInput
              // This is to apply background color on hover. MUI does not seem to support this hover action for .MuiOutlinedInput-root.
              style={PillInputStylesJS}
              onMouseEnter={handleMouseEnterLeave}
              onMouseLeave={handleMouseEnterLeave}
              onFocus={() => setSearchFocus(true)}
              onBlur={() => setSearchFocus(false)}
              variant="outlined"
              value={searchValue}
              label={searchFormViewModel?.placeholder ?? ''}
              type="text"
              inputProps={{
                'data-testid': 'input-search',
              }}
              onChange={(
                e: React.ChangeEvent<HTMLInputElement>,
              ) => {
                handlePassengerSearch(e);
              }}
              InputProps={{
                startAdornment: (
                  <StyledAdornment
                    position="start"
                    $focused={searchFocus}
                  >
                    <Icons.Search />
                  </StyledAdornment>
                ),
                endAdornment: (
                  <StyledEndAdornment
                    onClick={handleCancelSearch}
                    $display={
                      searchValue.length > 0
                        ? 'flex'
                        : 'none'
                    }
                    position="end"
                  >
                    <Icons.Cancel />
                  </StyledEndAdornment>
                ),
              }}
              InputLabelProps={{
                shrink:
                  searchFocus || searchValue.length > 0,
              }}
              fullWidth={true}
            />
          </div>
        </ContainerStyled>
        <ContainerSubNav
          tabChangeHandle={handleSubnavChange}
          id="01"
          headline=""
          items={[
            {
              title: allTitle ?? 'All',
              image: null,
            },
            {
              title: leadTitle ?? 'Lead',
              image: null,
            },
            {
              title: favoriteTitle ?? 'Favorite',
              image: null,
            },
          ]}
        >
          <PassengerListCard
            handlePassengerSelection={
              handlePassengerSelection
            }
            selectedTabList={passengersTabList ?? []}
            allTab={{
              tabTitle: allTitle ?? 'All',
              tabLeadIcon:
                allPassengerList.data.leadPassengerIcon
                  .url ?? hostedImage.generic,
              addButton:
                allPassengerList.data.addNewPassengerButton,
            }}
            leg={id ? parseInt(id) : undefined}
            LeadPaxButton={
              <LeadPassengerButtonContainer>
                {passengersTabList?.length === 0 &&
                petsTabList.length === 0 ? null : (
                  <LeadPassengerSelection
                    handleOpenButton={handleOpenPopUp}
                    handleCloseButton={handleClosePopUp}
                    isOpen={popUpOpen}
                    availableLeadPassengers={
                      leadPassengerList.data.passengers
                    }
                    confirmAction={selectAlternateLead}
                    currentLeadPassenger={flightLead}
                  />
                )}
              </LeadPassengerButtonContainer>
            }
          />
          <PassengerListCard
            handlePassengerSelection={
              handlePassengerSelection
            }
            selectedTabList={passengersTabList ?? []}
            allTab={{
              tabTitle: leadTitle ?? 'Lead',
              tabLeadIcon:
                leadPassengerList.data.leadPassengerIcon
                  .url ?? hostedImage.generic,
              addButton:
                leadPassengerList.data
                  .addNewPassengerButton,
            }}
            leg={id ? parseInt(id) : undefined}
            LeadPaxButton={
              <LeadPassengerButtonContainer>
                {passengersTabList?.length === 0 &&
                petsTabList.length === 0 ? null : (
                  <LeadPassengerSelection
                    handleOpenButton={handleOpenPopUp}
                    handleCloseButton={handleClosePopUp}
                    isOpen={popUpOpen}
                    availableLeadPassengers={
                      leadPassengerList.data.passengers
                    }
                    confirmAction={selectAlternateLead}
                    currentLeadPassenger={flightLead}
                  />
                )}
              </LeadPassengerButtonContainer>
            }
          />
          <PassengerListCard
            handlePassengerSelection={
              handlePassengerSelection
            }
            selectedTabList={passengersTabList ?? []}
            allTab={{
              tabTitle: favoriteTitle ?? 'Favorite',
              tabLeadIcon:
                favoritePassengerList.data.leadPassengerIcon
                  .url ?? hostedImage.generic,
              addButton:
                favoritePassengerList.data
                  .addNewPassengerButton,
            }}
            leg={id ? parseInt(id) : undefined}
            LeadPaxButton={
              <LeadPassengerButtonContainer>
                {passengersTabList?.length === 0 &&
                petsTabList.length === 0 ? null : (
                  <LeadPassengerSelection
                    handleOpenButton={handleOpenPopUp}
                    handleCloseButton={handleClosePopUp}
                    isOpen={popUpOpen}
                    availableLeadPassengers={
                      leadPassengerList.data.passengers
                    }
                    confirmAction={selectAlternateLead}
                    currentLeadPassenger={flightLead}
                  />
                )}
              </LeadPassengerButtonContainer>
            }
          />
        </ContainerSubNav>
        {allPassengerList.data.addNewPetButton && (
          <div style={{ marginTop: '46px' }}>
            <Typography
              variant={typography.h3a}
              color={solidColors.midnight}
            >
              Pets
            </Typography>
            <ListPaperContainer elevation={1}>
              <PassengerListGroup
                handlePassengerSelection={
                  handlePetsSelection
                }
                passengerList={mapPetsToDisplayValues(
                  petsTabList as IPet[],
                )}
                leadIcon={{
                  url: hostedImage.generic,
                }}
                addButton={
                  allPassengerList.data.addNewPetButton
                }
                leg={id ? parseInt(id) : undefined}
                addPassengerUrl={paths.ADD_PET}
                isPetList
              />
            </ListPaperContainer>
          </div>
        )}
        <PassengerActionButtonsContainer>
          <Button
            variant="secondary"
            onPress={handleCancelChanges}
          >
            nevermind
          </Button>
          <Button
            variant="primary"
            onPress={handleOnSave}
            isDisabled={handleDisabledValidation()}
          >
            save
          </Button>
        </PassengerActionButtonsContainer>
      </PassengerSelectionSection>
    </>
  );
}
