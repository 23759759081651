import { set } from 'lodash';
import { useEffect, useState, RefObject } from 'react';

interface ICarouselSlides {
  slideRef: RefObject<HTMLElement>;
  parentContainer: RefObject<HTMLElement>;
  children: React.ReactNode | React.ReactNode[];
  inComponent?: boolean;
  width?: number;
  isHorizontalScroll?: boolean;
  slideMargin?: number;
  slidePadding?: number;
  fullCardWidthOverride?: boolean;
}

function useCarouselSlides({
  slideRef,
  parentContainer,
  children,
  inComponent,
  width = 0,
  isHorizontalScroll = false,
  slideMargin = 24,
  slidePadding = 0,
  fullCardWidthOverride = false,
}: ICarouselSlides) {
  // slidesPerView is how many cards should be 'active' at a time
  const [slidesPerView, setSlidesPerView] = useState(0);
  // total slides is the total number of views it will take to reach the end of the carousel
  const [totalSlides, setTotalSlides] = useState(0);
  const [slideWidth, setSlideWidth] = useState(0);
  const [transformWidth, setTransformWidth] = useState(0);
  const [slideView, setSlideView] = useState(1);
  const [startIndex, setStartIndex] = useState(0);
  const [activeForward, setActiveForward] = useState(true);
  const [activeBack, setActiveBack] = useState(false);
  const [
    allActiveFallback,
    setAllActiveFallback,
  ] = useState(false);
  const slides: React.ReactNode[] = Array.isArray(children)
    ? children
    : [children];

  const inComponentOrFullCardWidthOverride =
    inComponent || fullCardWidthOverride;

  useEffect(() => {
    const parentWidth =
      parentContainer.current?.offsetWidth || 1;
    const childWidth = slideRef?.current?.offsetWidth || 1;
    const slideWithMargin = childWidth + slideMargin;
    const slideWithMarginAndPadding =
      slideWithMargin + slidePadding;
    const currItemsPerSlide = Math.floor(
      parentWidth / slideWithMargin,
    );
    setSlideWidth(
      slideWithMarginAndPadding * currItemsPerSlide,
    );
    const currTotalSlides = Math.ceil(
      slides.length / currItemsPerSlide,
    );
    setSlidesPerView(
      inComponentOrFullCardWidthOverride
        ? 1
        : currItemsPerSlide,
    );
    if (currTotalSlides === Infinity) {
      inComponentOrFullCardWidthOverride &&
        setSlideWidth(
          (slideRef?.current?.offsetWidth ?? 0) +
            slideMargin,
        );
      setTotalSlides(slides.length);
      setAllActiveFallback(true);
    } else {
      setTotalSlides(currTotalSlides);
      setAllActiveFallback(false);
    }

    const resetSlideView = () => {
      /*
               When user changes the viewport size, we need to reset the carousel to the first slide
               to avoid some ad behavior
           */
      setStartIndex(0);
      setTransformWidth(0);
      setSlideView(1);
      setActiveForward(true);
      setActiveBack(false);
    };
    !isHorizontalScroll && resetSlideView();
  }, [
    slides,
    slideRef,
    parentContainer,
    width,
    slideRef?.current?.offsetWidth,
  ]);

  useEffect(() => {
    if (slideView === 1) {
      setActiveBack(false);
    } else {
      setActiveBack(true);
    }
    if (slideView === totalSlides) {
      setActiveForward(false);
    } else {
      setActiveForward(true);
    }
  }, [slideView, totalSlides]);

  const handleArrowForward = (e: React.MouseEvent) => {
    e.preventDefault();
    if (activeForward) {
      setTransformWidth(transformWidth - slideWidth);
      setStartIndex(startIndex + slidesPerView);
      setSlideView(slideView + 1);
    }
  };

  const handleArrowBack = (e: React.MouseEvent) => {
    e.preventDefault();
    if (activeBack) {
      setTransformWidth(transformWidth + slideWidth);
      setStartIndex(startIndex - slidesPerView);
      setSlideView(slideView - 1);
    }
  };
  return {
    slidesPerView,
    totalSlides,
    transformWidth,
    slideView,
    startIndex,
    activeForward,
    activeBack,
    allActiveFallback,
    slideMargin,
    slides,
    handleArrowForward,
    handleArrowBack,
  };
}

export default useCarouselSlides;
