const mainRack =
  'https://9dd4157e73239d65fbe9-be721e199265b7ef611d0853a58c51d2.ssl.cf2.rackcdn.com';

const mainRack2 =
  'https://9d97a0cd0a0130e80e69-d4ce3468b2ddbed328dcebe61fd41474.ssl.cf5.rackcdn.com/documents';
const mainRack3 =
  'https://35f0adf900eefe4e8c20-9ae5866a59bf2b247845fad2f9434a9a.ssl.cf5.rackcdn.com/documents';
const mainRack4 =
  'https://10efabe9a07fd7c21486-540b3da1da08c8bdfc21af9e633cf2ed.ssl.cf5.rackcdn.com/documents';
const mainRack5 =
  'https://919a3b1a153a4a7d4f9d-f343d07a8b01c95b3598e569a6495242.ssl.cf5.rackcdn.com/documents';
export const hostedImage = {
  generic:
    'https://a986261fb00bf2dd4ca0-ce701a730a522f7cd904e72ae946fa25.ssl.cf2.rackcdn.com/wu_reference_image.png',
  navHubIcon: `${mainRack}/hub-web.svg`,
  wheelsUpLogo: `${mainRack}/wheelsup-logo.svg`,
  wheelsUpLogoWhite: `${mainRack}/wheelsup-logo-white.svg`,
  wheelsUpLogoWhiteUP: `${mainRack}/UP-logo-white.svg`,
  wheelsUpMark: `${mainRack}/wheelsup-mark.svg`,
  wheelsUpMarkWhite: `${mainRack}/wheelsup-mark-white.svg`,
  wheelsUpPlane: `${mainRack}/img_plane.png`,
  wheelsUpPlaneTail: `${mainRack}/plane_tail.png`,
  wheelsUpTailCloud: `${mainRack}/tail-cloud.png`,
  wheelsUpCloudsOne: `${mainRack}/img_clouds-1.png`,
  wheelsUpCloudsTwo: `${mainRack}/img_clouds-2.png`,
  wheelsUpCloudsThree: `${mainRack}/img_clouds-3.png`,
  wheelsUpDottedLine: `${mainRack}/curved-dotted-line.svg`,
  wheelsUpSideCloud: `${mainRack}/img_cloud.png`,
  wheelsUpCloudsSignIn: `${mainRack}/img_clouds_signup.png`,
  wheelsUpFlightDeal: `${mainRack}/ic_deals.svg`,
  wheelsUpFlightDealPrice: `${mainRack}/instant-book.svg`,
  wheelsUpHubApp: `${mainRack}/hub-app.svg`,
  wheelsUpChat: `${mainRack}/ic_chat.svg`,
  wheelsUpPlaneSvg: `${mainRack}/neutral-right.svg`,
  wheelsUpCtaArrow: `${mainRack}/cta_arrow.svg`,
  wheelsUpEditPencil: `${mainRack}/edit.svg`,
  wheelsUpTestBackground: `${mainRack}/CitationDetail1222_wf05_Final170612.jpg`,
  icon_roundTrip: `${mainRack}/icon_roundTrip.svg`,
  downloadPDF: `${mainRack2}/pdf_16860741331.svg`,
  fundAvatar: `${mainRack2}/fund-avatar_16860742678.svg`,
  creditCardAvatar: `${mainRack2}/credit-card-avatar_16860742786.svg`,
  checkWireAvatar: `${mainRack2}/check-wire-avatar_16860742890.svg`,
  tailIconSVG: `${mainRack3}/tail_16612040261.svg`,
  chevronRight: `${mainRack4}/chevron-right_16361304912.svg`,
  roundedChevronRight: `${mainRack5}/chevron-right_16890834800.svg`,
};

export const dummySvgUrl =
  'https://upload.wikimedia.org/wikipedia/commons/4/4f/SVG_Logo.svg';

export const dummyAirplaneSvgUrl =
  'https://image.flaticon.com/icons/svg/61/61212.svg';

export const shapeImage = {
  square:
    'https://dummyimage.com/200x200/6a8591/6a8591.png',
};

export const icons = {
  switch:
    'https://wheelsup.imgix.net/https%3A%2F%2F34c66fb9bafa2e83bf42-4fc8ca6257496320096e6efa1ef30045.ssl.cf5.rackcdn.com%2Fdocuments%2FLeft-Right_16630061722.svg?w=20&h=20&fit=crop&fp-x=0&fp-y=0&fp-z=0&s=4b7beb1ba2f7b9067525a9305cf9b9cc',
  passenger:
    'https://34c66fb9bafa2e83bf42-4fc8ca6257496320096e6efa1ef30045.ssl.cf5.rackcdn.com/documents/passenger_16630061623.svg',
  plus:
    'https://57d941c476953b8523ab-d6cba39c7afbd165d69e38502dbfd065.ssl.cf2.rackcdn.com/plus-sign.svg',
  avatar:
    'https://35f0adf900eefe4e8c20-9ae5866a59bf2b247845fad2f9434a9a.ssl.cf5.rackcdn.com/documents/adult-circle_16629966582.svg',
  petAvatar:
    'https://35f0adf900eefe4e8c20-9ae5866a59bf2b247845fad2f9434a9a.ssl.cf5.rackcdn.com/documents/pet-circle_16629966313.svg',
};

export const wupPlusDeltaBanner =
  'https://9d97a0cd0a0130e80e69-d4ce3468b2ddbed328dcebe61fd41474.ssl.cf5.rackcdn.com/images/UP+Delta_17077491401.original.png';

export const deltaLogo =
  'https://caa6a674602fdf1bdb1c-042d7bab4be1365371aaf3f2feca0cd0.ssl.cf5.rackcdn.com/images/delta_c_r%205_17170053341.original.png';

export const heroImage =
  'https://35f0adf900eefe4e8c20-9ae5866a59bf2b247845fad2f9434a9a.ssl.cf5.rackcdn.com/original_images/hero-image-desktop_17029713489.png';
export const heroImageMobile =
  'https://9d97a0cd0a0130e80e69-d4ce3468b2ddbed328dcebe61fd41474.ssl.cf5.rackcdn.com/images/hero-image-mweb_17194133117.original.png';
