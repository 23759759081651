import styled from 'styled-components';

import { theme } from '@constants/styles/theme.constants';
import { BaseButton, CardAtom, ImageImgix } from '@atoms';
import {
  Button,
  CardActions,
  CardContent,
  CardHeader,
} from '@library';

export const CardAtomStyled = styled(CardAtom)`
  min-width: 27rem !important;
`;

export const HeadlineStyled = styled.div`
  max-width: 16.5rem;
  padding: 0;
  margin: 0 auto 0 auto;
  text-align: center;
`;

export const HeaderStyled = styled(CardContent)`
  padding: 1rem;
  padding-bottom: 0;
`;

export const CardHeaderStyled = styled(CardHeader)`
  height: 1rem;
  padding: 0.2rem;
  padding-bottom: 0;
`;

export const CardContentStyled = styled(CardContent)`
  text-align: center;
`;

export const CTAContainerStyled = styled(CardActions)`
  padding: 0;
  height: 3rem;
`;

export const ButtonStyled = styled(Button)`
  color: ${theme.palette.info.main};
  margin: 0 !important;
  width: 100%;
  height: 100%;
  border-radius: 0;
`;

export const ImageStyledContainer = styled(BaseButton)`
  padding-top: 8px;
  padding-right: 10px;
  display: flex;
`;

export const ImageStyled = styled(ImageImgix)`
  width: 2rem;
`;
