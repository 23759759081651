import PageTitle from '@atoms/PageTitle/PageTitle';
import Loader from '@components/molecules/Loader/Loader';
import { IPage } from '@services/dynamicRenderingService/dynamicRendering.interfaces';
import { createPage } from '@services/dynamicRenderingService/dynamicRendering.service';
import { PageBuilderContainerStyled } from './PageBuilderContainer.styles';
import { Container } from '@components/library';
import { useAppSelector } from '@app/hooks';
import Error from '@components/molecules/Error/Error';
import SubPageTermsOfService from '@components/organisms/SubPageTermsOfService/SubPageTermsOfService';
interface IPageBuilderContainer {
  page?: IPage | undefined;
  error?: unknown | null;
  isLoading?: boolean;
  children?: React.ReactNode;
  margin?: string;
  metadataTitle?: boolean;
}

export default function PageBuilderContainer({
  page,
  isLoading,
  children,
  margin = '11',
  metadataTitle = false,
}: IPageBuilderContainer) {
  const { errorPayload, errorOpen } = useAppSelector(
    (state) => state.error,
  );
  const renderContent = () => {
    if (page) {
      return createPage(page);
    } else {
      return children;
    }
  };

  return (
    <PageBuilderContainerStyled $margin={margin}>
      {page?.metadata.termsOfService ? (
        <SubPageTermsOfService
          {...page?.metadata?.termsOfService?.data}
        />
      ) : null}

      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Error
            open={!!errorOpen}
            data={errorPayload}
          />
          {metadataTitle && (
            <Container>
              <PageTitle
                title={page?.metadata.pageTitle || ''}
                subtitle={page?.metadata.pageSubtitle || ''}
              />
            </Container>
          )}

          {renderContent()}
        </>
      )}
    </PageBuilderContainerStyled>
  );
}
