import { IAirportItem } from '@atoms/AutoCompleteFlight/AutoCompleteFlight.interfaces';
import { FlightSearchContext } from './FlightSearchContext';
import {
  FlightObjTypeUpdate,
  PricingParameters,
  flightTypes,
} from './FlightSearchContext.types';

const useFlightSearch = (
  context: React.ContextType<typeof FlightSearchContext>,
) => {
  const { dispatch: contextDispatch, state } = context;
  const {
    legs,
    flightType,
    displayDate,
    pricingRequest,
    flightErrors,
  } = state;

  const roundTripFlightObject = {
    departure: state.legs[0],
    return: state.legs[1],
  };

  const updateLeg = (
    updatedFields: FlightObjTypeUpdate,
    legNumber: number,
  ) => {
    const leg = legs[legNumber];
    const updatedLeg = {
      ...leg,
      ...updatedFields,
    };
    contextDispatch({
      payload: {
        leg: updatedLeg,
        legNumber: legNumber,
      },
      type: 'updateLeg',
    });
  };

  const updateDisplayDate = (displayDate: string) => {
    contextDispatch({
      payload: {
        displayDate,
      },
      type: 'updateDisplayDate',
    });
  };

  const updateRoundTripFlights = (
    departureAirport: IAirportItem | null,
    arrivalAirport: IAirportItem | null,
    isDeparture: boolean,
    isSameAirport?: boolean,
  ) => {
    if (isDeparture) {
      contextDispatch({
        payload: {
          departure: {
            ...roundTripFlightObject.departure,
            departureAirport,
            airportError: isSameAirport
              ? 'Please pick two different airports'
              : undefined,
          },
          return: {
            ...roundTripFlightObject.return,
            arrivalAirport: departureAirport,
          },
        },
        type: 'updateRoundTrip',
      });
    } else {
      contextDispatch({
        payload: {
          departure: {
            ...legs[0],
            dateOpen: true,
            arrivalAirport,
            airportError: isSameAirport
              ? 'Please pick two different airports'
              : undefined,
          },
          return: {
            ...legs[1],
            departureAirport: arrivalAirport,
          },
        },
        type: 'updateRoundTrip',
      });
    }
  };

  const updateIsLegendOpen = (isLegendOpen: boolean) => {
    contextDispatch({
      payload: {
        isLegendOpen,
      },
      type: 'updateIsLegendOpen',
    });
  };

  const updatePricingRequest = (
    request: PricingParameters,
  ) => {
    contextDispatch({
      payload: {
        request,
      },
      type: 'updatePricingRequest',
    });
  };

  return {
    legs,
    flightErrors,
    roundTripFlightObject,
    displayDate,
    updateLeg,
    flightType,
    state,
    contextDispatch,
    updateRoundTripFlights,
    updateDisplayDate,
    pricingRequest,
    updatePricingRequest,
    isRoundTrip: flightType === flightTypes.roundTrip,
    isLegendOpen: state.isLegendOpen,
    setIsLegendOpen: updateIsLegendOpen,
  };
};

export { useFlightSearch };
