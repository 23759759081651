/* eslint-disable @typescript-eslint/no-empty-function */
import React, {
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  ChildContainer,
  Container,
  ContentContainer,
  CopyContainer,
  CopyStyled,
  CopyWrapper,
  CustomPeekDayContainer,
  LegendContainer,
  LegendSection,
  StyledDivider,
} from './CustomSubpage.styles';
import {
  IInputModalContainer,
  ILegend,
} from './CustomSubpage.interfaces';
import { typography } from '@constants/styles/typography.constants';
import { solidColors } from '@constants/styles/colors.constants';
import { useAppSelector } from '@app/hooks';
import ButtonSection from './ButtonSection';
import {
  Button,
  Link,
  ModalWithTitle,
  Typography,
} from '@wheelsup/wu-react-components';
import { useViewport } from '@hooks';
import { ImageImgix } from '@atoms';
import { useOverlayTriggerState } from '@react-stately/overlays';
import { useFlightSearch } from '../SubPageFlightSearchV2/FlightSearchContext/FlightSearchHooks';
import { FlightSearchContext } from '../SubPageFlightSearchV2/FlightSearchContext/FlightSearchContext';

const LegendModal = ({
  legends,
  state,
}: {
  legends: ILegend[];
  state: any;
}) => {
  const { setIsLegendOpen } = useFlightSearch(
    useContext(FlightSearchContext),
  );

  return (
    <div>
      <ModalWithTitle title={'Legend'} state={state}>
        <LegendSection>
          <ul>
            {legends?.length > 0 &&
              legends.map((legend, index) => (
                <li key={index}>
                  <span>
                    <ImageImgix
                      width={legend.icon.width}
                      src={legend.icon.url}
                      alt={legend.copy}
                    />
                  </span>
                  {legend.copy}
                </li>
              ))}
          </ul>
        </LegendSection>
        <Button
          variant="primaryInCard"
          onPress={() => {
            state.close();
            setIsLegendOpen(false);
          }}
        >
          Ok
        </Button>
      </ModalWithTitle>
    </div>
  );
};

const CustomSubpage = ({
  children,
  width,
  copy,
  scrollVisible,
  button,
  action,
  maxHeight,
  offsetTop,
  height,
  legends,
  tripType,
  isCalendar,
  isButtonDisabled,
  selectedPrice,
  showPrice,
  alignRight,
  actionTopGap
}: IInputModalContainer) => {
  const modalState = useOverlayTriggerState({});
  const { setIsLegendOpen } = useFlightSearch(
    useContext(FlightSearchContext),
  );

  // TODO: delete this when refactor goes in
  const { shouldLoadPrice } = useAppSelector(
    (state) => state.searchFlight,
  );

  useEffect(() => {
    const subPageContainer = document.getElementById(
      'subpage',
    );
    if (subPageContainer) {
      subPageContainer.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }
  }, []);
  const { width: viewportWidth } = useViewport();
  const isDesktop = viewportWidth > 1000;

  return (
    <Container
      width={width}
      isCalendar={isCalendar}
      data-id="CustomSubpage"
      maxHeight={maxHeight}
      offsetTop={offsetTop}
    >
      {isCalendar && !isDesktop && (
        <div>
          <LegendContainer>
            <div>Dates</div>
            <div
              onClick={(e) => {
                modalState.open();
                setIsLegendOpen(true);
              }}
            >
              <Typography variant="cta">Legend</Typography>
            </div>
          </LegendContainer>

          <StyledDivider />
        </div>
      )}
      <ContentContainer isScrollEnable={scrollVisible}>
        <ChildContainer height={height}>
          {children}
        </ChildContainer>
      </ContentContainer>
      <CopyContainer
        copy={copy !== undefined || legends !== undefined}
      >
        {copy && (
          <CopyWrapper>
            <CustomPeekDayContainer />

            <CopyStyled
              variant={typography.body1}
              color={solidColors.darkGray}
            >
              {copy}
            </CopyStyled>
          </CopyWrapper>
        )}
        {action && (
          <ButtonSection
            alignRight={alignRight}
            isCalendar={isCalendar}
            legends={legends}
            selectedPrice={selectedPrice}
            shouldLoadPrice={shouldLoadPrice || showPrice}
            action={action}
            tripType={tripType}
            isButtonDisabled={isButtonDisabled}
            button={button}
            topGap={actionTopGap}
          />
        )}
      </CopyContainer>
      {legends && legends.length > 0 && (
        <LegendModal
          state={modalState}
          legends={legends as ILegend[]}
        />
      )}
    </Container>
  );
};

export default CustomSubpage;
