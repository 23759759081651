import React, { useState } from 'react';
import { ISwitchButton } from '@components/types';
import { Typography } from '@atoms';
import styled from 'styled-components';
import { Color } from '@constants/styles/colors.constants';
import {
  ITypographyObject,
  typography,
} from '@constants/styles/typography.constants';
import Switch from '@atoms/Switch/Switch';

export interface ITextGroupSwitch extends ISwitchButton {
  copy: string;
  direction: 'left' | 'right';
  textStyle?: ITypographyObject;
  color: Color;
}

const StyledContainer = styled.div<{
  direction: 'left' | 'right';
}>`
  display: flex;
  flex-direction: ${({ direction }) =>
    direction === 'left' ? 'row' : 'row-reverse'};
  align-items: center;
  width: fit-content;
`;

export default function TextGroupSwitch({
  checked,
  copy,
  direction,
  disabled,
  color,
  textStyle,
  onChange,
}: ITextGroupSwitch) {
  const [isChecked, setIsChecked] = useState(checked);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setIsChecked(!isChecked);
    onChange(e);
  };

  return (
    <StyledContainer direction={direction}>
      <Typography
        variant={textStyle ?? typography.body2}
        color={color}
      >
        {copy}
      </Typography>
      <Switch
        id={copy}
        checked={isChecked}
        disabled={disabled}
        onChange={handleChange}
      />
    </StyledContainer>
  );
}
