import styled from 'styled-components';
import { solidColors } from '@constants/styles/colors.constants';
import { ImageImgix } from '@atoms';

export const CarouselContainer = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
`;

export const CarouselSlideWrapper = styled.div<{
  $isMobile: boolean;
}>`
  width: 100%;
  display: inline-flex;
  margin-bottom: 16px;
`;

export const CarouselSlidesContainer = styled.div<{
  $numItemsPerSlide?: number;
}>`
  display: flex;
  min-width: 100%;
  height: 100%;
  padding: 0.25rem 0 0.175rem 0.25rem;
  ${({ $numItemsPerSlide }) => {
    if ($numItemsPerSlide === 1) {
      return `
        padding-top: 8px;
        padding-bottom: 24px;
      `;
    }
  }}
`;

export const CarouselSlide = styled.div<{
  $translateX: number;
  $active: boolean;
  $marginRight: number;
  $inComponent: boolean;
  $width: number;
  $numItemsPerSlide?: number;
  $fullCardWidthOverride?: boolean;
}>`
  ${({ $active }) => {
    return $active
      ? ''
      : `
        display: none;
      `;
  }}
  margin-right: ${({ $marginRight }) =>
    `${$marginRight}px`};
  transform: translateX(
    ${({ $translateX }) => $translateX}px
  );
  display: inline-flex;
  transition: transform 1s ease;
  scroll-snap-align: start;
  ${({ $width, $numItemsPerSlide }) =>
    $numItemsPerSlide
      ? `
          // min-width: ${
            $width / $numItemsPerSlide
          }px !important;
        `
      : ''};
  padding-right: 16px;
  ${({ $inComponent, $width }) =>
    $inComponent && `min-width: ${$width}px !important;`}
  ${({ $fullCardWidthOverride }) => {
    if (!$fullCardWidthOverride) {
      return `
        &:last-child {
          margin-right: 0;
          padding-right: 0;
        }
      `;
    }
  }}
`;

export const ArrowContainer = styled.div`
  width: fit-content;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ArrowCircle = styled.div<{
  $active?: boolean;
}>`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid;
    min-width: 30px;
    min-height: 30px;
    border-color: ${solidColors.mediumGray.color};
    }};
`;

export const BackArrow = styled(ImageImgix)`
  transform: rotate(180deg);
`;

export const ArrowLabel = styled.div`
  width: 100%;
  margin: 0 12px;
`;

export const OverflowHiddenContainer = styled.div`
  overflow-x: hidden;
  width: 100%;
`;
