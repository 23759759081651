import Spacer from '@atoms/Spacer/Spacer';
import React, { useState, useEffect } from 'react';
import { IContainerColororImageBackground } from './ContainerColororImageBackgroundWEBONLY.interfaces';
import {
  BackgroundWrapper,
  CardContainer,
  ChildContainer,
} from './ContainerColororImageBackgroundWEBONLY.styles';
import { StyleType } from '@components/types';

const ContainerColororImageBackgroundWEBONLY = ({
  canContainDismissible,
  background,
  image,
  spacerTypeTop,
  spacerTypeBottom,
  styleType,
  children,
}: IContainerColororImageBackground) => {
  const [blueBackground, setBlueBackground] = useState(
    false,
  );

  useEffect(() => {
    background === 'blue'
      ? setBlueBackground(true)
      : setBlueBackground(false);
  }, []);

  return (
    <>
      {spacerTypeTop && <Spacer height={spacerTypeTop} />}

      {styleType && styleType === StyleType.card ? (
        // this is a temporary solution to have container that render as cards,
        // after MVP we should find a better solution
        <CardContainer data-name="ContainerColororImageBackgroundWEBONLY">
          {children}
        </CardContainer>
      ) : (
        <BackgroundWrapper
          data-name="ContainerColororImageBackgroundWEBONLY"
          image={image?.url}
          blueBackground={blueBackground}
        >
          <ChildContainer>{children}</ChildContainer>
        </BackgroundWrapper>
      )}

      {spacerTypeBottom && (
        <Spacer height={spacerTypeBottom} />
      )}
    </>
  );
};

export default ContainerColororImageBackgroundWEBONLY;
