import React from 'react';
import { IContainerTripLuggageEditor } from '@components/molecules/ContainerTripLuggageEditor/ContainerTripLuggageEditor.interfaces';
import { IContainerTripPassengerEditor } from '@components/molecules/ContainerTripPassengerEditor/ContainerTripPassengerEditor.interfaces';
import {
  ContainerTripLuggageEditor,
  ContainerTripPassengerEditor,
} from '@components/molecules';
import styled from 'styled-components';

interface ISubPageTripDetailsWebOnly {
  tripLuggageEditor: {
    data: IContainerTripLuggageEditor;
  };
  tripPassengerEditor: {
    data: IContainerTripPassengerEditor;
  };
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export default function SubPageTripDetailsWebOnly({
  tripLuggageEditor,
  tripPassengerEditor,
}: ISubPageTripDetailsWebOnly) {
  return (
    <StyledContainer
      /*
                id used to get width in
                    - ContainerTripPassengerEditor
                    - ContainerTripLuggageEditor
                    - FlightLegItemPassengerInfo
            */
      id="SubPageTripDetailsWebOnly"
      className="subpage-trip-details-web-only"
    >
      <ContainerTripPassengerEditor
        {...tripPassengerEditor.data}
      />
      <ContainerTripLuggageEditor
        {...tripLuggageEditor.data}
      />
    </StyledContainer>
  );
}
