import styled from 'styled-components';
import { CardAtom } from '@atoms';
import { solidColors } from '@constants/styles/colors.constants';

export const CardOversizedWrapper = styled(CardAtom)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  max-height: 610px;

  ${(props) => props.theme.breakpoints.down('md')} {
    flex-direction: column-reverse;
    height: auto;
    max-height: initial;
  }
`;

export const FirstSideCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 40%;
  min-height: 34rem;
  padding: 2rem;
  z-index: 3;

  background: ${solidColors.upWhite.color};

  > * {
    margin-bottom: 1rem;
  }

  ${(props) => props.theme.breakpoints.down('md')} {
    width: 100%;
  }
`;

export const OversizedSideCard = styled.div`
  background-color: ${solidColors.mediumGray.color};
  border-radius: 0 5px 5px 0;
  position: relative;
  width: 60%;

  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  ${(props) => props.theme.breakpoints.down('md')} {
    width: 100%;
    max-width: 100%;
    height: 300px;
    border-radius: 5px 5px 0 0;
  }

  ${(props) => props.theme.breakpoints.down('sm')} {
    width: 100%;
    max-width: 100%;
    height: 300px;
    border-radius: 5px 5px 0 0;
  }
`;

export const OptionalDateRange = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: 'flex-start';
  justify-content: space-between;
`;

export const OptionaList = styled.div`
  border-bottom: 1px solid ${solidColors.mediumGray.color};
  padding-bottom: 15px;
  width: 100%;

  h4 {
    margin-bottom: 1rem;
  }
`;

export const ListContainerCTA = styled.div`
  margin-right: auto;
  width: fit-content;
  margin-top: 10px;
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  max-height: 10rem;
  overflow-y: auto;
`;

export const ContainerSelectedPaymentMethod = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > * {
    margin-bottom: 10px;
  }
`;

export const ContactCTA = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
`;

export const ContactItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  margin-top: 40px;
  padding-top: 10px;

  > * {
    margin-bottom: 10px;
  }
`;
