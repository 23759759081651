import React, { useState } from 'react';
import {
  ArrowIconStyled,
  Button,
  ImageImgix,
  Typography,
} from '@atoms';
import { typography } from '@constants/styles/typography.constants';
import { ICardOversized } from './CardHeadlinesubcopyCTAImageAirmed.interfaces';
import {
  CardOversizedWrapper,
  FirstSideCard,
  OptionaList,
  ContactCTA,
  OversizedSideCard,
  OptionalDateRange,
  DateContainer,
  ListContainer,
  ContainerSelectedPaymentMethod,
  ContactItem,
  ListContainerCTA,
} from './CardHeadlinesubcopyCTAImageAirmed.styles';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  buttonPopupOpen,
  pagePopup,
  snackbarOpen,
} from '@features/Members/popupSlice';
import { useAppSelector } from '@app/hooks';
import MemberTabPopup from '@molecules/MemberTabPopup/MemberTabPopup';
import { saveDataInLocalStorage } from '@components/utils/storage';
import { checkIsTokenValid } from '@services/authService/authService';
import { useViewport } from '@hooks';
import { IImgixFit } from '@services/imgix/imgix.interfaces';
import { paths } from '@routing/routerPaths';
import TextGroupBodyCopyLink from '../TextGroupBodyCopyLink/TextGroupBodyCopyLink';

const CardHeadlinesubcopyCTAImageAirmed = ({
  headline,
  copy,
  startDate,
  endDate,
  list,
  price,
  selectedPaymentMethod,
  contact,
  image,
  button,
}: ICardOversized) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { width } = useViewport();
  const [activeCard, setActiveCard] = useState<any>('');
  const type: any = button?.action?.type;
  const { isButtonPopupOpen } = useAppSelector(
    (state) => state.popupOpen,
  );

  // takes a /src/client/inspirato/whatever/fix
  // returns inspirato*whatever*fix

  const splitUrl = button?.action?.data?.split(
    'src/client/',
  )[1];

  const joinedStarUrl = splitUrl
    ? splitUrl.split('/').join('*')
    : '';

  const handleIframeClick = () => {
    saveDataInLocalStorage(
      'shouldShowLoadingPageWeb',
      true,
    );
    checkIsTokenValid();
    return navigate(`/members/${joinedStarUrl}`);
  };

  const handleMembershipLock = () => {
    dispatch(buttonPopupOpen(true));
    dispatch(pagePopup(true));
    dispatch(snackbarOpen(false));
    setActiveCard(headline);
  };

  const handleButtonClick = () => {
    switch (type) {
      case 'in_app_membersite':
        handleIframeClick();
        break;
      case 'page_next':
        navigate(paths.AIRMED_SIGNUP);
        break;
      case 'display_membership_lock':
        handleMembershipLock();
        break;
      case 'out_of_app_browser':
        window.open(button?.action?.data);
        break;
      case 'uri':
        // everything after the .com
        navigate(
          button?.action?.data?.split('.com')[1] || '',
        );
        break;
      default:
        return button?.action?.actionMethod
          ? button?.action?.actionMethod()
          : null;
    }
  };

  const cardImageProps = {
    fit: 'crop' as IImgixFit,
    src: image.url,
    filter: image.tintColor,
    alt: 'Oversized Image',
    borderRadius: '0',
    width: width > 700 ? 1000 : undefined,
    height: width > 700 ? 600 : 358,
  };

  return (
    <>
      <CardOversizedWrapper data-name="CardHeadlinesubcopyCTAImageAirmed">
        {isButtonPopupOpen && headline === activeCard && (
          <MemberTabPopup />
        )}
        <FirstSideCard>
          <Typography variant={typography.heading01Large}>
            {headline}
          </Typography>

          <Typography variant={typography.body2}>
            {copy}
          </Typography>

          {startDate && endDate && (
            <OptionalDateRange>
              <DateContainer>
                <Typography variant={typography.body2a}>
                  {'Start Date'}
                </Typography>
                <Typography variant={typography.body1a}>
                  {startDate}
                </Typography>
              </DateContainer>
              <DateContainer>
                <Typography variant={typography.body2a}>
                  {'End Date'}
                </Typography>
                <Typography variant={typography.body1a}>
                  {endDate}
                </Typography>
              </DateContainer>
            </OptionalDateRange>
          )}

          {list && (
            <OptionaList>
              <Typography
                variant={typography.heading04Large}
              >
                {list.title}
              </Typography>
              <ListContainer>
                {list.listItems.map((item, idx) => (
                  <Typography
                    variant={typography.body1a}
                    key={idx}
                  >
                    {`- ${item}`}
                  </Typography>
                ))}
              </ListContainer>
              {list.cta && list.listItems.length < 12 && (
                <ListContainerCTA>
                  <TextGroupBodyCopyLink {...list.cta} />
                </ListContainerCTA>
              )}
            </OptionaList>
          )}

          {price && !selectedPaymentMethod && (
            <Typography variant={typography.heading01Large}>
              {price}
            </Typography>
          )}

          {selectedPaymentMethod && (
            <ContainerSelectedPaymentMethod>
              <Typography variant={typography.body2a}>
                {'Selected payment method'}
              </Typography>
              <Typography variant={typography.body1a}>
                {selectedPaymentMethod}
              </Typography>
              <br />
              <Typography variant={typography.body2a}>
                {'Yearly Total Cost'}
              </Typography>
              <Typography variant={typography.body1a}>
                {price}
              </Typography>
            </ContainerSelectedPaymentMethod>
          )}

          {button && (
            <Button
              action={{
                actionMethod: handleButtonClick,
                ...button.action,
              }}
              styleType={button?.styleType}
              title={button?.title}
              nativeIcon={<ArrowIconStyled />}
            />
          )}
        </FirstSideCard>
        <OversizedSideCard>
          <ImageImgix {...cardImageProps} />
        </OversizedSideCard>
      </CardOversizedWrapper>
      {contact && (
        <ContactCTA>
          <ContactItem>
            <Typography variant={typography.heading04Large}>
              {contact.title}
            </Typography>
            <TextGroupBodyCopyLink {...contact.cta} />
          </ContactItem>
          <ContactItem>
            {contact.title2 && (
              <Typography
                variant={typography.heading04Large}
              >
                {contact.title2}
              </Typography>
            )}
            {contact.cta2 && (
              <TextGroupBodyCopyLink {...contact.cta2} />
            )}
            {contact.disclaimer && (
              <Typography variant={typography.caption1a}>
                {contact.disclaimer}
              </Typography>
            )}
          </ContactItem>
        </ContactCTA>
      )}
    </>
  );
};

export default CardHeadlinesubcopyCTAImageAirmed;
