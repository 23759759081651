import { solidColors } from '@constants/styles/colors.constants';
import { CardAtom, Typography } from '@atoms';
import styled from 'styled-components';
import { theme } from '@constants/styles/theme.constants';

export const StyledCard = styled(CardAtom)<{
  $width: number;
}>`
  background-color: #ffffff;
  min-width: ${(props) => props.$width * 0.39}px;
  position: relative;
  height: fit-content;
  padding: 32px;

  ${(props) =>
    props.theme.breakpoints.down(
      theme.breakpoints.values.md,
    )} {
    min-width: ${(props) => props.$width}px;
    padding: 16px;
  }

  .styled-grid {
    > div {
      padding: 13px 0;
      border-bottom: 1px solid
        ${solidColors.mediumGray.color};
    }
    .checkbox-wrapper {
      label p {
        user-select: none;
      }
    }
  }
`;

export const StyledCardSearchTripDetails = styled(
  CardAtom,
)<{
  $width: number;
}>`
  background-color: #ffffff;
  min-width: 100%;
  position: relative;
  height: fit-content;
  padding: 32px;

  ${({ theme }) =>
    theme.breakpoints.down(theme.breakpoints.values.md)} {
    padding: 16px;
  }

  .styled-grid {
    > div {
      padding: 13px 0;
      border-bottom: 1px solid
        ${solidColors.mediumGray.color};
    }
    .checkbox-wrapper {
      label p {
        user-select: none;
      }
    }
  }
`;

export const StyledWrapTitle = styled.div`
  display: flex;
  button {
    min-width: 32px;
    padding-left: 0;
    padding-right: 0;
    .MuiButton-endIcon {
      margin-left: 0;
    }
  }
`;

export const StyledSubitle = styled(Typography)`
  margin-top: 7px;
  margin-bottom: 25px;
`;

export const StyledMaxLuggageAlertWrapper = styled.div`
  .MuiContainer-root {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 8px;
    justify-content: flex-start;

    div {
      justify-content: flex-start;
    }

    > div {
      > div {
        width: 100%;
      }
    }
  }
`;

export const StickyContainer = styled.div`
  margin-top: 7px;
  background-color: ${solidColors.lightGray.color};
  padding: 16px;
  border-radius: 8px;
`;
