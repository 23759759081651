import { Switch as MuiSwitch } from '@components/library';
import { ISwitchButton } from '@components/types';
import { useSwitchStyles } from './Switch.styles';

const Switch = ({
  id,
  checked,
  onChange,
  disabled,
}: ISwitchButton) => {
  const classes = useSwitchStyles();

  return (
    <MuiSwitch
      id={id ?? ''}
      focusVisibleClassName={classes.focusVisible}
      disabled={disabled}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      checked={checked}
      onChange={onChange}
    />
  );
};

export default Switch;
