import { solidColors } from '@constants/styles/colors.constants';
import { Typography } from '@wheelsup/wu-react-components';
import {
  StyledCard,
  StyledDepartureArrivalContainer,
} from './ContainerMultiLegTripFlightSummary.styles';
import { IContainerMultiLegTripFlightSummary } from './ContainerMultiLegTripFlightSummary.interfaces';
import { ListAccordionItem } from '@molecules';
import Spacer from '@atoms/Spacer/Spacer';
import { Divider } from '@components/library';

const ContainerMultiLegTripFlightSummary = ({
  button,
  children,
  spacerTypeTop,
  spacerTypeBottom,
}: IContainerMultiLegTripFlightSummary) => {
  return (
    <>
      {spacerTypeTop && <Spacer height={spacerTypeTop} />}
      <StyledCard data-name="ContainerMultiLegTripFlightSummary">
        <StyledDepartureArrivalContainer>
          <Typography
            variant="heading01"
            className="typoBackground"
          >
            {`${children.length} flights`}
          </Typography>
        </StyledDepartureArrivalContainer>
        <Divider />
        <ListAccordionItem
          title={button.title ?? ''}
          body={children}
          direction={'reverse'}
          headerType={'cta'}
          typographyColor={solidColors.upBlue}
        />
      </StyledCard>
      {spacerTypeBottom && (
        <Spacer height={spacerTypeBottom} />
      )}
    </>
  );
};

export default ContainerMultiLegTripFlightSummary;
