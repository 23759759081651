import styled from 'styled-components';
import { CardAtom, ImageImgix } from '@atoms';

export const StyledCard = styled(CardAtom)`
  display: flex;
  padding: 35px 20px;
  background-color: #ffffff;
  min-width: 100%;

  ${(props) => props.theme.breakpoints.down('sm')} {
    flex-wrap: wrap;
  }
`;

export const StyledMedia = styled.div`
  margin: 0;
`;

export const StyledInfo = styled.div`
  margin-left: 30px;

  .title {
    margin-bottom: 8px;
  }

  ${(props) => props.theme.breakpoints.down('xs')} {
    margin-left: 0;
    margin-top: 28px;
  }
`;

export const StyledItems = styled.div`
  margin-top: 10px;
`;

export const StyledItem = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

export const StyledIcon = styled(ImageImgix)`
  height: 1.25rem;
  margin-right: 0.5rem;
`;
