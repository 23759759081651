//This Code is auto generated by graphql-codegen, DO NOT EDIT
//You can update the queries or mutations in *.graphql to generate any new changes.
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
} from '@tanstack/react-query';
import { useAxios } from './axiosHelper';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<
  T,
  K
> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigDecimal: any;
  BigInteger: any;
  Date: any;
  DateTime: any;
  Email: any;
  EpochTimestamp: any;
  GUID: any;
  ISO8601Date: any;
  ISO8601DateTime: any;
  JSON: any;
  LocalDate: any;
  LocalDateTime: any;
  LocalTime: any;
  Long: any;
  Object: any;
  UUID: any;
  Url: any;
  ZonedDateTime: any;
};

export type AdsAddress = {
  __typename?: 'ADSAddress';
  city?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  countryISOCode2?: Maybe<Scalars['String']>;
  countryISOCode3?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stateCode?: Maybe<Scalars['String']>;
  streetLine1?: Maybe<Scalars['String']>;
  streetLine2?: Maybe<Scalars['String']>;
  streetLine3?: Maybe<Scalars['String']>;
};

export type AdsAddressRequest = {
  city: Scalars['String'];
  country: Scalars['String'];
  county?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  province?: InputMaybe<Scalars['String']>;
  state: Scalars['String'];
  streetLine1?: InputMaybe<Scalars['String']>;
  streetLine2?: InputMaybe<Scalars['String']>;
  streetLine3?: InputMaybe<Scalars['String']>;
};

export type AdsAuditLog = {
  __typename?: 'ADSAuditLog';
  application?: Maybe<Scalars['String']>;
  changeDetails?: Maybe<Array<AdsChangeDetail>>;
  createdOn?: Maybe<Scalars['LocalDateTime']>;
  data?: Maybe<Scalars['Object']>;
  entityId: Scalars['Long'];
  entityType: Scalars['String'];
  eventType?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  parentId?: Maybe<Scalars['Long']>;
  parentType?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
};

export type AdsChangeDetail = {
  __typename?: 'ADSChangeDetail';
  changeType?: Maybe<Scalars['String']>;
  currentValue?: Maybe<Scalars['Object']>;
  previousValue?: Maybe<Scalars['Object']>;
  propertyName?: Maybe<Scalars['String']>;
  propertyNameWithPath?: Maybe<Scalars['String']>;
};

/**
 * An account is an entity that represents a payment method.
 * One member may have multiple accounts.
 */
export type Account = {
  __typename?: 'Account';
  /**  A unique key for an account */
  accountId: Scalars['ID'];
  /**  The working unit of the account */
  accountType: AccountType;
  /**  Whether or not the account is active */
  active: Scalars['Boolean'];
  /**
   *  whether or not this account can have statements generated for it.
   * @deprecated use 'program.authorizedToStatement' instead
   */
  authorizedToStatement: Scalars['Boolean'];
  /**  The balances for the account */
  balances: AccountBalance;
  /**
   *  The type of the account
   * @deprecated use 'program.code' instead
   */
  description: Scalars['String'];
  forfeitDate?: Maybe<Scalars['ISO8601Date']>;
  guaranteeExpirationDate?: Maybe<Scalars['ISO8601Date']>;
  /**  The account name */
  name: Scalars['String'];
  /**  The account number, as seen in Atlas. */
  number: Scalars['String'];
  program: AccountProgram;
  /**  When the account was created */
  startDate: Scalars['ISO8601DateTime'];
};

export type AccountBalance = {
  __typename?: 'AccountBalance';
  /**  The current balance of the account. */
  currentBalance: Currency;
  /**  The projected balance after all pending transactions are processed. */
  projectedBalance: Currency;
};

export type AccountProgram = {
  __typename?: 'AccountProgram';
  authorizedToForfeit: Scalars['Boolean'];
  authorizedToStatement: Scalars['Boolean'];
  code: Scalars['String'];
  name: Scalars['String'];
};

export enum AccountType {
  Currency = 'CURRENCY',
  Hours = 'HOURS',
}

export type Aircraft = {
  __typename?: 'Aircraft';
  aircraftId: Scalars['ID'];
  aircraftType?: Maybe<AircraftType>;
  color?: Maybe<AircraftColor>;
  description?: Maybe<Scalars['String']>;
  displayDetails: AircraftDisplayDetails;
  fmsGUID?: Maybe<Scalars['String']>;
  manufactureDate?: Maybe<Scalars['LocalDate']>;
  serialNumber?: Maybe<Scalars['String']>;
  tailNumber: Scalars['String'];
};

export type AircraftCabin = {
  __typename?: 'AircraftCabin';
  aircraftCabinId: Scalars['ID'];
  code?: Maybe<Scalars['String']>;
  defaultAircraftType?: Maybe<AircraftType>;
  description?: Maybe<Scalars['String']>;
  displayDetails: AircraftDisplayDetails;
  images?: Maybe<Array<AircraftImage>>;
  luggageCapacity?: Maybe<Array<AircraftLuggageCapacity>>;
  name?: Maybe<Scalars['String']>;
  passengerLimit?: Maybe<AircraftPassengerLimit>;
  sortOrder?: Maybe<Scalars['Int']>;
};

/** The category of the AircraftInfo object. This is considered to be unique. There will never be two AircraftInfo objects with the same AircraftCategory */
export enum AircraftCategory {
  Helicopter = 'HELICOPTER',
  Large = 'LARGE',
  Light = 'LIGHT',
  Midsize = 'MIDSIZE',
  SuperMidsize = 'SUPER_MIDSIZE',
  Turboprop = 'TURBOPROP',
  Ultra = 'ULTRA',
}

export type AircraftColor = {
  __typename?: 'AircraftColor';
  exterior?: Maybe<Scalars['String']>;
  interior?: Maybe<Scalars['String']>;
};

export type AircraftDataServiceAuditLog = {
  __typename?: 'AircraftDataServiceAuditLog';
  application?: Maybe<Scalars['String']>;
  changeDetails?: Maybe<
    Array<AircraftDataServiceChangeDetail>
  >;
  createdOn?: Maybe<Scalars['LocalDateTime']>;
  data?: Maybe<Scalars['Object']>;
  entityId: Scalars['Long'];
  entityType: Scalars['String'];
  eventType?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  parentId?: Maybe<Scalars['Long']>;
  parentType?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
};

export type AircraftDataServiceChangeDetail = {
  __typename?: 'AircraftDataServiceChangeDetail';
  changeType?: Maybe<Scalars['String']>;
  currentValue?: Maybe<Scalars['Object']>;
  previousValue?: Maybe<Scalars['Object']>;
  propertyName?: Maybe<Scalars['String']>;
  propertyNameWithPath?: Maybe<Scalars['String']>;
};

export type AircraftDisplayDetails = {
  __typename?: 'AircraftDisplayDetails';
  additionalImages?: Maybe<Array<AircraftImage>>;
  category?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  luggageCapacity?: Maybe<Array<AircraftLuggageCapacity>>;
  maxPax?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  onFleet?: Maybe<Scalars['Boolean']>;
};

export type AircraftImage = {
  __typename?: 'AircraftImage';
  /**  The caption of the image */
  caption?: Maybe<Scalars['String']>;
  /**  The URL of the image */
  photoUrl: Scalars['String'];
};

/** Object that represents the static content for an aircraft of a certain AircraftCategory. FE clients could pull info from this central object for use on many pages */
export type AircraftInfo = {
  __typename?: 'AircraftInfo';
  /** List of image urls of the aircraft details cards */
  aircraftDetailsImages: Array<Scalars['String']>;
  /** The unique aircraft category for the AircraftInfo */
  category: AircraftCategory;
  /** List of image urls of the aircraft for the search results cards */
  searchResultsImages: Array<Scalars['String']>;
  /** Image url of the aircraft for the upcoming flight cards */
  upcomingFlightImage?: Maybe<Scalars['String']>;
};

export type AircraftInformation =
  | Aircraft
  | AircraftCabin
  | AircraftType
  | SingleSearchAircraftDetails;

export type AircraftLuggageCapacity = {
  __typename?: 'AircraftLuggageCapacity';
  /**  The maximum number of bags that can fit in this luggage capacity */
  maxBags: Scalars['Int'];
  /**  The type of luggage capacity, for example, "maxLimit", or "redLimit" */
  name: Scalars['String'];
};

export type AircraftPassengerLimit = {
  __typename?: 'AircraftPassengerLimit';
  maxPax?: Maybe<Scalars['Int']>;
  minPax?: Maybe<Scalars['Int']>;
};

export type AircraftType = {
  __typename?: 'AircraftType';
  aircraftCabin: AircraftCabin;
  aircraftTypeId: Scalars['ID'];
  averageSpeed?: Maybe<Scalars['Int']>;
  certifiedSeats?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  displayDetails: AircraftDisplayDetails;
  fmsGUID?: Maybe<Scalars['String']>;
  images?: Maybe<Array<AircraftImage>>;
  name?: Maybe<Scalars['String']>;
  normalSeats?: Maybe<Scalars['Int']>;
  statementName?: Maybe<Scalars['String']>;
};

export type Airport = {
  __typename?: 'Airport';
  active: Scalars['Boolean'];
  address: AdsAddress;
  aftn?: Maybe<Scalars['String']>;
  airportAircraftType?: Maybe<AirportAircraftType>;
  airportCategory?: Maybe<AirportCategory>;
  airportId: Scalars['ID'];
  alternateOptions?: Maybe<Array<AlternateAirport>>;
  approaches?: Maybe<Scalars['String']>;
  /**
   * # To support legacy endpoints
   * @deprecated Use new Field 'airportId'
   */
  atlasAirportId?: Maybe<Scalars['Long']>;
  clearances?: Maybe<Array<Clearance>>;
  /**
   * # TO support legacy federation
   * @deprecated Use new field 'displayCode'
   */
  code: Scalars['String'];
  controlTowerHours?: Maybe<Scalars['String']>;
  customs?: Maybe<Scalars['Boolean']>;
  designatedInternational: Scalars['Boolean'];
  /** @deprecated marked for deprecation */
  directoryGUID?: Maybe<Scalars['String']>;
  displayCode: Scalars['String'];
  displayName: Scalars['String'];
  domesticBufferZoneRelevant?: Maybe<Scalars['Boolean']>;
  elevation?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  faa?: Maybe<Scalars['String']>;
  fbos?: Maybe<Array<Fbo>>;
  fireCategory?: Maybe<Scalars['String']>;
  fireCategoryRemarks?: Maybe<Scalars['String']>;
  /**
   * # To support legacy endpoints
   * @deprecated Use new Field 'airportId'
   */
  fmsAirportId?: Maybe<Scalars['Long']>;
  frequencies?: Maybe<Array<AirportFrequency>>;
  fuelType?: Maybe<Scalars['String']>;
  handlingRequired: Scalars['Boolean'];
  highDensityAirport?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  ianaTimeZone: Scalars['String'];
  iata?: Maybe<Scalars['String']>;
  icao?: Maybe<Scalars['String']>;
  information?: Maybe<Array<AirportInformation>>;
  internationalZone?: Maybe<Scalars['Int']>;
  latitude: Scalars['Float'];
  /**
   * # Atlas note field
   * @deprecated Use new Field 'note'
   */
  legacyNote?: Maybe<Scalars['String']>;
  lighting?: Maybe<Scalars['String']>;
  longitude: Scalars['Float'];
  name: Scalars['String'];
  notes?: Maybe<Array<AirportNote>>;
  open24Hours?: Maybe<Scalars['String']>;
  outsideOperatingRegion?: Maybe<Scalars['Boolean']>;
  pcn?: Maybe<Scalars['String']>;
  portOfEntry: PortOfEntry;
  /**
   * # Atlas field
   * @deprecated Marked for deprecation. Use preferred flag in FBO type
   */
  preferredFBOId?: Maybe<Scalars['Long']>;
  primaryRunway?: Maybe<Scalars['String']>;
  restrictions?: Maybe<Array<AirportRestriction>>;
  runwayLength?: Maybe<Scalars['Int']>;
  runwayWidth?: Maybe<Scalars['Int']>;
  runways?: Maybe<Array<AirportRunway>>;
  schedule?: Maybe<Array<AirportSchedule>>;
  serviceRegion?: Maybe<Scalars['String']>;
  serviceZone?: Maybe<Scalars['String']>;
  sita?: Maybe<Scalars['String']>;
  siteNumber?: Maybe<Scalars['String']>;
  slotsRequired?: Maybe<Scalars['Boolean']>;
  suppliers?: Maybe<Array<AirportSupplier>>;
  timeZoneOffset?: Maybe<Scalars['String']>;
  viewable?: Maybe<Array<AirportViewable>>;
  website?: Maybe<Scalars['String']>;
  westOfTheMississippi?: Maybe<Scalars['Boolean']>;
  windowsTimeZoneInfoId: Scalars['String'];
};

export type AirportAlternateOptionsArgs = {
  filter?: InputMaybe<AlternateAirportsFilter>;
};

export type AirportFbosArgs = {
  filter?: InputMaybe<FboFilter>;
};

export type AirportNotesArgs = {
  filter?: InputMaybe<AirportNoteFilter>;
};

export type AirportScheduleArgs = {
  filter?: InputMaybe<AirportScheduleFilter>;
};

export type AirportSuppliersArgs = {
  filter?: InputMaybe<AirportSupplierFilter>;
};

export type AirportAircraftType = {
  __typename?: 'AirportAircraftType';
  airportAircraftTypeId: Scalars['ID'];
  code: Scalars['String'];
  name: Scalars['String'];
};

export type AirportCategory = {
  __typename?: 'AirportCategory';
  airportCategoryId: Scalars['ID'];
  code: Scalars['String'];
  name: Scalars['String'];
};

export type AirportClosureReason = {
  __typename?: 'AirportClosureReason';
  active: Scalars['Boolean'];
  airportClosureReasonId: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type AirportClosureReasonRequest = {
  active: Scalars['Boolean'];
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type AirportCustomsHours = AirportSchedule & {
  __typename?: 'AirportCustomsHours';
  airportScheduleId: Scalars['ID'];
  closed: Scalars['Boolean'];
  comments?: Maybe<Scalars['String']>;
  dayOfWeek: DayOfWeek;
  dayOnly?: Maybe<Scalars['Boolean']>;
  /**
   * # To support legacy endpoints
   * @deprecated Use new Field 'airportScheduleId'
   */
  fmsAirportScheduleId?: Maybe<Scalars['String']>;
  fromTime?: Maybe<Scalars['LocalTime']>;
  open24Hours: Scalars['Boolean'];
  severity: AirportScheduleSeverity;
  toTime?: Maybe<Scalars['LocalTime']>;
  type: AirportScheduleType;
};

export type AirportCustomsHoursRequest = {
  closed: Scalars['Boolean'];
  comments?: InputMaybe<Scalars['String']>;
  dayOfWeek: Scalars['String'];
  dayOnly: Scalars['Boolean'];
  fromTime?: InputMaybe<Scalars['LocalTime']>;
  open24Hours: Scalars['Boolean'];
  toTime?: InputMaybe<Scalars['LocalTime']>;
};

export type AirportFrequency = {
  __typename?: 'AirportFrequency';
  airportFrequencyId: Scalars['ID'];
  commsType?: Maybe<Scalars['String']>;
  /**
   * # To support legacy endpoints
   * @deprecated Use new Field 'airportFrequencyId'
   */
  fmsAirportFrequencyId?: Maybe<Scalars['Long']>;
  frequency?: Maybe<Scalars['String']>;
};

export type AirportFrequencyRequest = {
  commsType: Scalars['String'];
  frequency: Scalars['String'];
};

export type AirportInformation = {
  __typename?: 'AirportInformation';
  afterHoursTelephone?: Maybe<Scalars['String']>;
  aftn?: Maybe<Scalars['String']>;
  airportInformationId: Scalars['ID'];
  details: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  /**
   * # To support legacy endpoints
   * @deprecated Use new Field 'airportInformationId'
   */
  fmsAirportInformationId?: Maybe<Scalars['Long']>;
  frequency?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  sita?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export type AirportInformationRequest = {
  afterHoursTelephone?: InputMaybe<Scalars['String']>;
  aftn?: InputMaybe<Scalars['String']>;
  details: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  fax?: InputMaybe<Scalars['String']>;
  frequency?: InputMaybe<Scalars['String']>;
  hours?: InputMaybe<Scalars['String']>;
  remarks?: InputMaybe<Scalars['String']>;
  sita?: InputMaybe<Scalars['String']>;
  telephone?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
};

export type AirportNote = {
  __typename?: 'AirportNote';
  active: Scalars['Boolean'];
  airportNoteId: Scalars['ID'];
  description: Scalars['String'];
  endDateTime?: Maybe<Scalars['LocalDateTime']>;
  /**
   * # To support legacy endpoints
   * @deprecated Use new Field 'airportNoteId'
   */
  fmsAirportNoteId?: Maybe<Scalars['Long']>;
  severity: AirportNoteSeverity;
  startDateTime?: Maybe<Scalars['LocalDateTime']>;
  subject: Scalars['String'];
  type: AirportNoteType;
};

export type AirportNoteFilter = {
  includePastNotes?: InputMaybe<Scalars['Boolean']>;
};

export type AirportNoteRequest = {
  active: Scalars['Boolean'];
  description: Scalars['String'];
  endDateTime?: InputMaybe<Scalars['LocalDateTime']>;
  severity: AirportNoteSeverity;
  startDateTime?: InputMaybe<Scalars['LocalDateTime']>;
  subject: Scalars['String'];
  type: AirportNoteType;
};

export enum AirportNoteSeverity {
  Critical = 'CRITICAL',
  Information = 'INFORMATION',
  Warning = 'WARNING',
}

export enum AirportNoteType {
  Customs = 'CUSTOMS',
  Operational = 'OPERATIONAL',
  Other = 'OTHER',
}

export type AirportOperatingHours = AirportSchedule & {
  __typename?: 'AirportOperatingHours';
  airportScheduleId: Scalars['ID'];
  closed: Scalars['Boolean'];
  comments?: Maybe<Scalars['String']>;
  dayOfWeek: DayOfWeek;
  dayOnly?: Maybe<Scalars['Boolean']>;
  /**
   * # To support legacy endpoints
   * @deprecated Use new Field 'airportScheduleId'
   */
  fmsAirportScheduleId?: Maybe<Scalars['String']>;
  fromTime?: Maybe<Scalars['LocalTime']>;
  open24Hours: Scalars['Boolean'];
  severity: AirportScheduleSeverity;
  toTime?: Maybe<Scalars['LocalTime']>;
  type: AirportScheduleType;
};

export type AirportOperatingHoursRequest = {
  closed: Scalars['Boolean'];
  comments?: InputMaybe<Scalars['String']>;
  dayOfWeek: Scalars['String'];
  dayOnly: Scalars['Boolean'];
  fromTime?: InputMaybe<Scalars['LocalTime']>;
  open24Hours: Scalars['Boolean'];
  toTime?: InputMaybe<Scalars['LocalTime']>;
};

export type AirportRequest = {
  active: Scalars['Boolean'];
  address: AdsAddressRequest;
  aftn?: InputMaybe<Scalars['String']>;
  airportAircraftTypeId?: InputMaybe<Scalars['Long']>;
  airportCategoryId?: InputMaybe<Scalars['Long']>;
  approaches?: InputMaybe<Scalars['String']>;
  atlasAirportId?: InputMaybe<Scalars['Long']>;
  controlTowerHours?: InputMaybe<Scalars['String']>;
  customs?: InputMaybe<Scalars['Boolean']>;
  designatedInternational: Scalars['Boolean'];
  displayName: Scalars['String'];
  domesticBufferZoneRelevant?: InputMaybe<
    Scalars['Boolean']
  >;
  elevation?: InputMaybe<Scalars['Int']>;
  email?: InputMaybe<Scalars['String']>;
  /** At least one is required (ICAO, FAA, IATA) */
  faa?: InputMaybe<Scalars['String']>;
  fireCategory?: InputMaybe<Scalars['String']>;
  fireCategoryRemarks?: InputMaybe<Scalars['String']>;
  fmsAirportId?: InputMaybe<Scalars['Long']>;
  fuelType?: InputMaybe<Scalars['String']>;
  handlingRequired: Scalars['Boolean'];
  highDensityAirport?: InputMaybe<Scalars['Boolean']>;
  hours?: InputMaybe<Scalars['String']>;
  ianaTimeZone: Scalars['String'];
  /** At least one is required (ICAO, FAA, IATA) */
  iata?: InputMaybe<Scalars['String']>;
  /** At least one is required (ICAO, FAA, IATA) */
  icao?: InputMaybe<Scalars['String']>;
  internationalZone?: InputMaybe<Scalars['Int']>;
  latitude: Scalars['Float'];
  legacyNote?: InputMaybe<Scalars['String']>;
  lighting?: InputMaybe<Scalars['String']>;
  longitude: Scalars['Float'];
  name: Scalars['String'];
  open24Hours?: InputMaybe<Scalars['String']>;
  outsideOperatingRegion?: InputMaybe<Scalars['Boolean']>;
  pcn?: InputMaybe<Scalars['String']>;
  portOfEntry: PortOfEntry;
  preferredFBOId?: InputMaybe<Scalars['Long']>;
  primaryRunway?: InputMaybe<Scalars['String']>;
  runwayLength?: InputMaybe<Scalars['Int']>;
  runwayWidth?: InputMaybe<Scalars['Int']>;
  sita?: InputMaybe<Scalars['String']>;
  siteNumber?: InputMaybe<Scalars['String']>;
  slotsRequired?: InputMaybe<Scalars['Boolean']>;
  timeZoneOffset?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
  westOfTheMississippi?: InputMaybe<Scalars['Boolean']>;
};

export type AirportRestriction = {
  __typename?: 'AirportRestriction';
  afterHoursTelephone?: Maybe<Scalars['String']>;
  aftn?: Maybe<Scalars['String']>;
  airportRestrictionId: Scalars['ID'];
  details: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  /**
   * # To support legacy endpoints
   * @deprecated Use new Field 'airportRestrictionId'
   */
  fmsAirportRestrictionId?: Maybe<Scalars['Long']>;
  frequency?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  sita?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export type AirportRestrictionRequest = {
  afterHoursTelephone?: InputMaybe<Scalars['String']>;
  aftn?: InputMaybe<Scalars['String']>;
  details: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  fax?: InputMaybe<Scalars['String']>;
  frequency?: InputMaybe<Scalars['String']>;
  hours?: InputMaybe<Scalars['String']>;
  remarks?: InputMaybe<Scalars['String']>;
  sita?: InputMaybe<Scalars['String']>;
  telephone?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
};

export type AirportRunway = {
  __typename?: 'AirportRunway';
  airportRunwayId: Scalars['ID'];
  edgeIntensity?: Maybe<Scalars['String']>;
  /**
   * # To support legacy endpoints
   * @deprecated Use new Field 'airportRunwayId'
   */
  fmsAirportRunwayId?: Maybe<Scalars['Long']>;
  length?: Maybe<Scalars['Float']>;
  pcn?: Maybe<Scalars['String']>;
  runwayIdentifier: Scalars['String'];
  surfaceTreatment?: Maybe<Scalars['String']>;
  surfaceType?: Maybe<Scalars['String']>;
  weightCap?: Maybe<Scalars['Float']>;
  weightCapDoubleDual?: Maybe<Scalars['Float']>;
  weightCapDual?: Maybe<Scalars['Float']>;
  weightCapDualTandem?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

export type AirportRunwayRequest = {
  edgeIntensity?: InputMaybe<Scalars['String']>;
  length?: InputMaybe<Scalars['Float']>;
  pcn?: InputMaybe<Scalars['String']>;
  runwayIdentifier: Scalars['String'];
  surfaceTreatment?: InputMaybe<Scalars['String']>;
  surfaceType?: InputMaybe<Scalars['String']>;
  weightCap?: InputMaybe<Scalars['Float']>;
  weightCapDoubleDual?: InputMaybe<Scalars['Float']>;
  weightCapDual?: InputMaybe<Scalars['Float']>;
  weightCapDualTandem?: InputMaybe<Scalars['Float']>;
  width?: InputMaybe<Scalars['Float']>;
};

export type AirportSchedule = {
  airportScheduleId: Scalars['ID'];
  /**
   * # To support legacy endpoints
   * @deprecated Use new Field 'airportScheduleId'
   */
  fmsAirportScheduleId?: Maybe<Scalars['String']>;
  severity: AirportScheduleSeverity;
  type: AirportScheduleType;
};

export type AirportScheduleFilter = {
  includePastSchedule?: InputMaybe<Scalars['Boolean']>;
  types?: InputMaybe<Array<AirportScheduleType>>;
};

export type AirportScheduleRequest = {
  closureTypeInput?: InputMaybe<AirportTemporaryClosureHoursRequest>;
  customsTypeInput?: InputMaybe<AirportCustomsHoursRequest>;
  operatingTypeInput?: InputMaybe<AirportOperatingHoursRequest>;
  severity: AirportScheduleSeverity;
  type: AirportScheduleType;
};

export enum AirportScheduleSeverity {
  Critical = 'CRITICAL',
  Info = 'INFO',
  Warning = 'WARNING',
}

export enum AirportScheduleType {
  Customs = 'CUSTOMS',
  Operating = 'OPERATING',
  TemporaryClosure = 'TEMPORARY_CLOSURE',
}

export type AirportSearchRequest = {
  /** Accepted Values: [All, Atlas, FMS, WU-Members] */
  application?: InputMaybe<Scalars['String']>;
  minRunwayLength?: InputMaybe<Scalars['Long']>;
  radius?: InputMaybe<Scalars['Float']>;
  radiusUnit?: InputMaybe<DistanceUnit>;
  searchText: Scalars['String'];
};

export type AirportSupplier = {
  __typename?: 'AirportSupplier';
  active: Scalars['Boolean'];
  afterHoursTelephone?: Maybe<Scalars['String']>;
  airportSupplierId: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  /**
   * # To support legacy endpoints
   * @deprecated Use new Field 'airportSupplierId'
   */
  fmsAirportSupplierId?: Maybe<Scalars['Long']>;
  frequency?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  tollFreeTelephone?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  website?: Maybe<Scalars['String']>;
};

export type AirportSupplierFilter = {
  includeDeactivated?: InputMaybe<Scalars['Boolean']>;
  types?: InputMaybe<Array<Scalars['String']>>;
};

export type AirportSupplierRequest = {
  active: Scalars['Boolean'];
  afterHoursTelephone?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  fax?: InputMaybe<Scalars['String']>;
  frequency?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  telephone?: InputMaybe<Scalars['String']>;
  tollFreeTelephone?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
  website?: InputMaybe<Scalars['String']>;
};

export type AirportTemporaryClosureHours = AirportSchedule & {
  __typename?: 'AirportTemporaryClosureHours';
  airportScheduleId: Scalars['ID'];
  closureReason: AirportClosureReason;
  comments?: Maybe<Scalars['String']>;
  daysOfWeek?: Maybe<Array<DayOfWeek>>;
  /**
   * # To support legacy endpoints
   * @deprecated Use new Field 'airportScheduleId'
   */
  fmsAirportScheduleId?: Maybe<Scalars['String']>;
  fromDate: Scalars['LocalDate'];
  fromTime?: Maybe<Scalars['LocalTime']>;
  severity: AirportScheduleSeverity;
  toDate: Scalars['LocalDate'];
  toTime?: Maybe<Scalars['LocalTime']>;
  type: AirportScheduleType;
};

export type AirportTemporaryClosureHoursRequest = {
  closureReasonId: Scalars['Long'];
  comments?: InputMaybe<Scalars['String']>;
  daysOfWeek: Array<DayOfWeek>;
  fromDate: Scalars['LocalDate'];
  fromTime: Scalars['LocalTime'];
  toDate: Scalars['LocalDate'];
  toTime: Scalars['LocalTime'];
};

export type AirportViewable = {
  __typename?: 'AirportViewable';
  airportViewableId: Scalars['ID'];
  /**  WU-Members, Atlas, FMS, etc */
  application: Scalars['String'];
  view: Scalars['Boolean'];
};

export type AirportWeather = {
  __typename?: 'AirportWeather';
  airport: Airport;
  /** Dummy ID to make Apollo Federation happy */
  airportWeatherId: Scalars['ID'];
  flightDateTime: Scalars['LocalDateTime'];
  weather?: Maybe<Weather>;
};

export type AlternateAirport = {
  __typename?: 'AlternateAirport';
  altAirport: Airport;
  distanceFromOriginal: Distance;
};

export type AlternateAirportsFilter = {
  limit?: InputMaybe<Scalars['Int']>;
  radius?: InputMaybe<Scalars['Float']>;
  radiusUnit?: InputMaybe<DistanceUnit>;
};

export type AlternateOptionMetadata = {
  __typename?: 'AlternateOptionMetadata';
  /**  Information on why the alternate option was suggested */
  description: AlternateSearchOptionDescription;
  /**  Information on what was altered about the flight */
  replaced: Array<SingleSearchReplacement>;
};

export type AlternatePaymentItem = {
  __typename?: 'AlternatePaymentItem';
  backupCard?: Maybe<PaymentCard>;
  isPaid?: Maybe<Scalars['Boolean']>;
  receivedOn?: Maybe<Scalars['ISO8601DateTime']>;
};

/**
 * Indicates that the alternate option has customs that will work better for the user.
 * Will only be returned for international flights.
 */
export type AlternateSearchOptionCustoms = {
  __typename?: 'AlternateSearchOptionCustoms';
  _?: Maybe<Scalars['String']>;
};

export type AlternateSearchOptionDescription =
  | AlternateSearchOptionCustoms
  | AlternateSearchOptionSavings;

/**  Indicates that the alternate option will cost less, and the savings percentile. */
export type AlternateSearchOptionSavings = {
  __typename?: 'AlternateSearchOptionSavings';
  savingsPercentile: Scalars['Int'];
};

export type AvailabilityOfferBookingRequest = {
  availabilityId: Scalars['String'];
  cateringCustomOrder?: InputMaybe<Scalars['String']>;
  groundCustomOrder?: InputMaybe<Scalars['String']>;
  /** Required for booking but not for calculating price breakdown */
  lockId?: InputMaybe<Scalars['String']>;
  luggage?: InputMaybe<Array<FlightLuggageInput>>;
  passengers?: InputMaybe<
    Array<OfferRequestPassengerInput>
  >;
  /** Required for booking but not for calculating price breakdown */
  paymentSplits?: InputMaybe<
    Array<RsBookingPaymentDetailsInput>
  >;
  scheduler: OfferRequestSchedulerInput;
  serviceCustomOrder?: InputMaybe<Scalars['String']>;
};

/**
 * A billing is an entity that represents a payment attached to a flight
 * It may represent a payment for catering, ground, or flight related activities.
 */
export type Billing = {
  __typename?: 'Billing';
  /**
   * The relevant account for this billing. May be empty if a FUND
   * payment method was not used.
   */
  account?: Maybe<Account>;
  /** a composed billing amount */
  amount?: Maybe<Currency>;
  /** A unique key for a billing */
  billingId: Scalars['ID'];
  /** The date on which the billing was created, in ISO 8601 format */
  createDate?: Maybe<Scalars['ISO8601DateTime']>;
  detail?: Maybe<BillingDetail>;
  /** The relevant flight for this billing */
  flight?: Maybe<Flight>;
  /** An invoice is provided by a billing */
  invoice?: Maybe<Invoice>;
  method?: Maybe<PaymentMethod>;
  /**
   * The label for the payment method, e.g. "1234", such that the user can identify the payment
   * method used. For example if method is CARD, the ui may display "CARD1234"
   */
  methodLabel?: Maybe<Scalars['String']>;
  /** The reservation for this billing */
  reservation?: Maybe<Reservation>;
  status?: Maybe<BillingStatus>;
};

/** Represents a pageable connection of billing objects. */
export type BillingConnection = {
  __typename?: 'BillingConnection';
  /** data for the current page of results. */
  edges: Array<BillingEdge>;
  /** Info about the current page of results. */
  pageInfo: PageInfo;
  /** The total number of results available for this query. */
  totalCount: Scalars['Int'];
};

/**
 * In this case, Flight indicates that the billing is for a flight,
 * groundTransport indicates that the billing is for ground transport,
 * and flightCateringOrder indicates that the billing is for catering.
 */
export type BillingDetail =
  | FlightCateringOrderResults
  | GroundTransportResults
  | TripAccountingSummary;

/**
 * Represents a single billing object in a connection.
 * Contains a cursor for resumable pagination, and the billing object.
 */
export type BillingEdge = {
  __typename?: 'BillingEdge';
  cursor: Scalars['String'];
  node: Billing;
};

export enum BillingMetadataCategory {
  Fee = 'FEE',
  Tax = 'TAX',
}

/**
 * The status of a billing.
 *
 * Pending: The customer has sent payment but not confirmed
 * Paid: The customer has confirmed payment
 */
export enum BillingStatus {
  Paid = 'PAID',
  Pending = 'PENDING',
}

export type Clearance = {
  __typename?: 'Clearance';
  WGS84?: Maybe<Scalars['String']>;
  aircraftDocumentation?: Maybe<Scalars['String']>;
  airportsOfEntry?: Maybe<Scalars['String']>;
  applicationFormat?: Maybe<Scalars['String']>;
  clearanceContacts?: Maybe<Scalars['String']>;
  clearanceId: Scalars['ID'];
  clearanceInfo?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryISOCode2?: Maybe<Scalars['String']>;
  countryId?: Maybe<Scalars['Long']>;
  countryName: Scalars['String'];
  currency?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  /**
   * # To support legacy endpoints
   * @deprecated Use new Field 'clearanceId'
   */
  fmsClearanceId?: Maybe<Scalars['Long']>;
  lastEdited?: Maybe<Scalars['LocalDateTime']>;
  telephoneCountryCode?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  visaInfo?: Maybe<Scalars['String']>;
};

export type ClearanceRequest = {
  WGS84?: InputMaybe<Scalars['String']>;
  aircraftDocumentation?: InputMaybe<Scalars['String']>;
  airportsOfEntry?: InputMaybe<Scalars['String']>;
  applicationFormat?: InputMaybe<Scalars['String']>;
  clearanceContacts?: InputMaybe<Scalars['String']>;
  clearanceInfo?: InputMaybe<Scalars['String']>;
  comments?: InputMaybe<Scalars['String']>;
  countryName: Scalars['String'];
  currency?: InputMaybe<Scalars['String']>;
  currencyCode?: InputMaybe<Scalars['String']>;
  fmsClearanceId?: InputMaybe<Scalars['Long']>;
  lastEdited?: InputMaybe<Scalars['LocalDateTime']>;
  telephoneCountryCode?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  visaInfo?: InputMaybe<Scalars['String']>;
};

/**  A single search result that can be displayed to the user. */
export type ClientSingleSearchResult = SingleSearchResult & {
  __typename?: 'ClientSingleSearchResult';
  aircraftDetails: FsSingleSearchAircraftDetails;
  aircraftSource: SingleSearchAircraftSourcingType;
  alternateOptionMetadata?: Maybe<AlternateOptionMetadata>;
  categoryResult: Scalars['Boolean'];
  coverImageURL?: Maybe<Scalars['String']>;
  feasible: Scalars['Boolean'];
  flightTimeInHours: Scalars['Float'];
  flightTripType: FlightTripType;
  instantBook: Scalars['Boolean'];
  legs: Array<SearchLeg>;
  /**  A block of text to display to the user to encourage them to become a member */
  membersOnlyUpsellBlock: SingleSearchMemberUpsellBlock;
  price: SingleSearchPricingScheme;
  /**  The options in the below group are documented in the SingleSearchResult interface */
  resultId: Scalars['ID'];
  /**
   * Non-interface fields, to be documented on this object.
   * Member Site/Apps fields
   */
  showUpsellBlock: Scalars['Boolean'];
  sortOrder: Scalars['Int'];
  stops?: Maybe<Scalars['Int']>;
  tags?: Maybe<Array<SingleSearchOfferTag>>;
};

export enum ConditionCode {
  Blizzard = 'Blizzard',
  BlowingSnow = 'BlowingSnow',
  Breezy = 'Breezy',
  Clear = 'Clear',
  Cloudy = 'Cloudy',
  Drizzle = 'Drizzle',
  Dust = 'Dust',
  Flurries = 'Flurries',
  Fog = 'Fog',
  FreezingDrizzle = 'FreezingDrizzle',
  FreezingRain = 'FreezingRain',
  Frigid = 'Frigid',
  Hail = 'Hail',
  Haze = 'Haze',
  HeavyRain = 'HeavyRain',
  HeavySnow = 'HeavySnow',
  Hot = 'Hot',
  Hurricane = 'Hurricane',
  IsolatedThunderstorms = 'IsolatedThunderstorms',
  MixedRainAndSleet = 'MixedRainAndSleet',
  MixedRainAndSnow = 'MixedRainAndSnow',
  MixedRainfall = 'MixedRainfall',
  MixedSnowAndSleet = 'MixedSnowAndSleet',
  MostlyClear = 'MostlyClear',
  MostlyCloudy = 'MostlyCloudy',
  PartlyCloudy = 'PartlyCloudy',
  Rain = 'Rain',
  ScatteredShowers = 'ScatteredShowers',
  ScatteredSnowShowers = 'ScatteredSnowShowers',
  ScatteredThunderstorms = 'ScatteredThunderstorms',
  SevereThunderstorm = 'SevereThunderstorm',
  Showers = 'Showers',
  Sleet = 'Sleet',
  Smoke = 'Smoke',
  Snow = 'Snow',
  SnowShowers = 'SnowShowers',
  Thunderstorms = 'Thunderstorms',
  Tornado = 'Tornado',
  TropicalStorm = 'TropicalStorm',
  Windy = 'Windy',
}

/**
 * This is created due to a bug in the system when booking is created via Flight Desk or Atlas
 * Eventually will be deprecated once bug is fixed
 */
export type ContactReservationBooker = {
  __typename?: 'ContactReservationBooker';
  email?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phone?: Maybe<Array<Scalars['String']>>;
};

export type Covid19Status = {
  __typename?: 'Covid19Status';
  code: Scalars['String'];
  label: Scalars['String'];
};

export type Credit = {
  __typename?: 'Credit';
  /**  A unique key for a credit */
  creditId: Scalars['ID'];
  /**
   *  The type of the credit
   * @deprecated use 'program.code' instead
   */
  description: Scalars['String'];
  /**  Whether the credit is active */
  enabled: Scalars['Boolean'];
  /**  The effective end date of the credit, if any. */
  expirationDate?: Maybe<Scalars['ISO8601DateTime']>;
  name: Scalars['String'];
  /**  The credit number, as seen in Atlas. */
  number: Scalars['String'];
  program: CreditProgram;
  /**  The effective start date of the credit */
  startDate: Scalars['ISO8601DateTime'];
};

export type CreditProgram = {
  __typename?: 'CreditProgram';
  code: Scalars['String'];
  name: Scalars['String'];
};

/**
 * Currency does not have a @key(...), as it is not an entity.
 * Note: taken from WheelsUp Availability Service: https://github.com/wheelsup/availability-service/blob/main/availability-service.graphql#L550
 */
export type Currency = {
  __typename?: 'Currency';
  /** Amount in the given currency, e.g. 123.45. Will be serialized as a decimal value with 2 digits of precision. */
  amount: Scalars['Float'];
  /** Currency symbol, e.g. "$", "￥", "￡", "€", "￦" etc. For display purposes only. */
  symbol: Scalars['String'];
  /** Currency unit, e.g. "USD", "EUR", or "GBP"  */
  unit: Scalars['String'];
};

export type CurrencyInput = {
  amount: Scalars['Float'];
  symbol: Scalars['String'];
  unit: Scalars['String'];
};

export type CursorPageableInput = {
  /** Fetch elements after this cursor. */
  after?: InputMaybe<Scalars['String']>;
  /** Fetch elements before this cursor. */
  before?: InputMaybe<Scalars['String']>;
  /** First n elements. Must be greater than 0. */
  first?: InputMaybe<Scalars['Int']>;
  /** Last n elements. Must be greater than 0. */
  last?: InputMaybe<Scalars['Int']>;
};

export type DateGrid = {
  __typename?: 'DateGrid';
  date: Scalars['LocalDate'];
  /**  Indicates +/- days from original departure date. */
  direction: Scalars['Int'];
};

export type DatePrice = {
  __typename?: 'DatePrice';
  /** Date the price is valid for (in departure airport timezone) */
  date: Scalars['ISO8601Date'];
  /** Price for the day. Optional. If no pricing is available for the day, a custom quote is required. */
  price?: Maybe<Currency>;
};

export enum DayOfWeek {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
}

/**  A simple distance, with no particular start or end point. */
export type Distance = {
  __typename?: 'Distance';
  /**  The unit that the distance is in. */
  unit: DistanceUnit;
  /**  The distance value, in the given unit. This is returned as a raw number, and will need to be formatted properly. */
  value: Scalars['Float'];
};

export enum DistanceUnit {
  Kilometers = 'KILOMETERS',
  Miles = 'MILES',
}

export type Earning = {
  accruedExpenses: Currency;
  code: Scalars['String'];
  spendingTarget: Currency;
};

export type Fbo = {
  __typename?: 'FBO';
  active: Scalars['Boolean'];
  address?: Maybe<AdsAddress>;
  afterHoursTelephone?: Maybe<Scalars['String']>;
  aftn?: Maybe<Scalars['String']>;
  airport: Airport;
  /**
   * # To support legacy endpoints
   * @deprecated Use new Field 'fboId'
   */
  atlasFBOId?: Maybe<Scalars['Long']>;
  directoryGUID?: Maybe<Scalars['String']>;
  displayName: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  fboId: Scalars['ID'];
  /** @deprecated Use new Field 'fboId' */
  fmsFBOId?: Maybe<Scalars['Long']>;
  frequency?: Maybe<Scalars['String']>;
  fuelBrands?: Maybe<Array<Scalars['String']>>;
  fuelSupply?: Maybe<Scalars['String']>;
  highlightOnTripSheet: Scalars['Boolean'];
  hours?: Maybe<Scalars['String']>;
  locationOnField?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  preferred: Scalars['Boolean'];
  privateVendor: Scalars['Boolean'];
  services?: Maybe<Array<Scalars['String']>>;
  shortCode?: Maybe<Scalars['String']>;
  sita?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  tollFreeTelephone?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  viewable?: Maybe<Array<FboViewable>>;
  website?: Maybe<Scalars['String']>;
};

export type FboFilter = {
  includeDeactivated?: InputMaybe<Scalars['Boolean']>;
};

export type FboRequest = {
  active: Scalars['Boolean'];
  address: AdsAddressRequest;
  afterHoursTelephone?: InputMaybe<Scalars['String']>;
  aftn?: InputMaybe<Scalars['String']>;
  atlasFBOId?: InputMaybe<Scalars['Long']>;
  directoryGUID?: InputMaybe<Scalars['String']>;
  displayName?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  fax?: InputMaybe<Scalars['String']>;
  fmsFBOId?: InputMaybe<Scalars['Long']>;
  frequency?: InputMaybe<Scalars['String']>;
  fuelBrands?: InputMaybe<Array<Scalars['String']>>;
  fuelSupply?: InputMaybe<Scalars['String']>;
  highlightOnTripSheet: Scalars['Boolean'];
  hours?: InputMaybe<Scalars['String']>;
  locationOnField?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  preferred: Scalars['Boolean'];
  privateVendor: Scalars['Boolean'];
  services?: InputMaybe<Array<Scalars['String']>>;
  shortCode?: InputMaybe<Scalars['String']>;
  sita?: InputMaybe<Scalars['String']>;
  telephone?: InputMaybe<Scalars['String']>;
  tollFreeTelephone?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
};

export type FboViewable = {
  __typename?: 'FBOViewable';
  application: Scalars['String'];
  fboViewableId: Scalars['ID'];
  /** # WU-Members, Atlas, FMS */
  view: Scalars['Boolean'];
};

export type FmsFlight = {
  __typename?: 'FMSFlight';
  fmsFlightId: Scalars['ID'];
};

/**
 * BE note: No information on a FSSSADView should be saved in the database.
 * Flight Search Single Search Aircraft Details View. (what a mouthful)
 * Contains a headline and description to display more prominently for this aircraftDetails
 */
export type FsssadView = {
  __typename?: 'FSSSADView';
  /**  A description to display below the headline for this aircraftDetails */
  displayDescription: Scalars['String'];
  /**  A Headline to display more prominently for this aircraftDetails */
  displayHeadline: Scalars['String'];
};

/**
 * Contains information on the aircraft category relevant to a single search result,
 * and a view for displaying this information.
 */
export type FsSingleSearchAircraftDetails = {
  __typename?: 'FSSingleSearchAircraftDetails';
  aircraftCategory: Scalars['String'];
  aircraftType: Scalars['String'];
  view: FsssadView;
};

/**
 * A Flight is an entity that represents a booked flight.
 * Very limited information is available on flights, as they are not
 * owned by this service.
 */
export type Flight = {
  aircraftInfo: Array<FlightAircraftInformation>;
  /**
   * Order to display date time for Members Site and App should be as follows:
   * Actual > Delayed if status is Delayed > Flexed if status is Flexed > Estimated
   */
  arrivalDateTime?: Maybe<Array<FlightDateTime>>;
  billedTime: FlightDuration;
  /** Each of these are new fields that can be resolved by *this service* */
  billing?: Maybe<Array<Billing>>;
  cancellationDateTime?: Maybe<FlightDateTime>;
  /**
   * Order to display date time for Members Site and App should be as follows:
   * Actual > Delayed if status is Delayed > Flexed if status is Flexed > Estimated
   */
  departureDateTime?: Maybe<Array<FlightDateTime>>;
  destination: FlightAirportFbo;
  flightDate: Scalars['LocalDate'];
  /**
   * The @key field is given to us, and does not need resolving.
   * A unique key for a flight
   */
  flightId: Scalars['ID'];
  flightTime: FlightDuration;
  flightType: Scalars['String'];
  fmsFlight?: Maybe<FmsFlight>;
  /** @deprecated Use origin field instead */
  fromAirport?: Maybe<Airport>;
  isOnPeakDay?: Maybe<Scalars['Boolean']>;
  leg: Scalars['Int'];
  origin: FlightAirportFbo;
  status: Array<FlightStatus>;
  /** @deprecated Soon will be deprecated and provided by fmsFlight attribute */
  techStops?: Maybe<Array<TechnicalStop>>;
  /** @deprecated Use destination field instead */
  toAirport?: Maybe<Airport>;
};

export type FlightAircraftInformation = {
  __typename?: 'FlightAircraftInformation';
  aircraft: AircraftInformation;
  type: FlightAircraftInformationType;
};

export enum FlightAircraftInformationType {
  Assigned = 'ASSIGNED',
  Requested = 'REQUESTED',
}

export type FlightAirportFbo = {
  __typename?: 'FlightAirportFBO';
  airport: Airport;
  fbo?: Maybe<Fbo>;
  weather?: Maybe<AirportWeather>;
};

/**
 * Fetch pricing information for the current user on the given day(s).
 * clientId: Unique Id of the user making the request (e.g member_19411_bluesky_5YNRRSWB -> member_{memberNumber}_{platform}_uniqueNumber)
 * startDate: The first day to fetch, in ISO-8601 format (e.g. '2023-04-01'). Not be in the past. Required.
 * endDate: The last day to fetch, inclusive. In ISO-8601 format.
 * departureAirport: the Atlas Airport ID of the departure airport. Required.
 * arrivalAirport: the Atlas Airport ID of the arrival airport. Required.
 * roundTrip: if true, price a round trip. Required.
 * roundTripDays: When first showing the calendar, price a round trip from the given day with a return flight
 * on `roundTripDays` later.
 * anchoredDate: After the user selects an initial departure date, the price displayed assumes departure on
 * this date (in ISO-8601 format). For example, if anchoredDate is june 14th, pricing for prior days assume
 * departure on that day and a return on anchoredDate. Pricing for days after anchoredDate assume departure on
 * anchoredDate and return on the given date.  Pricing for anchoredDate itself assumes a same-day return.
 * otherLegs: for multi-leg trips, the other legs the user has already selected. These legs should be priced and
 * included in the final price for each day, including any round-trip or multi-leg discounts as applicable.
 */
export type FlightCalendarRequest = {
  anchoredDate?: InputMaybe<Scalars['ISO8601Date']>;
  arrivalAirport: Scalars['Int'];
  clientId: Scalars['String'];
  departureAirport: Scalars['Int'];
  endDate: Scalars['ISO8601Date'];
  multiLegs?: InputMaybe<Array<InputMaybe<LegInput>>>;
  roundTripDays?: InputMaybe<Scalars['Int']>;
  startDate: Scalars['ISO8601Date'];
};

export type FlightCateringOrder = {
  __typename?: 'FlightCateringOrder';
  flightCateringOrderId: Scalars['ID'];
};

/**
 * A list of flight catering objects.
 * These can re-occur on billings if the same caterings have a split payment.
 */
export type FlightCateringOrderResults = {
  __typename?: 'FlightCateringOrderResults';
  results: Array<FlightCateringOrder>;
};

export type FlightConnection = {
  __typename?: 'FlightConnection';
  edges?: Maybe<Array<FlightEdge>>;
  pageInfo: PageInfo;
};

export type FlightDateTime = {
  __typename?: 'FlightDateTime';
  dateTime: Scalars['LocalDateTime'];
  type: FlightDatetimeType;
};

export enum FlightDatetimeType {
  Actual = 'ACTUAL',
  Cancelled = 'CANCELLED',
  Delayed = 'DELAYED',
  Estimated = 'ESTIMATED',
  Flexed = 'FLEXED',
}

export type FlightDuration = {
  __typename?: 'FlightDuration';
  timeFormatted: Scalars['String'];
  timeInHours: Scalars['Float'];
  timeInMinutes: Scalars['Long'];
};

export type FlightEdge = {
  __typename?: 'FlightEdge';
  cursor: Scalars['String'];
  node: Flight;
};

export type FlightLuggage = {
  __typename?: 'FlightLuggage';
  count?: Maybe<Scalars['Int']>;
  flightLuggageId: Scalars['ID'];
  luggageType: LuggageType;
  note?: Maybe<Scalars['String']>;
};

export type FlightLuggageInput = {
  count?: InputMaybe<Scalars['Int']>;
  note?: InputMaybe<Scalars['String']>;
  type: FlightLuggageTypeInput;
};

export enum FlightLuggageTypeInput {
  Bags = 'BAGS',
  GolfClub = 'GOLF_CLUB',
  Misc = 'MISC',
  Oversize = 'OVERSIZE',
  Ski = 'SKI',
}

export type FlightPassenger = {
  __typename?: 'FlightPassenger';
  covid19Status?: Maybe<Covid19Status>;
  flightPassengerId: Scalars['ID'];
  isCountedForAircraftSeat: Scalars['Boolean'];
  isLead: Scalars['Boolean'];
  /** Applicable of international flight only */
  missingTravelDocument?: Maybe<Scalars['Boolean']>;
  passengerMemberInfo: MemberRelatedPassenger;
};

export type FlightSearchMessage = {
  __typename?: 'FlightSearchMessage';
  /**  If the message should have a banner image, this is the URL for that image. */
  bannerImageUrl?: Maybe<Scalars['String']>;
  highlightedText?: Maybe<Scalars['String']>;
  /**  The user-facing message to display. */
  message?: Maybe<Scalars['String']>;
  /**
   * The category of message that this is.
   * If null, the category should be treated as 'OTHER'
   */
  messageType?: Maybe<FlightSearchMessageType>;
  /**
   * Which order the message should be displayed in, lowest being first.
   * Null values should be treated as the lowest value.
   */
  sortOrder?: Maybe<Scalars['Int']>;
  /**
   * If the message should have a tooltip, this is the tooltip to display.
   * Null values should be treated as no tooltip.
   */
  tooltip?: Maybe<ToolTip>;
};

/**  For FlightSearchMessages, an enum that represents the type of message that is being displayed. */
export enum FlightSearchMessageType {
  AirportClosed = 'AIRPORT_CLOSED',
  AmexBenefit = 'AMEX_BENEFIT',
  ConditionalTrip = 'CONDITIONAL_TRIP',
  CrewDuty = 'CREW_DUTY',
  DeniedTrip = 'DENIED_TRIP',
  EmptyResult = 'EMPTY_RESULT',
  InternationalTrip = 'INTERNATIONAL_TRIP',
  MultipleAircraft = 'MULTIPLE_AIRCRAFT',
  OutsideServiceArea = 'OUTSIDE_SERVICE_AREA',
  PeakDay = 'PEAK_DAY',
}

export type FlightStatus = {
  __typename?: 'FlightStatus';
  application: Scalars['String'];
  status: Scalars['String'];
};

export type FlightTravellerGroup = {
  availableSeats?: Maybe<Scalars['Int']>;
  luggage?: Maybe<Array<FlightLuggage>>;
  passengers: Array<FlightPassenger>;
  unknownPassengers?: Maybe<Scalars['Int']>;
};

/**  A Classification of the type of trip that this result represents based on how many legs it has. */
export enum FlightTripType {
  MultiCity = 'MULTI_CITY',
  OneWay = 'ONE_WAY',
  RoundTrip = 'ROUND_TRIP',
}

export type GeoLocationRequest = {
  /** Accepted Values: [All, Atlas, FMS, WU-Members] */
  application?: InputMaybe<Scalars['String']>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  minRunwayLength?: InputMaybe<Scalars['Long']>;
  radius?: InputMaybe<Scalars['Float']>;
  radiusUnit?: InputMaybe<DistanceUnit>;
};

export type GroundTransport = {
  __typename?: 'GroundTransport';
  category?: Maybe<Scalars['String']>;
  /** Where the user was dropped off */
  dropOffLocation?: Maybe<WuMemberAddress>;
  groundTransportId: Scalars['ID'];
  /** contains info on the type of ground transport */
  groundTransportType?: Maybe<GroundTransportType>;
  /** Where the user was picked up */
  pickUpLocation?: Maybe<WuMemberAddress>;
};

/**
 * A list of ground transport objects.
 * These can re-occur on billings if the same ground transports have a split payment.
 */
export type GroundTransportResults = {
  __typename?: 'GroundTransportResults';
  results: Array<GroundTransport>;
};

export type GroundTransportType = {
  __typename?: 'GroundTransportType';
  groundTransportTypeId: Scalars['ID'];
};

export type HumanPassengerDetail = {
  __typename?: 'HumanPassengerDetail';
  emailContacts?: Maybe<Array<PassengerEmailContact>>;
  firstName: Scalars['String'];
  /** Calculated field based on date of birth */
  humanPassengerType: HumanPassengerType;
  lastName: Scalars['String'];
  legalName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<PassengerNamePrefix>;
  nameSuffix?: Maybe<PassengerNameSuffix>;
  phoneContacts?: Maybe<Array<PassengerPhoneContact>>;
};

export enum HumanPassengerType {
  /** Above 18 years old */
  Adult = 'ADULT',
  /** Above 2 years old and below 13 years old */
  ChildUnder_13 = 'CHILD_UNDER_13',
  /** Above 13 years old and below 18 years old */
  ChildUnder_18 = 'CHILD_UNDER_18',
  /** Below or equal to 2 years old */
  Infant = 'INFANT',
}

export type IndividualOfferRequestBooker = {
  __typename?: 'IndividualOfferRequestBooker';
  email: Scalars['String'];
  passenger: Passenger;
  phone?: Maybe<Array<Scalars['String']>>;
};

export type IndividualReservationBooker = {
  __typename?: 'IndividualReservationBooker';
  email?: Maybe<Scalars['String']>;
  passenger: Passenger;
  phone?: Maybe<Array<Scalars['String']>>;
};

/**  A pricing scheme for a single search result that can be instantly booked. */
export type InstantBookSingleSearchPricing = {
  __typename?: 'InstantBookSingleSearchPricing';
  /**  An optional crossOutPrice which will be provided If a crossOutPrice may be displayed. */
  crossOutPrice?: Maybe<Currency>;
  /**  The price as it should be displayed to the user */
  displayPrice: Currency;
};

/** A Netsuite Invoice. */
export type Invoice = {
  __typename?: 'Invoice';
  invoiceId: Scalars['ID'];
};

/** Represents a leg the user has already selected and should be included in pricing. */
export type LegInput = {
  arrivalAirport: Scalars['Int'];
  departureAirport: Scalars['Int'];
  departureDate: Scalars['ISO8601Date'];
};

export type LuggageType = {
  __typename?: 'LuggageType';
  code: Scalars['String'];
  luggageTypeId: Scalars['ID'];
  name: Scalars['String'];
};

/** Object that represents highlight information for a news page */
export type MarketingNews = {
  __typename?: 'MarketingNews';
  /** Main body copy */
  body: Scalars['String'];
  /** The text for the call to action */
  cta: Scalars['String'];
  /** Heading text */
  heading: Scalars['String'];
  /** Thumbnail Image */
  imageUrl: Scalars['String'];
  newsPage: NewsPage;
  /** Subheading text */
  subheading: Scalars['String'];
};

export enum MarketingNewsCategory {
  /** Featured News */
  Featured = 'FEATURED',
  /** How To News */
  HowTo = 'HOW_TO',
  /** Member Benefits News */
  MemberBenefits = 'MEMBER_BENEFITS',
  /** Our Pilot News */
  OurPilot = 'OUR_PILOT',
}

export type Member = {
  __typename?: 'Member';
  companyName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  isKeyAccount?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  legacyProgramRules?: Maybe<Scalars['Boolean']>;
  legalName?: Maybe<Scalars['String']>;
  memberClassification?: Maybe<MemberClassification>;
  memberId: Scalars['ID'];
  memberNumber: Scalars['Long'];
  memberSince: Scalars['LocalDateTime'];
  memberSubType?: Maybe<MemberSubType>;
  memberType: MemberType;
  memberUserType?: Maybe<MemberUserType>;
  middleName?: Maybe<Scalars['String']>;
  national?: Maybe<Scalars['Boolean']>;
  notes?: Maybe<Array<MemberNote>>;
  notifySalesFirst?: Maybe<Scalars['Boolean']>;
  passengers: Array<MemberRelatedPassenger>;
  preferredPassenger?: Maybe<MemberRelatedPassenger>;
  salesPersons?: Maybe<Array<MemberSalesPerson>>;
  statementName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type MemberPassengersArgs = {
  filter?: InputMaybe<MemberRelatedPassengerFilter>;
};

export type MemberAdvantage =
  | MemberLoyalty
  | MemberNoAdvantage
  | MemberSaving;

export enum MemberClassification {
  Blue = 'BLUE',
  National = 'NATIONAL',
  Red = 'RED',
}

export type MemberEarningCredit = Earning & {
  __typename?: 'MemberEarningCredit';
  accruedExpenses: Currency;
  code: Scalars['String'];
  spendingTarget: Currency;
};

export type MemberEarningMedallion = Earning & {
  __typename?: 'MemberEarningMedallion';
  accruedExpenses: Currency;
  code: Scalars['String'];
  earnedMedallion?: Maybe<MemberEarningMedallionEntity>;
  spendingTarget: Currency;
};

export type MemberEarningMedallionEntity = {
  __typename?: 'MemberEarningMedallionEntity';
  name: Scalars['String'];
  pendingApplication: Scalars['Boolean'];
};

export type MemberLoyalty = {
  __typename?: 'MemberLoyalty';
  earnings?: Maybe<Array<Earning>>;
};

export type MemberNoAdvantage = {
  __typename?: 'MemberNoAdvantage';
  _?: Maybe<Scalars['String']>;
};

export type MemberNote = {
  __typename?: 'MemberNote';
  note: Scalars['String'];
  type: MemberNoteType;
};

export enum MemberNoteType {
  Account = 'ACCOUNT',
  AlwaysOnBoard = 'ALWAYS_ON_BOARD',
  NotifySales = 'NOTIFY_SALES',
  Special = 'SPECIAL',
}

export type MemberRelatedPassenger = {
  __typename?: 'MemberRelatedPassenger';
  authorizedLead: Scalars['Boolean'];
  authorizedToBook: Scalars['Boolean'];
  authorizedToFly: Scalars['Boolean'];
  authorizedToGetInfo: Scalars['Boolean'];
  authorizedUser: Scalars['Boolean'];
  deltaRecipient: Scalars['Boolean'];
  disabled: Scalars['Boolean'];
  favorite: Scalars['Boolean'];
  member: Member;
  memberRelatedPassengerId: Scalars['ID'];
  passenger: Passenger;
  principal: Scalars['Boolean'];
  relationToMember?: Maybe<Scalars['String']>;
  skyMilesNumber?: Maybe<Scalars['String']>;
};

/**
 * Allows to filter passengers by passengerIds or onlyFavorites or onlyAuthorizedToFly
 * Filter is accessible to all applications
 */
export type MemberRelatedPassengerBasicFilter = {
  onlyAuthorizedToFly?: InputMaybe<Scalars['Boolean']>;
  onlyFavorites?: InputMaybe<Scalars['Boolean']>;
  passengerIds?: InputMaybe<Array<Scalars['ID']>>;
};

/**
 * Allows to filter passengers
 * PassengerFilter takes precedence over MatcherFilter
 * Note: Only to be used when a search for a specific member's passengers
 */
export type MemberRelatedPassengerFilter = {
  basicFilter?: InputMaybe<MemberRelatedPassengerBasicFilter>;
  matcherFilter?: InputMaybe<MemberRelatedPassengerMatcherFilter>;
};

/** Allows to filter passengers by matcher along with other attributes */
export type MemberRelatedPassengerMatcherFilter = {
  /** only applicable for Atlas/BlueSky applications */
  includeDisabled?: InputMaybe<Scalars['Boolean']>;
  matcher: Scalars['String'];
  onlyFavorites?: InputMaybe<Scalars['Boolean']>;
};

export type MemberSalesPerson = {
  __typename?: 'MemberSalesPerson';
  memberSalesPersonId: Scalars['ID'];
  primary: Scalars['Boolean'];
  salesPerson: SalesPerson;
  salesPersonType: MemberSalesPersonType;
};

export type MemberSalesPersonType = {
  __typename?: 'MemberSalesPersonType';
  code: Scalars['String'];
  memberSalesPersonTypeId: Scalars['ID'];
  name: Scalars['String'];
};

export type MemberSaving = {
  __typename?: 'MemberSaving';
  flightsWithSurgeProtection: Percentage;
  flightsWithoutSurgeProtection: Percentage;
  totalSavings: Currency;
  year: Scalars['Int'];
};

export type MemberSearchRequest = {
  matcher: Scalars['String'];
  memberSubTypeCodes?: InputMaybe<Array<Scalars['String']>>;
  memberTypeCodes?: InputMaybe<Array<Scalars['String']>>;
  memberUserTypeCodes?: InputMaybe<
    Array<Scalars['String']>
  >;
};

/**  A statement item is a single charge or credit for a given month. */
export type MemberStatementDocument = {
  __typename?: 'MemberStatementDocument';
  /**  The account for the line-item. */
  account?: Maybe<Account>;
  /**  The balance for the line-item */
  balance: Currency;
  containerName: Scalars['String'];
  /**  Either PDF, or CSV */
  contentType: Scalars['String'];
  /**  The credit for the line-item. Will be null if the line-item is a charge. */
  credit?: Maybe<Credit>;
  /**  The user-friendly display name for this statement item. */
  displayName: Scalars['String'];
  /**  The type of this statement item, e.g. "Funds", "Credits", "Other". */
  documentType: DocumentType;
  /**
   * The PDF download URL for this statement item.
   * Will come in the form of `/api/v1/download/pdf/statements/{statementId}`
   */
  downloadUrl: Scalars['String'];
  /**  The name of the PDF file for this statement item. */
  fileName: Scalars['String'];
  /**  The non-federated ID for this statement item. Will be used for federation in the future. */
  memberStatementDocumentId: Scalars['ID'];
};

export type MemberSubType = {
  __typename?: 'MemberSubType';
  code: Scalars['String'];
  disabled: Scalars['Boolean'];
  displayName: Scalars['String'];
  memberSubTypeId: Scalars['ID'];
  name: Scalars['String'];
};

export type MemberType = {
  __typename?: 'MemberType';
  code: Scalars['String'];
  disabled: Scalars['Boolean'];
  displayName: Scalars['String'];
  memberTypeId: Scalars['ID'];
  name: Scalars['String'];
};

export enum MemberTypeCode {
  /** Corporate/Business */
  Corporate = 'CORPORATE',
  /** Fly User/Member */
  Fly = 'FLY',
  /** Core */
  Individual = 'INDIVIDUAL',
  /** Connect */
  Individual_2 = 'INDIVIDUAL_2',
  /** Partner */
  Partner = 'PARTNER',
  /** Pilot */
  Pilot = 'PILOT',
  /** Prospect */
  Prospect = 'PROSPECT',
}

export type MemberUserType = {
  __typename?: 'MemberUserType';
  code: Scalars['String'];
  memberUserTypeId: Scalars['ID'];
  name: Scalars['String'];
};

export enum MissingPassengerInfoType {
  DateOfBirth = 'DATE_OF_BIRTH',
  Gender = 'GENDER',
  UnconfirmedPassport = 'UNCONFIRMED_PASSPORT',
  Weight = 'WEIGHT',
}

export type Mutation = {
  __typename?: 'Mutation';
  /** Allows to add an airport aircraft type */
  addAirportAircraftType: AirportAircraftType;
  /** Allows to add an airport category */
  addAirportCategory: AirportCategory;
  /** Allows to add an airport closure reason */
  addAirportClosureReason: AirportClosureReason;
  /** Allows to add an airport frequency */
  addAirportFrequency: AirportFrequency;
  /** Allows to add an airport information */
  addAirportInformation: AirportInformation;
  /** Allows to add an airport note */
  addAirportNote: AirportNote;
  /** Allows to add an airport restriction */
  addAirportRestriction: AirportRestriction;
  /** Allows to add an airport runway */
  addAirportRunway: AirportRunway;
  /** Allows to add an airport schedule */
  addAirportSchedule: AirportSchedule;
  /** Allows to add an airport supplier */
  addAirportSupplier: AirportSupplier;
  /** Allows to add an airport viewable for an application */
  addAirportVisibility: AirportViewable;
  /** Allows to create new clearance */
  addClearance: Clearance;
  /** Allows to add an FBO for given airport Id */
  addFBO: Fbo;
  /** Allows to add an FBO viewable for an application */
  addFBOVisibility: FboViewable;
  applyEarnedMedallion?: Maybe<MemberEarningMedallion>;
  /** Allows to update an airport note status */
  changeAirportNoteStatus: AirportNote;
  /** Allows to update an airport status */
  changeAirportStatus: Airport;
  /** Allows to update an airport supplier status */
  changeAirportSupplierStatus: AirportSupplier;
  /** Allows to update an airport viewable for an application */
  changeAirportVisibility: AirportViewable;
  /** Allows to update an FBO status */
  changeFBOStatus: Fbo;
  /** Allows to update an FBO viewable for an application */
  changeFBOVisibility: FboViewable;
  /** Allows to create an airport */
  createAirport: Airport;
  /** Allows to delete an airport closure reason */
  deleteAirportClosureReason: Scalars['Boolean'];
  /** Allows to delete an airport frequency */
  deleteAirportFrequency: Scalars['Boolean'];
  /** Allows to delete an airport information */
  deleteAirportInformation: Scalars['Boolean'];
  /** Allows to delete an airport note */
  deleteAirportNote: Scalars['Boolean'];
  /** Allows to delete an airport restriction */
  deleteAirportRestriction: Scalars['Boolean'];
  /** Allows to delete an airport runway */
  deleteAirportRunway: Scalars['Boolean'];
  /** Allows to delete an airport schedule */
  deleteAirportSchedule: Scalars['Boolean'];
  /** Allows to delete an airport supplier */
  deleteAirportSupplier: Scalars['Boolean'];
  /** Allows to deletes clearance for given Id */
  deleteClearance: Scalars['Boolean'];
  /**
   * Add a new credit card to the user's account
   * POST /payment/card
   */
  pwAddCreditCard: PwExistingCreditCardDetails;
  /**
   * Add a new notification alert to the user's account
   * POST /ms/rest/user/sharedFlightAlertLocations/member/{memberId}?userIds={userId1}&userIds={userId2}
   */
  pwAddNotificationAlert: PwNotificationAlert;
  /**
   * Delete an existing credit card on the user's account by using its id
   * DELETE /payment/card/{cardId}
   */
  pwDeleteCreditCardById: Scalars['Boolean'];
  /**
   * Delete an existing credit card on the user's account by using its id
   * POST /ms/rest/user/sharedFlightAlertLocations/member/{memberId}/alert/{alertId}/delete/
   */
  pwDeleteNotificationAlertById: Scalars['Boolean'];
  /**
   * Edit an existing credit card on the user's account
   * PUT /payment/card
   */
  pwEditCreditCard: PwExistingCreditCardDetails;
  /**
   * Edit an existing Email notification setting on the user's account
   * PUT /notification/setting/toggleEmailControl/{notificationSettingId}}?enabled={boolean}
   */
  pwEditEmailNotificationSetting: PwNotificationSettingEmailControl;
  /**
   * Edit an existing notification alert on the user's account by using its id
   * PUT /ms/rest/user/sharedFlightAlertLocations/member/{memberId}/alert/{alertId}
   */
  pwEditNotificationAlert: PwNotificationAlert;
  /**
   * Edit an existing Push notification setting on the user's account
   * PUT /notification/setting/togglePushControl/{notificationSettingId}}?enabled={boolean}
   */
  pwEditPushNotificationSetting: PwNotificationSettingPushControl;
  pwUpdateUserTermsAndConditions: Scalars['Boolean'];
  /** Allows to refresh an airport views by ID */
  refreshAirportCache: Scalars['Boolean'];
  /** Allows to refresh airport views cache */
  refreshAllAirportCache: Scalars['Boolean'];
  /** Allows to refresh clearance cache */
  refreshClearanceCache: Scalars['Boolean'];
  /** Allows to update an airport */
  updateAirport: Airport;
  /** Allows to update an airport aircraft type */
  updateAirportAircraftType: AirportAircraftType;
  /** Allows to update an airport category */
  updateAirportCategory: AirportCategory;
  /** Allows to update an airport closure reason */
  updateAirportClosureReason: AirportClosureReason;
  /** Allows to update an airport frequency */
  updateAirportFrequency: AirportFrequency;
  /** Allows to update an airport information */
  updateAirportInformation: AirportInformation;
  /** Allows to update an airport note */
  updateAirportNote: AirportNote;
  /** Allows to update an airport restriction */
  updateAirportRestriction: AirportRestriction;
  /** Allows to update an airport runway */
  updateAirportRunway: AirportRunway;
  /** Allows to update an airport schedule */
  updateAirportSchedule: AirportSchedule;
  /** Allows to update an airport supplier */
  updateAirportSupplier: AirportSupplier;
  /** Allows to update existing clearance */
  updateClearance: Clearance;
  /** Allows to update an FBO */
  updateFBO: Fbo;
};

export type MutationAddAirportAircraftTypeArgs = {
  code: Scalars['String'];
  name: Scalars['String'];
};

export type MutationAddAirportCategoryArgs = {
  code: Scalars['String'];
  name: Scalars['String'];
};

export type MutationAddAirportClosureReasonArgs = {
  request: AirportClosureReasonRequest;
};

export type MutationAddAirportFrequencyArgs = {
  airportId: Scalars['Long'];
  request: AirportFrequencyRequest;
};

export type MutationAddAirportInformationArgs = {
  airportId: Scalars['Long'];
  request: AirportInformationRequest;
};

export type MutationAddAirportNoteArgs = {
  airportId: Scalars['Long'];
  request: AirportNoteRequest;
};

export type MutationAddAirportRestrictionArgs = {
  airportId: Scalars['Long'];
  request: AirportRestrictionRequest;
};

export type MutationAddAirportRunwayArgs = {
  airportId: Scalars['Long'];
  request: AirportRunwayRequest;
};

export type MutationAddAirportScheduleArgs = {
  airportId: Scalars['Long'];
  request: AirportScheduleRequest;
};

export type MutationAddAirportSupplierArgs = {
  airportId: Scalars['Long'];
  request: AirportSupplierRequest;
};

export type MutationAddAirportVisibilityArgs = {
  airportId: Scalars['Long'];
  application: Scalars['String'];
  view: Scalars['Boolean'];
};

export type MutationAddClearanceArgs = {
  request: ClearanceRequest;
};

export type MutationAddFboArgs = {
  airportId: Scalars['Long'];
  request: FboRequest;
};

export type MutationAddFboVisibilityArgs = {
  application: Scalars['String'];
  fboId: Scalars['Long'];
  view: Scalars['Boolean'];
};

export type MutationApplyEarnedMedallionArgs = {
  memberId?: InputMaybe<Scalars['ID']>;
};

export type MutationChangeAirportNoteStatusArgs = {
  active: Scalars['Boolean'];
  airportNoteId: Scalars['Long'];
};

export type MutationChangeAirportStatusArgs = {
  active: Scalars['Boolean'];
  airportId: Scalars['Long'];
};

export type MutationChangeAirportSupplierStatusArgs = {
  active: Scalars['Boolean'];
  airportSupplierId: Scalars['Long'];
};

export type MutationChangeAirportVisibilityArgs = {
  airportViewableId: Scalars['Long'];
  view: Scalars['Boolean'];
};

export type MutationChangeFboStatusArgs = {
  active: Scalars['Boolean'];
  fboId: Scalars['Long'];
};

export type MutationChangeFboVisibilityArgs = {
  fboViewableId: Scalars['Long'];
  view: Scalars['Boolean'];
};

export type MutationCreateAirportArgs = {
  request: AirportRequest;
};

export type MutationDeleteAirportClosureReasonArgs = {
  airportClosureReasonId: Scalars['Long'];
};

export type MutationDeleteAirportFrequencyArgs = {
  airportFrequencyId: Scalars['Long'];
};

export type MutationDeleteAirportInformationArgs = {
  airportInformationId: Scalars['Long'];
};

export type MutationDeleteAirportNoteArgs = {
  airportNoteId: Scalars['Long'];
};

export type MutationDeleteAirportRestrictionArgs = {
  airportRestrictionId: Scalars['Long'];
};

export type MutationDeleteAirportRunwayArgs = {
  airportRunwayId: Scalars['Long'];
};

export type MutationDeleteAirportScheduleArgs = {
  airportScheduleId: Scalars['Long'];
};

export type MutationDeleteAirportSupplierArgs = {
  airportSupplierId: Scalars['Long'];
};

export type MutationDeleteClearanceArgs = {
  clearanceId: Scalars['Long'];
};

export type MutationPwAddCreditCardArgs = {
  creditCard: PwNewCreditCardDetailsInput;
};

export type MutationPwAddNotificationAlertArgs = {
  memberId: Scalars['ID'];
  notificationAlert: PwNewNotificationAlertInput;
};

export type MutationPwDeleteCreditCardByIdArgs = {
  creditCardId: Scalars['ID'];
};

export type MutationPwDeleteNotificationAlertByIdArgs = {
  memberId: Scalars['ID'];
  notificationId: Scalars['ID'];
};

export type MutationPwEditCreditCardArgs = {
  creditCard: PwExistingCreditCardDetailsInput;
};

export type MutationPwEditEmailNotificationSettingArgs = {
  isEnabled: Scalars['Boolean'];
  notificationSettingId: Scalars['ID'];
};

export type MutationPwEditNotificationAlertArgs = {
  memberId: Scalars['ID'];
  notificationAlert: PwExistingNotificationAlertInput;
};

export type MutationPwEditPushNotificationSettingArgs = {
  isEnabled: Scalars['Boolean'];
  notificationSettingId: Scalars['ID'];
};

export type MutationPwUpdateUserTermsAndConditionsArgs = {
  termsInput: PwUserTermsAndConditionsInput;
};

export type MutationRefreshAirportCacheArgs = {
  airportId: Scalars['Long'];
};

export type MutationUpdateAirportArgs = {
  airportId: Scalars['Long'];
  request: AirportRequest;
};

export type MutationUpdateAirportAircraftTypeArgs = {
  airportAircraftTypeId: Scalars['Long'];
  code: Scalars['String'];
  name: Scalars['String'];
};

export type MutationUpdateAirportCategoryArgs = {
  airportCategoryId: Scalars['Long'];
  code: Scalars['String'];
  name: Scalars['String'];
};

export type MutationUpdateAirportClosureReasonArgs = {
  airportClosureReasonId: Scalars['Long'];
  request: AirportClosureReasonRequest;
};

export type MutationUpdateAirportFrequencyArgs = {
  airportFrequencyId: Scalars['Long'];
  request: AirportFrequencyRequest;
};

export type MutationUpdateAirportInformationArgs = {
  airportInformationId: Scalars['Long'];
  request: AirportInformationRequest;
};

export type MutationUpdateAirportNoteArgs = {
  airportNoteId: Scalars['Long'];
  request: AirportNoteRequest;
};

export type MutationUpdateAirportRestrictionArgs = {
  airportRestrictionId: Scalars['Long'];
  request: AirportRestrictionRequest;
};

export type MutationUpdateAirportRunwayArgs = {
  airportRunwayId: Scalars['Long'];
  request: AirportRunwayRequest;
};

export type MutationUpdateAirportScheduleArgs = {
  airportScheduleId: Scalars['Long'];
  request?: InputMaybe<AirportScheduleRequest>;
};

export type MutationUpdateAirportSupplierArgs = {
  airportSupplierId: Scalars['Long'];
  request: AirportSupplierRequest;
};

export type MutationUpdateClearanceArgs = {
  clearanceId: Scalars['Long'];
  request: ClearanceRequest;
};

export type MutationUpdateFboArgs = {
  fboId: Scalars['Long'];
  request: FboRequest;
};

/** Object that represents a news page */
export type NewsPage = {
  __typename?: 'NewsPage';
  /** The id of the news page in which the MarketingNews is related to */
  newsPageId: Scalars['ID'];
};

export type OfferRequest = {
  __typename?: 'OfferRequest';
  aircraftInfo: AircraftInformation;
  arrivalDateTime: Array<FlightDateTime>;
  availabilityId: Scalars['String'];
  departureDateTime: Array<FlightDateTime>;
  destination: OfferRequestAirportFbo;
  flight?: Maybe<Flight>;
  flightTime: FlightDuration;
  fuelStops?: Maybe<Scalars['Int']>;
  offerRequestId: Scalars['ID'];
  origin: OfferRequestAirportFbo;
  origination?: Maybe<Scalars['String']>;
  possibleStops?: Maybe<Scalars['Boolean']>;
  price: Currency;
  requestedDateTime: Scalars['DateTime'];
  requestor: OfferRequestBooker;
  status: OfferRequestStatus;
  travellerGroup: OfferRequestTravellerGroup;
  tripType: TripType;
};

export type OfferRequestAirportFbo = {
  __typename?: 'OfferRequestAirportFBO';
  airport: Airport;
  fbo?: Maybe<Fbo>;
};

export type OfferRequestBooker = IndividualOfferRequestBooker;

export type OfferRequestConnection = {
  __typename?: 'OfferRequestConnection';
  edges?: Maybe<Array<OfferRequestEdge>>;
  pageInfo: PageInfo;
};

export type OfferRequestEdge = {
  __typename?: 'OfferRequestEdge';
  cursor: Scalars['String'];
  node: OfferRequest;
};

export type OfferRequestLuggage = {
  __typename?: 'OfferRequestLuggage';
  count?: Maybe<Scalars['Int']>;
  luggageType: LuggageType;
  offerRequestLuggageId: Scalars['ID'];
};

export type OfferRequestPassenger = {
  __typename?: 'OfferRequestPassenger';
  isCountedForAircraftSeat: Scalars['Boolean'];
  isLead: Scalars['Boolean'];
  offerRequestPassengerId: Scalars['ID'];
  passengerMemberInfo: MemberRelatedPassenger;
};

export type OfferRequestPassengerInput = {
  email?: InputMaybe<Scalars['String']>;
  isLead?: InputMaybe<Scalars['Boolean']>;
  passengerId: Scalars['ID'];
  phone?: InputMaybe<Scalars['String']>;
};

export type OfferRequestSchedulerInput = {
  email: Scalars['String'];
  phone: Scalars['String'];
};

export type OfferRequestStatus = {
  __typename?: 'OfferRequestStatus';
  application: Scalars['String'];
  status: Scalars['String'];
};

export type OfferRequestTravellerGroup = {
  __typename?: 'OfferRequestTravellerGroup';
  availableSeats?: Maybe<Scalars['Int']>;
  luggage?: Maybe<Array<OfferRequestLuggage>>;
  passengers: Array<OfferRequestPassenger>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  /** The cursor to the last element in the set. */
  endCursor?: Maybe<Scalars['String']>;
  /** Whether there are more elements in the set. */
  hasNextPage: Scalars['Boolean'];
  /** Whether there are previous elements in the set. */
  hasPreviousPage: Scalars['Boolean'];
  /** The cursor to the first element in the set. */
  startCursor?: Maybe<Scalars['String']>;
};

export type Passenger = {
  __typename?: 'Passenger';
  dateOfBirth?: Maybe<Scalars['LocalDate']>;
  futureTravelDates?: Maybe<Array<Scalars['LocalDate']>>;
  gender?: Maybe<PassengerGenderType>;
  missingInfo?: Maybe<Array<MissingPassengerInfoType>>;
  note?: Maybe<Scalars['String']>;
  passengerDetail: PassengerDetail;
  passengerId: Scalars['ID'];
  weight?: Maybe<PassengerWeight>;
};

export type PassengerCountDetails = {
  __typename?: 'PassengerCountDetails';
  numberOfAdults?: Maybe<Scalars['Int']>;
  numberOfChildren?: Maybe<Scalars['Int']>;
  numberOfInfants?: Maybe<Scalars['Int']>;
};

export type PassengerDetail =
  | HumanPassengerDetail
  | PetPassengerDetail;

export type PassengerEmailContact = {
  __typename?: 'PassengerEmailContact';
  email: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  passengerEmailContactId: Scalars['ID'];
  type: PassengerEmailContactType;
};

export type PassengerEmailContactType = {
  __typename?: 'PassengerEmailContactType';
  name: Scalars['String'];
  orderSequence: Scalars['Int'];
  passengerEmailContactTypeId: Scalars['ID'];
};

export type PassengerGenderType = {
  __typename?: 'PassengerGenderType';
  code: Scalars['String'];
  name: Scalars['String'];
  passengerGenderTypeId: Scalars['ID'];
};

export type PassengerNamePrefix = {
  __typename?: 'PassengerNamePrefix';
  code: Scalars['String'];
  name: Scalars['String'];
  namePrefixId: Scalars['ID'];
};

export type PassengerNameSuffix = {
  __typename?: 'PassengerNameSuffix';
  code: Scalars['String'];
  name: Scalars['String'];
  nameSuffixId: Scalars['ID'];
};

export type PassengerPhoneContact = {
  __typename?: 'PassengerPhoneContact';
  countryCode?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  passengerPhoneContactId: Scalars['ID'];
  phoneNumber: Scalars['String'];
  type: PassengerPhoneContactType;
};

export type PassengerPhoneContactType = {
  __typename?: 'PassengerPhoneContactType';
  name: Scalars['String'];
  orderSequence: Scalars['Int'];
  passengerPhoneContactTypeId: Scalars['ID'];
};

export type PassengerWeight = {
  __typename?: 'PassengerWeight';
  symbol: Scalars['String'];
  unit: Scalars['String'];
  value: Scalars['Int'];
};

export type PaymentCard = {
  __typename?: 'PaymentCard';
  expirationMonth?: Maybe<Scalars['String']>;
  expirationYear?: Maybe<Scalars['String']>;
  lastFour?: Maybe<Scalars['String']>;
  paymentCardId: Scalars['ID'];
  type?: Maybe<Scalars['String']>;
};

export enum PaymentMethod {
  Account = 'ACCOUNT',
  Card = 'CARD',
  Check = 'CHECK',
  Credit = 'CREDIT',
  Other = 'OTHER',
  Pending = 'PENDING',
  Wire = 'WIRE',
}

export type Percentage = {
  __typename?: 'Percentage';
  formatted: Scalars['String'];
  value: Scalars['Float'];
};

export type PetAttribute = {
  __typename?: 'PetAttribute';
  breed?: Maybe<Scalars['String']>;
  friendlyWithCats?: Maybe<Scalars['Boolean']>;
  friendlyWithDogs?: Maybe<Scalars['Boolean']>;
  friendlyWithKids?: Maybe<Scalars['Boolean']>;
  hypoallergenic?: Maybe<Scalars['Boolean']>;
  petAttributeId: Scalars['ID'];
  servicePet?: Maybe<Scalars['Boolean']>;
};

export type PetPassengerDetail = {
  __typename?: 'PetPassengerDetail';
  name: Scalars['String'];
  petAttribute?: Maybe<PetAttribute>;
  /** Calculated field based on weight */
  petPassengerType: PetPassengerType;
  petType?: Maybe<PetType>;
};

export enum PetPassengerType {
  /** Pet below 50 lbs */
  PetBlack = 'PET_BLACK',
  /** Pet above 50 lbs */
  PetBlue = 'PET_BLUE',
}

export type PetType = {
  __typename?: 'PetType';
  code: Scalars['String'];
  name: Scalars['String'];
  petTypeId: Scalars['ID'];
};

export enum PortOfEntry {
  No = 'NO',
  PriorNoticeRequired = 'PRIOR_NOTICE_REQUIRED',
  Yes = 'YES',
}

export type PrivateFlight = Flight & {
  __typename?: 'PrivateFlight';
  aircraftInfo: Array<FlightAircraftInformation>;
  arrivalDateTime?: Maybe<Array<FlightDateTime>>;
  billedTime: FlightDuration;
  /** Each of these are new fields that can be resolved by *this service* */
  billing?: Maybe<Array<Billing>>;
  cancellationDateTime?: Maybe<FlightDateTime>;
  departureDateTime?: Maybe<Array<FlightDateTime>>;
  destination: FlightAirportFbo;
  flightDate: Scalars['LocalDate'];
  flightId: Scalars['ID'];
  flightTime: FlightDuration;
  flightType: Scalars['String'];
  fmsFlight?: Maybe<FmsFlight>;
  /** @deprecated Use origin field instead */
  fromAirport?: Maybe<Airport>;
  isOnPeakDay?: Maybe<Scalars['Boolean']>;
  leg: Scalars['Int'];
  origin: FlightAirportFbo;
  reservation: PrivateReservation;
  status: Array<FlightStatus>;
  /** @deprecated Soon will be deprecated and provided by segments attribute */
  techStops?: Maybe<Array<TechnicalStop>>;
  /** @deprecated Use destination field instead */
  toAirport?: Maybe<Airport>;
  travellerGroup: PrivateFlightTravellerGroup;
};

export type PrivateFlightTravellerGroup = FlightTravellerGroup & {
  __typename?: 'PrivateFlightTravellerGroup';
  /**
   * Total number of available seats for this entire flight
   * Number of available seats = (Aircraft max. passenger) - (passengers.size + unknownPassengers)
   */
  availableSeats?: Maybe<Scalars['Int']>;
  luggage?: Maybe<Array<FlightLuggage>>;
  passengers: Array<FlightPassenger>;
  /** Number of passengers with missing information */
  unknownPassengers?: Maybe<Scalars['Int']>;
};

export type PrivateReservation = Reservation & {
  __typename?: 'PrivateReservation';
  bookingMethod?: Maybe<Scalars['String']>;
  details: Array<PrivateReservationDetails>;
  origination?: Maybe<Scalars['String']>;
  reservationId: Scalars['ID'];
  reservationNumber: Scalars['String'];
  segments: Array<PrivateFlight>;
  span: ReservationSpan;
  tripType: TripType;
};

export type PrivateReservationDetails = ReservationDetails & {
  __typename?: 'PrivateReservationDetails';
  booker: ReservationBooker;
  bookingDate?: Maybe<Scalars['LocalDateTime']>;
  member: Member;
  paymentBreakdown?: Maybe<Array<TripPaymentBreakdown>>;
  priceBreakdown: Array<TripPriceBreakDown>;
  status?: Maybe<Array<ReservationStatus>>;
};

export type PwAlert = {
  __typename?: 'PwAlert';
  alertGroup: PwAlertGroup;
  alertId?: Maybe<Scalars['ID']>;
  alertType: PwAlertType;
  entityId?: Maybe<Scalars['ID']>;
  payload?: Maybe<PwAlertPayload>;
};

export type PwAlertFboChange = {
  __typename?: 'PwAlertFBOChange';
  airport: Airport;
  postalCode?: Maybe<Scalars['String']>;
};

export enum PwAlertGroup {
  FlightDetails = 'FLIGHT_DETAILS',
  MembershipExpiration = 'MEMBERSHIP_EXPIRATION',
  MissingPassengerInfo = 'MISSING_PASSENGER_INFO',
  Unknown = 'UNKNOWN',
}

export type PwAlertMembershipExpiring = {
  __typename?: 'PwAlertMembershipExpiring';
  expirationDate?: Maybe<Scalars['String']>;
};

export type PwAlertMissingPassengerInfo = {
  __typename?: 'PwAlertMissingPassengerInfo';
  flightNumber: Scalars['String'];
  reservation?: Maybe<Reservation>;
  unknownPassengerCount?: Maybe<Scalars['Int']>;
};

export type PwAlertPassenger = {
  __typename?: 'PwAlertPassenger';
  alertPassengerId: Scalars['ID'];
  firstName?: Maybe<Scalars['String']>;
  isPet?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
};

export type PwAlertPayload =
  | PwAlertFboChange
  | PwAlertMembershipExpiring
  | PwAlertMissingPassengerInfo
  | PwAlertPassenger
  | PwAlertScheduleUpdate
  | PwAlertTailNumberChange;

export type PwAlertScheduleUpdate = {
  __typename?: 'PwAlertScheduleUpdate';
  flightNumber: Scalars['String'];
  fromAirport: Airport;
  toAirport: Airport;
};

export type PwAlertTailNumberChange = {
  __typename?: 'PwAlertTailNumberChange';
  changeAssignment: PwTailNumberChangeType;
  flightNumber: Scalars['String'];
  newAircraft: Aircraft;
};

export enum PwAlertType {
  FboArrivalChange = 'FBO_ARRIVAL_CHANGE',
  FboDepartureChange = 'FBO_DEPARTURE_CHANGE',
  MembershipExpiration_90Days = 'MEMBERSHIP_EXPIRATION_90_DAYS',
  MembershipExpired = 'MEMBERSHIP_EXPIRED',
  MissingDateOfBirth = 'MISSING_DATE_OF_BIRTH',
  MissingGender = 'MISSING_GENDER',
  MissingWeightInfo = 'MISSING_WEIGHT_INFO',
  ScheduleUpdateDelayed = 'SCHEDULE_UPDATE_DELAYED',
  ScheduleUpdateFlexed = 'SCHEDULE_UPDATE_FLEXED',
  TailNumberChange = 'TAIL_NUMBER_CHANGE',
  UnconfirmedPassport = 'UNCONFIRMED_PASSPORT',
  Unknown = 'UNKNOWN',
  UnknownPassengers = 'UNKNOWN_PASSENGERS',
}

export type PwAuthorizedUser = {
  __typename?: 'PwAuthorizedUser';
  /** Unique Id for the authorized user */
  authorizedUserId: Scalars['ID'];
  /** Date of birth in local date format. Ex: 12/31/1969 */
  dateOfBirth?: Maybe<Scalars['LocalDate']>;
  /** Email of the authorized user */
  email?: Maybe<Scalars['String']>;
  /** Email contact information for the user */
  emailContact?: Maybe<PwEmailContact>;
  /** Determines if the user is enabled or disabled */
  enabled?: Maybe<Scalars['Boolean']>;
  /** First name of the authorized user */
  firstName?: Maybe<Scalars['String']>;
  /** Image for the user */
  imageUrl?: Maybe<Scalars['String']>;
  /** Determines if the user is a principal on the account */
  isPrincipal?: Maybe<Scalars['Boolean']>;
  /** Last name of the authorized user */
  lastName?: Maybe<Scalars['String']>;
  /** The number of login attempts for the user's account */
  loginAttempts?: Maybe<Scalars['Int']>;
  /** Passenger details for the user */
  passenger?: Maybe<PwUserPassengerDetail>;
  /** Determines if the user has accepted the terms and conditions */
  termsAndConditionsAccepted?: Maybe<Scalars['Boolean']>;
  /** The unix timestamp when the user accepted the terms. Ex: 1707212460000 */
  termsAndConditionsDateAccepted?: Maybe<
    Scalars['EpochTimestamp']
  >;
  /** The type of user. Ex: member */
  type?: Maybe<Scalars['String']>;
  /** A list of addresses for the user */
  userAddresses?: Maybe<Array<PwWuMemberAddress>>;
  /** A list of email contacts for the user */
  userEmailContacts?: Maybe<Array<PwUserEmailContact>>;
  /** A list of phone contacts for the user */
  userPhoneContacts?: Maybe<Array<PwUserPhoneContact>>;
  /** The username for the user's login */
  username?: Maybe<Scalars['String']>;
};

/** The billing information for the credit card owner */
export type PwBillingContact = {
  __typename?: 'PwBillingContact';
  /** Primary address information such as street address. Ex: 2566 Dow ST. */
  address1: Scalars['String'];
  /** Secondary address information such as apartment, office or suite number. Ex: Apt 204 */
  address2?: Maybe<Scalars['String']>;
  /** City name of the credit card owner */
  city: Scalars['String'];
  /** Country of the credit card owner. Ex: US */
  country?: Maybe<Scalars['String']>;
  /** First name of the credit card owner */
  firstName: Scalars['String'];
  /** Last name of the credit card owner */
  lastName: Scalars['String'];
  /** State of the credit card owner. Ex: NJ */
  state?: Maybe<Scalars['String']>;
  /** Zip code of the credit card owner */
  zip: Scalars['Int'];
};

/** The billing information for the credit card owner */
export type PwBillingContactInput = {
  /** Primary address information such as street address. Ex: 2566 Dow ST. */
  address1: Scalars['String'];
  /** Secondary address information such as apartment, office or suite number. Ex: Apt 204 */
  address2?: InputMaybe<Scalars['String']>;
  /** City name of the credit card owner */
  city: Scalars['String'];
  /** First name of the credit card owner */
  firstName: Scalars['String'];
  /** Last name of the credit card owner */
  lastName: Scalars['String'];
  /** State of the credit card owner. Ex: NJ */
  state?: InputMaybe<Scalars['String']>;
  /** Zip code of the credit card owner */
  zip: Scalars['Int'];
};

export type PwEmailContact = {
  __typename?: 'PwEmailContact';
  /** The email address for the user */
  email?: Maybe<Scalars['String']>;
  /** The name for the email contact. Ex: Primary */
  name?: Maybe<Scalars['String']>;
  /** The order sequence for the email contact type */
  orderSequence?: Maybe<Scalars['Int']>;
};

/** The necessary information for an existing credit card */
export type PwExistingCreditCard = {
  __typename?: 'PwExistingCreditCard';
  /** Last 4 digits of the credit card. EX: '1234' */
  cardLastFourDigits: Scalars['String'];
  /** The type of credit card. EX: AMEX, JCB, etc... */
  cardType: Scalars['String'];
  /** Expiration Month of the credit card. Ex: 2 */
  expirationMonth: Scalars['Int'];
  /** Expiration Year of the credit card. Ex: 2026 */
  expirationYear: Scalars['Int'];
};

/** Only the information needed to view and edit an existing credit card on the user's account */
export type PwExistingCreditCardDetails = {
  __typename?: 'PwExistingCreditCardDetails';
  /** The billing information for the credit card owner */
  billingContact: PwBillingContact;
  /** Existing credit card information */
  creditCard: PwExistingCreditCard;
  /** The id of the credit card, only needed for the DEL method */
  creditCardId: Scalars['ID'];
  /** If true, this is the primary credit card for the user's account */
  primaryCard: Scalars['Boolean'];
};

/** Input for only the information needed to view and edit an existing credit card on the user's account */
export type PwExistingCreditCardDetailsInput = {
  /** The billing information for the credit card owner */
  billingContact: PwBillingContactInput;
  /** Existing credit card information */
  creditCard: PwExistingCreditCardInput;
  /** If true, this is the primary credit card for the user's account */
  primaryCard: Scalars['Boolean'];
};

/** Input for the necessary information for an existing credit card */
export type PwExistingCreditCardInput = {
  /** Last 4 digits of the credit card. EX: '1234' */
  cardLastFourDigits: Scalars['String'];
  /** The type of credit card. EX: AMEX, JCB, etc... */
  cardType: Scalars['String'];
  /** Expiration Month of the credit card. Ex: 2 */
  expirationMonth: Scalars['Int'];
  /** Expiration Year of the credit card. Ex: 2026 */
  expirationYear: Scalars['Int'];
};

/** The information for an existing notification alert */
export type PwExistingNotificationAlertInput = {
  /** Id for the From Airports taken from the Airport type */
  fromAirportIds?: InputMaybe<Array<Scalars['ID']>>;
  /** From Airport Location Description like city name and state */
  fromLocationDescription?: InputMaybe<Scalars['String']>;
  /** Unique notification id */
  notificationId: Scalars['ID'];
  /** Id for the To Airports taken from the Airport type */
  toAirportIds?: InputMaybe<Array<Scalars['ID']>>;
  /** To Airport Location Description like city name and state */
  toLocationDescription?: InputMaybe<Scalars['String']>;
  /** List of unique user ids which the alert notification will be sent to */
  userIds: Array<Scalars['ID']>;
};

export type PwFlightLuggage = {
  __typename?: 'PwFlightLuggage';
  flightLuggageId: Scalars['ID'];
  luggageCount: Scalars['Int'];
  luggageType: PwLuggageType;
};

export type PwFlightPassengerInfo = {
  __typename?: 'PwFlightPassengerInfo';
  isAuthorizedUser?: Maybe<Scalars['Boolean']>;
  isFavPassenger?: Maybe<Scalars['Boolean']>;
  isLeadPassenger?: Maybe<Scalars['Boolean']>;
  isPet?: Maybe<Scalars['Boolean']>;
  passenger?: Maybe<PwWuPassenger>;
  passengerAlias?: Maybe<Scalars['String']>;
  passengerImageUrl?: Maybe<Scalars['String']>;
  passengerMember?: Maybe<PwWuPassengerMember>;
  passengerName?: Maybe<Scalars['String']>;
};

export type PwFlightSummary = {
  __typename?: 'PwFlightSummary';
  availableSeats?: Maybe<Scalars['Int']>;
  delayedArrivalTime?: Maybe<Scalars['LocalDateTime']>;
  delayedDepartureTime?: Maybe<Scalars['LocalDateTime']>;
  estimatedArrivalTime?: Maybe<Scalars['LocalDateTime']>;
  estimatedDepartureTime?: Maybe<Scalars['LocalDateTime']>;
  flight: Flight;
  flightDate: Scalars['LocalDate'];
  flightTime: Scalars['String'];
  flightType?: Maybe<Scalars['String']>;
  isOnPeakDay?: Maybe<Scalars['Boolean']>;
  leg: Scalars['Int'];
  luggageDetails?: Maybe<Array<PwFlightLuggage>>;
  onFleet?: Maybe<Scalars['Boolean']>;
  passengerMemberInfo: Array<PwFlightPassengerInfo>;
  pilotImageUrl?: Maybe<Scalars['String']>;
  pilotName?: Maybe<Scalars['String']>;
  requestedDateTime?: Maybe<Scalars['LocalDateTime']>;
  reservation: Reservation;
  techStopAirport?: Maybe<Array<Airport>>;
  zonedEstimatedArrivalTime?: Maybe<
    Scalars['ZonedDateTime']
  >;
  zonedEstimatedDepartureTime?: Maybe<
    Scalars['ZonedDateTime']
  >;
};

export type PwLuggageType = {
  __typename?: 'PwLuggageType';
  code: Scalars['String'];
  luggageTypeId: Scalars['ID'];
  name: Scalars['String'];
};

/** Input for all the information required to add a new credit card on the user's account */
export type PwNewCreditCardDetailsInput = {
  /** The billing information for the credit card owner */
  billingContact: PwBillingContactInput;
  /** New credit card information */
  creditCard: PwNewCreditCardInput;
  /** If true, this is the primary credit card for the user's account */
  primaryCard: Scalars['Boolean'];
};

/** Input for the necessary information for a new credit card */
export type PwNewCreditCardInput = {
  /** Encrypted credit card number */
  cardNumber: Scalars['String'];
  /** The type of credit card. EX: AMEX, JCB, etc... */
  cardType: Scalars['String'];
  /** Expiration Month of the credit card. Ex: 2 */
  expirationMonth: Scalars['Int'];
  /** Expiration Year of the credit card. Ex: 2026 */
  expirationYear: Scalars['Int'];
  /** CVV of the credit card. Ex: '123' */
  securityCode: Scalars['String'];
};

/** The necessary input information to create a new notification alert */
export type PwNewNotificationAlertInput = {
  /** Id for the From Airports taken from the Airport type */
  fromAirportIds?: InputMaybe<Array<Scalars['ID']>>;
  /** From Airport Location Description like city name and state */
  fromLocationDescription?: InputMaybe<Scalars['String']>;
  /** Id for the To Airports taken from the Airport type */
  toAirportIds?: InputMaybe<Array<Scalars['ID']>>;
  /** To Airport Location Description like city name and state */
  toLocationDescription?: InputMaybe<Scalars['String']>;
  /** List of unique user ids which the alert notification will be sent to */
  userIds: Array<Scalars['ID']>;
};

/** The necessary information for a notification alert */
export type PwNotificationAlert = {
  __typename?: 'PwNotificationAlert';
  /** From Airport */
  fromAirports?: Maybe<Array<Airport>>;
  /** From Airport Location Description like city name and state */
  fromLocationDescription?: Maybe<Scalars['String']>;
  /** Unique notification id */
  notificationId: Scalars['ID'];
  /** To Airport */
  toAirports?: Maybe<Array<Airport>>;
  /** To Airport Location Description like city name and state */
  toLocationDescription?: Maybe<Scalars['String']>;
  /** List of unique user ids which the alert notification will be sent to */
  userIds: Array<Scalars['ID']>;
};

/** The necessary information for a notification setting, to receive Email or Push Notification alerts */
export type PwNotificationSetting = {
  __typename?: 'PwNotificationSetting';
  /** Boolean which determines if the email notification is enabled/disabled */
  emailEnabled?: Maybe<Scalars['Boolean']>;
  /** Unique emailId this can be used to enable/disable flag */
  emailId?: Maybe<Scalars['ID']>;
  /** Title of the notification type ex: HOT FLIGHT ALERTS */
  notificationHeader: Scalars['String'];
  /** Boolean which determines if the push notification is enabled/disabled */
  pushEnabled?: Maybe<Scalars['Boolean']>;
  /** Unique pushId this can be used to enable/disable flag */
  pushId?: Maybe<Scalars['ID']>;
  /** Number which represents the sort order of a notification setting */
  sortOrder: Scalars['Int'];
};

/** Response object which contains all of the information related to an Email Notification Setting */
export type PwNotificationSettingEmailControl = {
  __typename?: 'PwNotificationSettingEmailControl';
  /** The type of email notification. Ex: SHARED_FLIGHT_ALERT_FOR_USER */
  emailNotificationType: Scalars['String'];
  /** Boolean that determines whether the notification is enabled or disabled */
  enabled: Scalars['Boolean'];
  /** Category of the notification. Ex: GENERAL */
  notificationCategory: Scalars['String'];
  /** Brief description for the notification setting */
  notificationDescription?: Maybe<Scalars['String']>;
  /** Title for the notification setting. Ex: SHARED FLIGHT ALERTS */
  notificationHeader: Scalars['String'];
  /** Unique ID for the Email Notification Setting */
  notificationSettingEmailControlId: Scalars['ID'];
  /** Number which represents the sort order of a notification setting */
  sortOrder: Scalars['Int'];
};

/** Response object which contains all of the information related to a Push Notification Setting */
export type PwNotificationSettingPushControl = {
  __typename?: 'PwNotificationSettingPushControl';
  /** Boolean that determines whether the notification is enabled or disabled */
  enabled: Scalars['Boolean'];
  /** Category of the notification. Ex: GENERAL */
  notificationCategory: Scalars['String'];
  /** Brief description for the notification setting */
  notificationDescription?: Maybe<Scalars['String']>;
  /** Title for the notification setting. Ex: SHARED FLIGHT ALERTS */
  notificationHeader: Scalars['String'];
  /** Unique ID for the Push Notification Setting */
  notificationSettingPushControlId: Scalars['ID'];
  /** The type of push notification. Ex: SHARED_FLIGHT_ALERT_FOR_USER */
  pushNotificationType: Scalars['String'];
  /** Number which represents the order of the notification setting */
  sortOrder: Scalars['Int'];
};

/**
 * The response object which contains the list of general and operational notifications
 * for a users account
 */
export type PwNotificationSettings = {
  __typename?: 'PwNotificationSettings';
  /** List of general notification settings for the user's account */
  general: Array<Maybe<PwNotificationSetting>>;
  /** List of operational notification settings for the user's account */
  operational: Array<Maybe<PwNotificationSetting>>;
};

/** Object that represents a Recent airport */
export type PwRecentAirport = {
  __typename?: 'PwRecentAirport';
  /** Airport object referenced by the RecentAirport */
  airport: Airport;
  /** Determines if the RecentAirport is a home airport */
  isHomeAirport?: Maybe<Scalars['Boolean']>;
  /** Determines if the RecentAirport is a saved airport */
  isSavedAirport?: Maybe<Scalars['Boolean']>;
  /** The date in which the user last search for this airport. Ex: 2024-03-28T17:33:49.089Z */
  lastSearchDate?: Maybe<Scalars['LocalDateTime']>;
  /** The date in which the user last traveled to this airport. Ex: 2024-03-28T17:33:49.089Z */
  lastTravelDate?: Maybe<Scalars['LocalDateTime']>;
  /** Unique recent airport id  */
  recentAirportId: Scalars['ID'];
  /** The number of searches the user submitted for this airport */
  searchFrequency?: Maybe<Scalars['Int']>;
  /** The frequency in which the user travels to this airport */
  travelFrequency?: Maybe<Scalars['Int']>;
  /** The type for the RecentAirport */
  type?: Maybe<PwRecentAirportType>;
};

/** The different types of recent airports */
export enum PwRecentAirportType {
  /** Home airport */
  Home = 'HOME',
  /** Recently searched airport */
  Recent = 'RECENT',
  /** Saved airport */
  Saved = 'SAVED',
}

export type PwSpringPageInfo = {
  number?: InputMaybe<Scalars['Int']>;
  size?: InputMaybe<Scalars['Int']>;
};

export enum PwTailNumberChangeType {
  ChangeAssignment = 'CHANGE_ASSIGNMENT',
  FirstAssignment = 'FIRST_ASSIGNMENT',
  SecondAssignment = 'SECOND_ASSIGNMENT',
}

/** Response object which contains home, recently searched, and saved airport details */
export type PwTopAirports = {
  __typename?: 'PwTopAirports';
  /** The user's home airport */
  homeAirport?: Maybe<PwRecentAirport>;
  /** The users most recently searched for airports */
  recentAirports: Array<PwRecentAirport>;
  /** The users saved airports */
  savedAirports: Array<PwRecentAirport>;
};

export type PwUserEmailContact = {
  __typename?: 'PwUserEmailContact';
  /** The date the email contact was created represented by Unix timestamp. Ex: 1707212459000 */
  createDate?: Maybe<Scalars['EpochTimestamp']>;
  /** The email address for the user */
  email?: Maybe<Scalars['String']>;
  /** The id for the email contact type */
  emailContactTypeId?: Maybe<Scalars['ID']>;
  /** The name for the email contact. Ex: Primary */
  name?: Maybe<Scalars['String']>;
  /** The order sequence for the email contact type */
  orderSequence?: Maybe<Scalars['Int']>;
  /** The date the email contact was updated represented by Unix timestamp. Ex: 1707328411000 */
  updateDate?: Maybe<Scalars['EpochTimestamp']>;
  /** Unique id for the user's email contact */
  userEmailContactId: Scalars['ID'];
};

export type PwUserGenderType = {
  __typename?: 'PwUserGenderType';
  /** Code for the gender type. Ex: M, F, U, X */
  code?: Maybe<Scalars['String']>;
  /** Id for the gender type */
  genderTypeId?: Maybe<Scalars['ID']>;
  /** Name for the gender type. Ex: Male. Female */
  name?: Maybe<Scalars['String']>;
};

export type PwUserPassengerDetail = {
  __typename?: 'PwUserPassengerDetail';
  /** Determines if the passenger is an authorized user */
  appUser?: Maybe<Scalars['Boolean']>;
  /** Date of birth for the passenger in Unix timestamp. Ex: 658299600000 */
  dateOfBirth?: Maybe<Scalars['EpochTimestamp']>;
  /** The email address for the passenger */
  email?: Maybe<Scalars['String']>;
  /** Legal first name of the passenger */
  firstName?: Maybe<Scalars['String']>;
  /** The gender of the passenger */
  genderType?: Maybe<PwUserGenderType>;
  /** Determines if the passenger has a passport */
  hasPassport?: Maybe<Scalars['Boolean']>;
  /** Determines if the passenger is a lap child */
  lapChild?: Maybe<Scalars['Boolean']>;
  /** Legal last name of the passenger */
  lastName?: Maybe<Scalars['String']>;
  /** Determines if the passenger is a member */
  member?: Maybe<Scalars['Boolean']>;
  /** Legal middle name of the passenger */
  middleName?: Maybe<Scalars['String']>;
  /** Determines if the passenger is a pet */
  pet?: Maybe<Scalars['Boolean']>;
  /** The pet type of the passenger */
  petType?: Maybe<Scalars['String']>;
  /** Unique passenger id */
  userPassengerDetailId?: Maybe<Scalars['ID']>;
  /** The weight in pounds of the passenger */
  weight?: Maybe<Scalars['String']>;
};

export type PwUserPhoneContact = {
  __typename?: 'PwUserPhoneContact';
  /** The date the phone contact was created represented by Unix timestamp. Ex: 1707212459000 */
  createDate?: Maybe<Scalars['EpochTimestamp']>;
  /** The name of the phone contact. Ex: Primary */
  name?: Maybe<Scalars['String']>;
  /** The order sequence of the phone contact type */
  orderSequence?: Maybe<Scalars['Int']>;
  /** The id for the phone contact type */
  phoneContactTypeId?: Maybe<Scalars['ID']>;
  /** Phone number of the user */
  phoneNumber?: Maybe<Scalars['String']>;
  /** The date the phone contact was updated represented by Unix timestamp. Ex: 1707328411000 */
  updateDate?: Maybe<Scalars['EpochTimestamp']>;
  /** Unique dd for the user's phone contact */
  userPhoneContactId: Scalars['ID'];
};

export type PwUserTermsAndConditions = {
  __typename?: 'PwUserTermsAndConditions';
  termsAndConditionsAccepted: Scalars['Boolean'];
  termsAndConditionsDateAccepted?: Maybe<
    Scalars['LocalDateTime']
  >;
  type?: Maybe<Scalars['String']>;
  version: Scalars['String'];
};

export type PwUserTermsAndConditionsInput = {
  termsAndConditionsAccepted: Scalars['Boolean'];
  version: Scalars['String'];
};

export type PwWuMemberAddress = {
  __typename?: 'PwWUMemberAddress';
  wuMemberAddressId: Scalars['ID'];
};

export type PwWuPassenger = {
  __typename?: 'PwWUPassenger';
  passengerId: Scalars['ID'];
};

export type PwWuPassengerMember = {
  __typename?: 'PwWUPassengerMember';
  passengerMemberId: Scalars['ID'];
};

/**
 * 'Query' is a special type in GraphQL which denotes the root or start of a schema.
 * in this case `billing` takes in a list of `Id` (String), and outputs a list of `Billing`s.
 * this query is not part of the requirement, but is a useful test point for our code.
 */
export type Query = {
  __typename?: 'Query';
  /**
   * Get all accounts for a given member.
   * `types`: Optional list of account types to filter by. If not provided, all accounts will be returned.
   */
  accountsForMember?: Maybe<Array<Account>>;
  /** Allows to fetch audit logs within given time range */
  adsAuditLogsByDateTimeRange?: Maybe<Array<AdsAuditLog>>;
  /** Allows to fetch audit logs for given entity */
  adsAuditLogsByTypeAndTypeId?: Maybe<Array<AdsAuditLog>>;
  /** Allows to fetch an aircraft by ID */
  aircraft?: Maybe<Aircraft>;
  /** Allows to fetch audit logs within given time range */
  aircraftAuditLogsByDateTimeRange?: Maybe<
    Array<AircraftDataServiceAuditLog>
  >;
  /** Allows to fetch audit logs for given entity */
  aircraftAuditLogsByTypeAndTypeId?: Maybe<
    Array<AircraftDataServiceAuditLog>
  >;
  /** Allows to fetch an aircraft by tail number */
  aircraftByTailNumber?: Maybe<Aircraft>;
  /** Allows to fetch an aircraft cabin by ID */
  aircraftCabin?: Maybe<AircraftCabin>;
  /** Allows to fetch aircraft cabins by IDs */
  aircraftCabins?: Maybe<Array<AircraftCabin>>;
  /** Allows to fetch aircraft cabins by codes */
  aircraftCabinsByCodes?: Maybe<Array<AircraftCabin>>;
  /** Get a single AircraftInfo object by its category */
  aircraftInfoByCategory?: Maybe<AircraftInfo>;
  /** Get a list of all the AircraftInfo objects */
  aircraftInfos: Array<AircraftInfo>;
  /** Allows to fetch an aircraft type by ID */
  aircraftType?: Maybe<AircraftType>;
  /** Allows to fetch aircraft types by IDs */
  aircraftTypes?: Maybe<Array<AircraftType>>;
  /** Allows to fetch aircrafts by IDs */
  aircrafts?: Maybe<Array<Aircraft>>;
  /** Allows to fetch aircrafts by tail numbers */
  aircraftsByTailNumbers?: Maybe<Array<Aircraft>>;
  /** Allows to fetch an airport by Id */
  airport?: Maybe<Airport>;
  /**
   * Allows to fetch airport aircraft types
   * Used while adding/updating Airport General Info
   */
  airportAircraftTypes?: Maybe<Array<AirportAircraftType>>;
  /**
   * Allows to fetch airport categories
   * Used while adding/updating Airport General Info
   */
  airportCategories?: Maybe<Array<AirportCategory>>;
  /**
   * Allows to fetch an airport closure reason
   * Used while adding/updating Airport Schedule Exception
   */
  airportClosureReasons?: Maybe<
    Array<AirportClosureReason>
  >;
  /** Allows to fetch airports by Ids */
  airports?: Maybe<Array<Airport>>;
  /** Allows to fetch airports by codes (icao, iata, or faa) */
  airportsByCodeIn?: Maybe<Array<Airport>>;
  /** Allows to fetch airports by FMSAirportIds */
  airportsByFMSAirportIds?: Maybe<Array<Airport>>;
  /** Allows to fetch airports by GeoLocation */
  airportsByGeoLocation?: Maybe<Array<Airport>>;
  /** Allows to search airports */
  airportsBySearcher?: Maybe<Array<Airport>>;
  /** Allows to fetch an availability offer request by ID */
  availabilityOfferRequest: OfferRequest;
  /** Allows to fetch availability offer requests by IDs */
  availabilityOfferRequests: Array<OfferRequest>;
  /** Allows to fetch flights by status and given date range only */
  availabilityOfferRequestsByStatusAndDateRange?: Maybe<OfferRequestConnection>;
  /** Allows to recalculate price breakdown for an availability offer */
  availabilityOfferUpdatePriceBreakDown: Array<RsPriceBreakdown>;
  /** Get a billing by its ID. */
  billingById?: Maybe<Array<Billing>>;
  /**
   * Get all billings for a member, optionally filtered by status and type.
   * First: The number of results to return. Defaults to 20.
   * After: The cursor to start from. Defaults to the first result.
   * Status: The status of the billing to filter by. Defaults to all statuses.
   * Type: The type of the billing to filter by. Defaults to all types.
   * MemberId: The ID of the member to filter by. This input is only allowed from Atlas, and is required if Atlas is the client.
   * Other services will provide this through the bearer token.
   */
  billingForMemberPaged: BillingConnection;
  /**
   * Allows to fetch cancelled flights from past two years to next a year and six months
   * Note:
   *     MemberId is required when querying from internal application such as Atlas/BlueSky
   *     MemberId is not considered when querying from members website or apps
   */
  cancelledFlights?: Maybe<FlightConnection>;
  /** Allows to fetch clearances by Country Code (ISO2) */
  clearancesByCountryCode?: Maybe<Array<Clearance>>;
  /** Allows to fetch Clearances by Country Codes (ISO2) */
  clearancesByCountryCodes?: Maybe<Array<Clearance>>;
  /**
   * validate a leg for a single search.
   * validateClientSingleSearch(request: [FlightSearchRequest!]!): SingleSearchFeasibility!
   * perform a single search for a clientId gotten from a validateClientSingleSearch call
   * clientSingleSearch(clientId: String!): SingleSearch!
   * perform a single search for alternate airport options for a clientId gotten from a validateClientSingleSearch call
   */
  clientSingleSearchAlternateAirportOptions: SingleSearch;
  creditsBalanceByMember?: Maybe<Currency>;
  /** Get all credits for a given member. */
  creditsForMember?: Maybe<Array<Credit>>;
  /** Allows to fetch FixedBasedOperators (FBOs) by Id */
  fbosByIdIn?: Maybe<Array<Fbo>>;
  /** Allows to fetch a flight by ID */
  flight?: Maybe<Flight>;
  /** Allows to fetch flights by IDs */
  flights?: Maybe<Array<Flight>>;
  /** Allows to fetch flights by given date range */
  flightsByDateRange?: Maybe<FlightConnection>;
  luggageTypes: Array<LuggageType>;
  /** Get a list of all the marketing news for a specific member type and category */
  marketingNewsByCategory?: Maybe<Array<MarketingNews>>;
  /** Allows to fetch member by memberId */
  member?: Maybe<Member>;
  memberAdvantage?: Maybe<MemberAdvantage>;
  /** Allows to fetch member by memberNumber */
  memberByNumber?: Maybe<Member>;
  /** Get all the member sub types */
  memberSubTypes: Array<MemberSubType>;
  /** Get all the member types */
  memberTypes: Array<MemberType>;
  /** Get all the member user types */
  memberUserTypes: Array<MemberUserType>;
  /** Allows to fetch members by memberIds */
  members?: Maybe<Array<Member>>;
  /** Allows to fetch members by memberNumbers */
  membersByNumbers?: Maybe<Array<Member>>;
  /** Get all the passenger email contact types */
  passengerEmailContactTypes?: Maybe<
    Array<PassengerEmailContactType>
  >;
  /** Get all the passenger gender types */
  passengerGenderTypes?: Maybe<Array<PassengerGenderType>>;
  /** Get all the passenger name prefix types */
  passengerNamePrefixes?: Maybe<Array<PassengerNamePrefix>>;
  /** Get all the passenger name suffix types */
  passengerNameSuffixes?: Maybe<Array<PassengerNameSuffix>>;
  /** Get all the passenger phone contact types */
  passengerPhoneContactTypes?: Maybe<
    Array<PassengerPhoneContactType>
  >;
  /**
   * Allows to fetch past flights upto two years from current date
   * Note:
   *     MemberId is required when querying from internal application such as Atlas/BlueSky
   *     MemberId is not considered when querying from members website or apps
   */
  pastFlights?: Maybe<FlightConnection>;
  /** Get all the passenger weight types */
  petTypes?: Maybe<Array<PetType>>;
  pricingForDates: Array<DatePrice>;
  /**
   * Get the active alerts for a collection or group type
   * GET /alert/active?collectionOrGroupTypeName={collectionOrGroupTypeName}&isCollection={isCollection}
   */
  pwActiveAlerts: Array<PwAlert>;
  /**
   * Search for airports by a string search criteria
   * POST /aviation/findbycustomlogic/Airport
   */
  pwAirportsBySearchString: Array<Airport>;
  /**
   * Get a list of the authorized users on a user's account
   * GET /ms/api/user/info/appUsers
   */
  pwAuthorizedUsers: Array<PwAuthorizedUser>;
  /**
   * Get List of NotificationAlerts for the user's account by userId
   * GET /ms/rest/user/sharedFlightAlertLocations/member/{userId}
   */
  pwNotificationAlertsByMemberId: Array<PwNotificationAlert>;
  /**
   * Get an object which contains lists of general and operational notification settings for the users account by userId.
   * GET /notification/setting/v1/user/{userId}
   */
  pwNotificationSettingsByUserId: PwNotificationSettings;
  /**
   * Get home, saved, and recently searched airports
   * POST /member/recent-airport/get-top
   */
  pwTopRecentAirports: PwTopAirports;
  pwUpcomingFlights: Array<PwFlightSummary>;
  pwUserTermsAndConditions?: Maybe<PwUserTermsAndConditions>;
  /** Allows to fetch a reservation by ID */
  reservation?: Maybe<Reservation>;
  /** Allows  to fetch a reservation by reservation number */
  reservationByNumber?: Maybe<Reservation>;
  /** Allows  to fetch reservations by reservation numbers */
  reservationByNumberIn?: Maybe<Array<Reservation>>;
  /** Allows to fetch reservations by IDs */
  reservations?: Maybe<Array<Reservation>>;
  /** Allows to search members */
  searchMembers?: Maybe<Array<Member>>;
  /**
   * Get all statements for a given member.
   * `accountTypes`: Optional list of account types to filter by. If not provided, all statements for all account types will be returned.
   */
  statementsForMember?: Maybe<StatementList>;
  /** Allows to fetch trip summary for single search result */
  tripSearchSummary: TripSearchSummary;
  /** Allows to recalculate price breakdown of a trip summary for single search result */
  tripSearchUpdatePriceBreakDown: TripSearchSummary;
  /**
   * Returns list of upcoming availability offer
   * Note:
   *     MemberId is required when querying from internal application such as Atlas/BlueSky
   *     MemberId is optional/not considered and will be fetched from the session when querying from members website or apps
   */
  upcomingAvailabilityOfferRequests?: Maybe<OfferRequestConnection>;
  /**
   * Allows to fetch upcoming flights upto a year and six months from current date
   * Note:
   *     MemberId is required when querying from internal application such as Atlas/BlueSky
   *     MemberId is not considered when querying from members website or apps
   */
  upcomingFlights?: Maybe<FlightConnection>;
  /**
   * Returns list of upcoming reservations
   * Note:
   *     MemberId is required when querying from internal application such as Atlas/BlueSky
   *     MemberId is optional/not considered and will be fetched from the session when querying from members website or apps
   */
  upcomingReservations?: Maybe<ReservationConnection>;
  /** Allows to fetch weather forecast */
  weatherForecast?: Maybe<Array<WeatherOutput>>;
};

/**
 * 'Query' is a special type in GraphQL which denotes the root or start of a schema.
 * in this case `billing` takes in a list of `Id` (String), and outputs a list of `Billing`s.
 * this query is not part of the requirement, but is a useful test point for our code.
 */
export type QueryAccountsForMemberArgs = {
  types?: InputMaybe<Array<AccountType>>;
};

/**
 * 'Query' is a special type in GraphQL which denotes the root or start of a schema.
 * in this case `billing` takes in a list of `Id` (String), and outputs a list of `Billing`s.
 * this query is not part of the requirement, but is a useful test point for our code.
 */
export type QueryAdsAuditLogsByDateTimeRangeArgs = {
  endDateTime: Scalars['LocalDateTime'];
  startDateTime: Scalars['LocalDateTime'];
};

/**
 * 'Query' is a special type in GraphQL which denotes the root or start of a schema.
 * in this case `billing` takes in a list of `Id` (String), and outputs a list of `Billing`s.
 * this query is not part of the requirement, but is a useful test point for our code.
 */
export type QueryAdsAuditLogsByTypeAndTypeIdArgs = {
  entityId: Scalars['Long'];
  entityType: Scalars['String'];
  fetchChildLogs?: InputMaybe<Scalars['Boolean']>;
};

/**
 * 'Query' is a special type in GraphQL which denotes the root or start of a schema.
 * in this case `billing` takes in a list of `Id` (String), and outputs a list of `Billing`s.
 * this query is not part of the requirement, but is a useful test point for our code.
 */
export type QueryAircraftArgs = {
  aircraftId: Scalars['ID'];
};

/**
 * 'Query' is a special type in GraphQL which denotes the root or start of a schema.
 * in this case `billing` takes in a list of `Id` (String), and outputs a list of `Billing`s.
 * this query is not part of the requirement, but is a useful test point for our code.
 */
export type QueryAircraftAuditLogsByDateTimeRangeArgs = {
  endDateTime: Scalars['LocalDateTime'];
  startDateTime: Scalars['LocalDateTime'];
};

/**
 * 'Query' is a special type in GraphQL which denotes the root or start of a schema.
 * in this case `billing` takes in a list of `Id` (String), and outputs a list of `Billing`s.
 * this query is not part of the requirement, but is a useful test point for our code.
 */
export type QueryAircraftAuditLogsByTypeAndTypeIdArgs = {
  entityId: Scalars['Long'];
  entityType: Scalars['String'];
  fetchChildLogs?: InputMaybe<Scalars['Boolean']>;
};

/**
 * 'Query' is a special type in GraphQL which denotes the root or start of a schema.
 * in this case `billing` takes in a list of `Id` (String), and outputs a list of `Billing`s.
 * this query is not part of the requirement, but is a useful test point for our code.
 */
export type QueryAircraftByTailNumberArgs = {
  tailNumber: Scalars['String'];
};

/**
 * 'Query' is a special type in GraphQL which denotes the root or start of a schema.
 * in this case `billing` takes in a list of `Id` (String), and outputs a list of `Billing`s.
 * this query is not part of the requirement, but is a useful test point for our code.
 */
export type QueryAircraftCabinArgs = {
  aircraftCabinId: Scalars['ID'];
};

/**
 * 'Query' is a special type in GraphQL which denotes the root or start of a schema.
 * in this case `billing` takes in a list of `Id` (String), and outputs a list of `Billing`s.
 * this query is not part of the requirement, but is a useful test point for our code.
 */
export type QueryAircraftCabinsArgs = {
  aircraftCabinIds: Array<Scalars['ID']>;
};

/**
 * 'Query' is a special type in GraphQL which denotes the root or start of a schema.
 * in this case `billing` takes in a list of `Id` (String), and outputs a list of `Billing`s.
 * this query is not part of the requirement, but is a useful test point for our code.
 */
export type QueryAircraftCabinsByCodesArgs = {
  codes: Array<Scalars['String']>;
};

/**
 * 'Query' is a special type in GraphQL which denotes the root or start of a schema.
 * in this case `billing` takes in a list of `Id` (String), and outputs a list of `Billing`s.
 * this query is not part of the requirement, but is a useful test point for our code.
 */
export type QueryAircraftInfoByCategoryArgs = {
  category: AircraftCategory;
};

/**
 * 'Query' is a special type in GraphQL which denotes the root or start of a schema.
 * in this case `billing` takes in a list of `Id` (String), and outputs a list of `Billing`s.
 * this query is not part of the requirement, but is a useful test point for our code.
 */
export type QueryAircraftTypeArgs = {
  aircraftTypeId: Scalars['ID'];
};

/**
 * 'Query' is a special type in GraphQL which denotes the root or start of a schema.
 * in this case `billing` takes in a list of `Id` (String), and outputs a list of `Billing`s.
 * this query is not part of the requirement, but is a useful test point for our code.
 */
export type QueryAircraftTypesArgs = {
  aircraftTypeIds: Array<Scalars['ID']>;
};

/**
 * 'Query' is a special type in GraphQL which denotes the root or start of a schema.
 * in this case `billing` takes in a list of `Id` (String), and outputs a list of `Billing`s.
 * this query is not part of the requirement, but is a useful test point for our code.
 */
export type QueryAircraftsArgs = {
  aircraftIds: Array<Scalars['ID']>;
};

/**
 * 'Query' is a special type in GraphQL which denotes the root or start of a schema.
 * in this case `billing` takes in a list of `Id` (String), and outputs a list of `Billing`s.
 * this query is not part of the requirement, but is a useful test point for our code.
 */
export type QueryAircraftsByTailNumbersArgs = {
  tailNumbers: Array<Scalars['String']>;
};

/**
 * 'Query' is a special type in GraphQL which denotes the root or start of a schema.
 * in this case `billing` takes in a list of `Id` (String), and outputs a list of `Billing`s.
 * this query is not part of the requirement, but is a useful test point for our code.
 */
export type QueryAirportArgs = {
  airportId: Scalars['ID'];
};

/**
 * 'Query' is a special type in GraphQL which denotes the root or start of a schema.
 * in this case `billing` takes in a list of `Id` (String), and outputs a list of `Billing`s.
 * this query is not part of the requirement, but is a useful test point for our code.
 */
export type QueryAirportClosureReasonsArgs = {
  includeDeactivated?: InputMaybe<Scalars['Boolean']>;
};

/**
 * 'Query' is a special type in GraphQL which denotes the root or start of a schema.
 * in this case `billing` takes in a list of `Id` (String), and outputs a list of `Billing`s.
 * this query is not part of the requirement, but is a useful test point for our code.
 */
export type QueryAirportsArgs = {
  airportIds: Array<Scalars['ID']>;
};

/**
 * 'Query' is a special type in GraphQL which denotes the root or start of a schema.
 * in this case `billing` takes in a list of `Id` (String), and outputs a list of `Billing`s.
 * this query is not part of the requirement, but is a useful test point for our code.
 */
export type QueryAirportsByCodeInArgs = {
  codes: Array<Scalars['String']>;
};

/**
 * 'Query' is a special type in GraphQL which denotes the root or start of a schema.
 * in this case `billing` takes in a list of `Id` (String), and outputs a list of `Billing`s.
 * this query is not part of the requirement, but is a useful test point for our code.
 */
export type QueryAirportsByFmsAirportIdsArgs = {
  fmsAirportIds: Array<Scalars['Long']>;
};

/**
 * 'Query' is a special type in GraphQL which denotes the root or start of a schema.
 * in this case `billing` takes in a list of `Id` (String), and outputs a list of `Billing`s.
 * this query is not part of the requirement, but is a useful test point for our code.
 */
export type QueryAirportsByGeoLocationArgs = {
  request: GeoLocationRequest;
};

/**
 * 'Query' is a special type in GraphQL which denotes the root or start of a schema.
 * in this case `billing` takes in a list of `Id` (String), and outputs a list of `Billing`s.
 * this query is not part of the requirement, but is a useful test point for our code.
 */
export type QueryAirportsBySearcherArgs = {
  request: AirportSearchRequest;
};

/**
 * 'Query' is a special type in GraphQL which denotes the root or start of a schema.
 * in this case `billing` takes in a list of `Id` (String), and outputs a list of `Billing`s.
 * this query is not part of the requirement, but is a useful test point for our code.
 */
export type QueryAvailabilityOfferRequestArgs = {
  offerRequestId: Scalars['ID'];
};

/**
 * 'Query' is a special type in GraphQL which denotes the root or start of a schema.
 * in this case `billing` takes in a list of `Id` (String), and outputs a list of `Billing`s.
 * this query is not part of the requirement, but is a useful test point for our code.
 */
export type QueryAvailabilityOfferRequestsArgs = {
  offerRequestIds: Array<Scalars['ID']>;
};

/**
 * 'Query' is a special type in GraphQL which denotes the root or start of a schema.
 * in this case `billing` takes in a list of `Id` (String), and outputs a list of `Billing`s.
 * this query is not part of the requirement, but is a useful test point for our code.
 */
export type QueryAvailabilityOfferRequestsByStatusAndDateRangeArgs = {
  endDate: Scalars['LocalDate'];
  pageable?: InputMaybe<CursorPageableInput>;
  startDate: Scalars['LocalDate'];
  status: Scalars['String'];
};

/**
 * 'Query' is a special type in GraphQL which denotes the root or start of a schema.
 * in this case `billing` takes in a list of `Id` (String), and outputs a list of `Billing`s.
 * this query is not part of the requirement, but is a useful test point for our code.
 */
export type QueryAvailabilityOfferUpdatePriceBreakDownArgs = {
  request: AvailabilityOfferBookingRequest;
};

/**
 * 'Query' is a special type in GraphQL which denotes the root or start of a schema.
 * in this case `billing` takes in a list of `Id` (String), and outputs a list of `Billing`s.
 * this query is not part of the requirement, but is a useful test point for our code.
 */
export type QueryBillingByIdArgs = {
  billingIds?: InputMaybe<Array<Scalars['ID']>>;
};

/**
 * 'Query' is a special type in GraphQL which denotes the root or start of a schema.
 * in this case `billing` takes in a list of `Id` (String), and outputs a list of `Billing`s.
 * this query is not part of the requirement, but is a useful test point for our code.
 */
export type QueryBillingForMemberPagedArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  memberId?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<Array<InputMaybe<BillingStatus>>>;
  type?: InputMaybe<Array<InputMaybe<PaymentMethod>>>;
};

/**
 * 'Query' is a special type in GraphQL which denotes the root or start of a schema.
 * in this case `billing` takes in a list of `Id` (String), and outputs a list of `Billing`s.
 * this query is not part of the requirement, but is a useful test point for our code.
 */
export type QueryCancelledFlightsArgs = {
  memberId?: InputMaybe<Scalars['ID']>;
  pageable?: InputMaybe<CursorPageableInput>;
};

/**
 * 'Query' is a special type in GraphQL which denotes the root or start of a schema.
 * in this case `billing` takes in a list of `Id` (String), and outputs a list of `Billing`s.
 * this query is not part of the requirement, but is a useful test point for our code.
 */
export type QueryClearancesByCountryCodeArgs = {
  countryCode: Scalars['String'];
};

/**
 * 'Query' is a special type in GraphQL which denotes the root or start of a schema.
 * in this case `billing` takes in a list of `Id` (String), and outputs a list of `Billing`s.
 * this query is not part of the requirement, but is a useful test point for our code.
 */
export type QueryClearancesByCountryCodesArgs = {
  countryCodes: Array<Scalars['String']>;
};

/**
 * 'Query' is a special type in GraphQL which denotes the root or start of a schema.
 * in this case `billing` takes in a list of `Id` (String), and outputs a list of `Billing`s.
 * this query is not part of the requirement, but is a useful test point for our code.
 */
export type QueryClientSingleSearchAlternateAirportOptionsArgs = {
  clientId: Scalars['String'];
};

/**
 * 'Query' is a special type in GraphQL which denotes the root or start of a schema.
 * in this case `billing` takes in a list of `Id` (String), and outputs a list of `Billing`s.
 * this query is not part of the requirement, but is a useful test point for our code.
 */
export type QueryCreditsBalanceByMemberArgs = {
  memberId?: InputMaybe<Scalars['ID']>;
};

/**
 * 'Query' is a special type in GraphQL which denotes the root or start of a schema.
 * in this case `billing` takes in a list of `Id` (String), and outputs a list of `Billing`s.
 * this query is not part of the requirement, but is a useful test point for our code.
 */
export type QueryFbosByIdInArgs = {
  fboIds: Array<Scalars['ID']>;
};

/**
 * 'Query' is a special type in GraphQL which denotes the root or start of a schema.
 * in this case `billing` takes in a list of `Id` (String), and outputs a list of `Billing`s.
 * this query is not part of the requirement, but is a useful test point for our code.
 */
export type QueryFlightArgs = {
  flightId: Scalars['ID'];
};

/**
 * 'Query' is a special type in GraphQL which denotes the root or start of a schema.
 * in this case `billing` takes in a list of `Id` (String), and outputs a list of `Billing`s.
 * this query is not part of the requirement, but is a useful test point for our code.
 */
export type QueryFlightsArgs = {
  flightIds: Array<Scalars['ID']>;
};

/**
 * 'Query' is a special type in GraphQL which denotes the root or start of a schema.
 * in this case `billing` takes in a list of `Id` (String), and outputs a list of `Billing`s.
 * this query is not part of the requirement, but is a useful test point for our code.
 */
export type QueryFlightsByDateRangeArgs = {
  endDate: Scalars['LocalDate'];
  pageable?: InputMaybe<CursorPageableInput>;
  startDate: Scalars['LocalDate'];
};

/**
 * 'Query' is a special type in GraphQL which denotes the root or start of a schema.
 * in this case `billing` takes in a list of `Id` (String), and outputs a list of `Billing`s.
 * this query is not part of the requirement, but is a useful test point for our code.
 */
export type QueryMarketingNewsByCategoryArgs = {
  category: MarketingNewsCategory;
  memberTypeCode: MemberTypeCode;
};

/**
 * 'Query' is a special type in GraphQL which denotes the root or start of a schema.
 * in this case `billing` takes in a list of `Id` (String), and outputs a list of `Billing`s.
 * this query is not part of the requirement, but is a useful test point for our code.
 */
export type QueryMemberArgs = {
  memberId: Scalars['ID'];
};

/**
 * 'Query' is a special type in GraphQL which denotes the root or start of a schema.
 * in this case `billing` takes in a list of `Id` (String), and outputs a list of `Billing`s.
 * this query is not part of the requirement, but is a useful test point for our code.
 */
export type QueryMemberAdvantageArgs = {
  memberId?: InputMaybe<Scalars['ID']>;
};

/**
 * 'Query' is a special type in GraphQL which denotes the root or start of a schema.
 * in this case `billing` takes in a list of `Id` (String), and outputs a list of `Billing`s.
 * this query is not part of the requirement, but is a useful test point for our code.
 */
export type QueryMemberByNumberArgs = {
  memberNumber: Scalars['Long'];
};

/**
 * 'Query' is a special type in GraphQL which denotes the root or start of a schema.
 * in this case `billing` takes in a list of `Id` (String), and outputs a list of `Billing`s.
 * this query is not part of the requirement, but is a useful test point for our code.
 */
export type QueryMembersArgs = {
  memberIds: Array<Scalars['ID']>;
};

/**
 * 'Query' is a special type in GraphQL which denotes the root or start of a schema.
 * in this case `billing` takes in a list of `Id` (String), and outputs a list of `Billing`s.
 * this query is not part of the requirement, but is a useful test point for our code.
 */
export type QueryMembersByNumbersArgs = {
  memberNumbers: Array<Scalars['Long']>;
};

/**
 * 'Query' is a special type in GraphQL which denotes the root or start of a schema.
 * in this case `billing` takes in a list of `Id` (String), and outputs a list of `Billing`s.
 * this query is not part of the requirement, but is a useful test point for our code.
 */
export type QueryPastFlightsArgs = {
  memberId?: InputMaybe<Scalars['ID']>;
  pageable?: InputMaybe<CursorPageableInput>;
};

/**
 * 'Query' is a special type in GraphQL which denotes the root or start of a schema.
 * in this case `billing` takes in a list of `Id` (String), and outputs a list of `Billing`s.
 * this query is not part of the requirement, but is a useful test point for our code.
 */
export type QueryPricingForDatesArgs = {
  flightCalendarRequest?: InputMaybe<FlightCalendarRequest>;
};

/**
 * 'Query' is a special type in GraphQL which denotes the root or start of a schema.
 * in this case `billing` takes in a list of `Id` (String), and outputs a list of `Billing`s.
 * this query is not part of the requirement, but is a useful test point for our code.
 */
export type QueryPwActiveAlertsArgs = {
  collectionOrGroupTypeName?: InputMaybe<Scalars['String']>;
  isCollection?: InputMaybe<Scalars['Boolean']>;
};

/**
 * 'Query' is a special type in GraphQL which denotes the root or start of a schema.
 * in this case `billing` takes in a list of `Id` (String), and outputs a list of `Billing`s.
 * this query is not part of the requirement, but is a useful test point for our code.
 */
export type QueryPwAirportsBySearchStringArgs = {
  searchCriteria: Scalars['String'];
};

/**
 * 'Query' is a special type in GraphQL which denotes the root or start of a schema.
 * in this case `billing` takes in a list of `Id` (String), and outputs a list of `Billing`s.
 * this query is not part of the requirement, but is a useful test point for our code.
 */
export type QueryPwNotificationAlertsByMemberIdArgs = {
  memberId: Scalars['ID'];
};

/**
 * 'Query' is a special type in GraphQL which denotes the root or start of a schema.
 * in this case `billing` takes in a list of `Id` (String), and outputs a list of `Billing`s.
 * this query is not part of the requirement, but is a useful test point for our code.
 */
export type QueryPwNotificationSettingsByUserIdArgs = {
  userId: Scalars['ID'];
};

/**
 * 'Query' is a special type in GraphQL which denotes the root or start of a schema.
 * in this case `billing` takes in a list of `Id` (String), and outputs a list of `Billing`s.
 * this query is not part of the requirement, but is a useful test point for our code.
 */
export type QueryPwTopRecentAirportsArgs = {
  numRecentAirports: Scalars['Int'];
  numSavedAirports: Scalars['Int'];
};

/**
 * 'Query' is a special type in GraphQL which denotes the root or start of a schema.
 * in this case `billing` takes in a list of `Id` (String), and outputs a list of `Billing`s.
 * this query is not part of the requirement, but is a useful test point for our code.
 */
export type QueryPwUpcomingFlightsArgs = {
  pageInfo?: InputMaybe<PwSpringPageInfo>;
};

/**
 * 'Query' is a special type in GraphQL which denotes the root or start of a schema.
 * in this case `billing` takes in a list of `Id` (String), and outputs a list of `Billing`s.
 * this query is not part of the requirement, but is a useful test point for our code.
 */
export type QueryReservationArgs = {
  reservationId: Scalars['ID'];
};

/**
 * 'Query' is a special type in GraphQL which denotes the root or start of a schema.
 * in this case `billing` takes in a list of `Id` (String), and outputs a list of `Billing`s.
 * this query is not part of the requirement, but is a useful test point for our code.
 */
export type QueryReservationByNumberArgs = {
  reservationNumber: Scalars['String'];
};

/**
 * 'Query' is a special type in GraphQL which denotes the root or start of a schema.
 * in this case `billing` takes in a list of `Id` (String), and outputs a list of `Billing`s.
 * this query is not part of the requirement, but is a useful test point for our code.
 */
export type QueryReservationByNumberInArgs = {
  reservationNumbers: Array<Scalars['String']>;
};

/**
 * 'Query' is a special type in GraphQL which denotes the root or start of a schema.
 * in this case `billing` takes in a list of `Id` (String), and outputs a list of `Billing`s.
 * this query is not part of the requirement, but is a useful test point for our code.
 */
export type QueryReservationsArgs = {
  reservationIds: Array<Scalars['ID']>;
};

/**
 * 'Query' is a special type in GraphQL which denotes the root or start of a schema.
 * in this case `billing` takes in a list of `Id` (String), and outputs a list of `Billing`s.
 * this query is not part of the requirement, but is a useful test point for our code.
 */
export type QuerySearchMembersArgs = {
  request: MemberSearchRequest;
};

/**
 * 'Query' is a special type in GraphQL which denotes the root or start of a schema.
 * in this case `billing` takes in a list of `Id` (String), and outputs a list of `Billing`s.
 * this query is not part of the requirement, but is a useful test point for our code.
 */
export type QueryStatementsForMemberArgs = {
  accountTypes?: InputMaybe<Array<AccountType>>;
};

/**
 * 'Query' is a special type in GraphQL which denotes the root or start of a schema.
 * in this case `billing` takes in a list of `Id` (String), and outputs a list of `Billing`s.
 * this query is not part of the requirement, but is a useful test point for our code.
 */
export type QueryTripSearchSummaryArgs = {
  request: TripSearchSummaryRequest;
};

/**
 * 'Query' is a special type in GraphQL which denotes the root or start of a schema.
 * in this case `billing` takes in a list of `Id` (String), and outputs a list of `Billing`s.
 * this query is not part of the requirement, but is a useful test point for our code.
 */
export type QueryTripSearchUpdatePriceBreakDownArgs = {
  request: TripBookingRequest;
};

/**
 * 'Query' is a special type in GraphQL which denotes the root or start of a schema.
 * in this case `billing` takes in a list of `Id` (String), and outputs a list of `Billing`s.
 * this query is not part of the requirement, but is a useful test point for our code.
 */
export type QueryUpcomingAvailabilityOfferRequestsArgs = {
  memberId?: InputMaybe<Scalars['ID']>;
  pageable?: InputMaybe<CursorPageableInput>;
};

/**
 * 'Query' is a special type in GraphQL which denotes the root or start of a schema.
 * in this case `billing` takes in a list of `Id` (String), and outputs a list of `Billing`s.
 * this query is not part of the requirement, but is a useful test point for our code.
 */
export type QueryUpcomingFlightsArgs = {
  memberId?: InputMaybe<Scalars['ID']>;
  pageable?: InputMaybe<CursorPageableInput>;
};

/**
 * 'Query' is a special type in GraphQL which denotes the root or start of a schema.
 * in this case `billing` takes in a list of `Id` (String), and outputs a list of `Billing`s.
 * this query is not part of the requirement, but is a useful test point for our code.
 */
export type QueryUpcomingReservationsArgs = {
  memberId?: InputMaybe<Scalars['ID']>;
  pageable?: InputMaybe<CursorPageableInput>;
};

/**
 * 'Query' is a special type in GraphQL which denotes the root or start of a schema.
 * in this case `billing` takes in a list of `Id` (String), and outputs a list of `Billing`s.
 * this query is not part of the requirement, but is a useful test point for our code.
 */
export type QueryWeatherForecastArgs = {
  request: Array<WeatherRequestInput>;
};

/**  A pricing scheme for a single search result that requires a quote to be requested. */
export type RfqSingleSearchPricing = {
  __typename?: 'RFQSingleSearchPricing';
  /**  Flag to determine whether to display Price Range or hardcoded text 'Custom Price', 'Request Quote', etc. */
  displayPriceRange: Scalars['Boolean'];
  /**  The maximum price that the user could be charged */
  maxPrice?: Maybe<Currency>;
  /**  The minimum price that the user will be charged */
  minPrice?: Maybe<Currency>;
};

export type RsBookingPaymentDetailsInput = {
  amount: CurrencyInput;
  backupCardId?: InputMaybe<Scalars['Long']>;
  id: Scalars['ID'];
  type?: InputMaybe<Scalars['String']>;
};

export type RsPriceBreakdown =
  | TripPriceBreakDown
  | TripRfqPriceBreakdown;

export type Reservation = {
  bookingMethod?: Maybe<Scalars['String']>;
  details: Array<ReservationDetails>;
  origination?: Maybe<Scalars['String']>;
  reservationId: Scalars['ID'];
  reservationNumber: Scalars['String'];
  segments: Array<Flight>;
  span: ReservationSpan;
  tripType: TripType;
};

export type ReservationBooker =
  | ContactReservationBooker
  | IndividualReservationBooker;

export type ReservationConnection = {
  __typename?: 'ReservationConnection';
  edges?: Maybe<Array<ReservationEdge>>;
  pageInfo: PageInfo;
};

export type ReservationDetails = {
  booker: ReservationBooker;
  bookingDate?: Maybe<Scalars['LocalDateTime']>;
  member: Member;
  paymentBreakdown?: Maybe<Array<TripPaymentBreakdown>>;
  priceBreakdown: Array<TripPriceBreakDown>;
  status?: Maybe<Array<ReservationStatus>>;
};

export type ReservationEdge = {
  __typename?: 'ReservationEdge';
  cursor: Scalars['String'];
  node: Reservation;
};

export type ReservationSpan = {
  __typename?: 'ReservationSpan';
  endDate?: Maybe<Scalars['LocalDate']>;
  startDate: Scalars['LocalDate'];
};

export type ReservationStatus = {
  __typename?: 'ReservationStatus';
  application: Scalars['String'];
  status: Scalars['String'];
};

export type SalesPerson = {
  __typename?: 'SalesPerson';
  department?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  salesPersonId: Scalars['ID'];
};

/**  When using single search, a SearchLeg holds information about a single leg of a potential flight. */
export type SearchLeg = {
  __typename?: 'SearchLeg';
  /**  The time that the flight is arriving, at the destination airport */
  arrivalDateTime: Scalars['LocalDateTime'];
  conditionalAirport?: Maybe<Scalars['Boolean']>;
  /**  The time that the flight is departing, from the origin airport */
  departureDateTime: Scalars['LocalDateTime'];
  /**  The distance between the origin and destination airports */
  distance: Distance;
  flexMinutes?: Maybe<Scalars['Int']>;
  /**  The time that the flight is departing */
  flightTimeInHours: Scalars['Float'];
  /**  The airport that the flight is departing from */
  fromAirport: Airport;
  /**  whether or not the leg is on a peak day */
  peakDay?: Maybe<Scalars['Boolean']>;
  /**
   * Indicates whether or not there may be a fuel stop on this leg
   * It does not guarantee that there will be a fuel stop, but that it is possible.
   */
  possibleFuelStop?: Maybe<Scalars['Boolean']>;
  /**  The number of extra destinations that the flight may stop at between the source and destination */
  stops?: Maybe<Scalars['Int']>;
  /**  The airport that the flight is arriving at */
  toAirport: Airport;
};

/**  When using single search, a SearchLeg holds information about a single leg of a potential flight. */
export type SearchLegDistanceArgs = {
  unit?: InputMaybe<DistanceUnit>;
};

/** # WUP Common Security Types */
export enum SecurityApplicationName {
  /** Atlas/BlueSky */
  Atlas = 'ATLAS',
  /** UP FMS */
  Fms = 'FMS',
  /** Members Site/Apps */
  WuMembers = 'WU_MEMBERS',
}

export type SharedFlight = Flight & {
  __typename?: 'SharedFlight';
  aircraftInfo: Array<FlightAircraftInformation>;
  arrivalDateTime?: Maybe<Array<FlightDateTime>>;
  billedTime: FlightDuration;
  /** Each of these are new fields that can be resolved by *this service* */
  billing?: Maybe<Array<Billing>>;
  cancellationDateTime?: Maybe<FlightDateTime>;
  departureDateTime?: Maybe<Array<FlightDateTime>>;
  destination: FlightAirportFbo;
  flightDate: Scalars['LocalDate'];
  flightId: Scalars['ID'];
  flightTime: FlightDuration;
  flightType: Scalars['String'];
  fmsFlight?: Maybe<FmsFlight>;
  /** @deprecated Use origin field instead */
  fromAirport?: Maybe<Airport>;
  isOnPeakDay?: Maybe<Scalars['Boolean']>;
  leg: Scalars['Int'];
  origin: FlightAirportFbo;
  proposedSeats?: Maybe<Scalars['Int']>;
  reservations: Array<SharedReservation>;
  status: Array<FlightStatus>;
  /** @deprecated Soon will be deprecated and provided by segments attribute */
  techStops?: Maybe<Array<TechnicalStop>>;
  /** @deprecated Use destination field instead */
  toAirport?: Maybe<Airport>;
  travellerGroups: Array<SharedFlightTravellerGroup>;
};

export enum SharedFlightGroupType {
  Joiner = 'JOINER',
  Proposer = 'PROPOSER',
}

export type SharedFlightTravellerGroup = FlightTravellerGroup & {
  __typename?: 'SharedFlightTravellerGroup';
  /**
   * Total number of available seats for this flight traveller group
   * For Proposer group, number of available seats = (Aircraft max. passenger) - (passengers.size + unknownPassengers) - (Shared Flight proposed seats)
   * For Joiner group, number of available seats = (Shared Flight proposed seats) - (passengers.size + unknownPassengers of all flight groups)
   */
  availableSeats?: Maybe<Scalars['Int']>;
  luggage?: Maybe<Array<FlightLuggage>>;
  passengers: Array<FlightPassenger>;
  status?: Maybe<Array<TravellerGroupStatus>>;
  type: SharedFlightGroupType;
  /**
   * Number of passengers with missing information
   * unknownPassengers = Number of seats - passengers.size
   */
  unknownPassengers?: Maybe<Scalars['Int']>;
};

export type SharedReservation = Reservation & {
  __typename?: 'SharedReservation';
  bookingMethod?: Maybe<Scalars['String']>;
  details: Array<SharedReservationDetails>;
  origination?: Maybe<Scalars['String']>;
  reservationId: Scalars['ID'];
  reservationNumber: Scalars['String'];
  segments: Array<SharedFlight>;
  span: ReservationSpan;
  tripType: TripType;
};

export type SharedReservationDetails = ReservationDetails & {
  __typename?: 'SharedReservationDetails';
  booker: ReservationBooker;
  bookingDate?: Maybe<Scalars['LocalDateTime']>;
  member: Member;
  paymentBreakdown?: Maybe<Array<TripPaymentBreakdown>>;
  priceBreakdown: Array<TripPriceBreakDown>;
  reservationType: SharedReservationType;
  status?: Maybe<Array<ReservationStatus>>;
};

export enum SharedReservationType {
  Joiner = 'JOINER',
  Proposer = 'PROPOSER',
}

export type ShuttleFlight = Flight & {
  __typename?: 'ShuttleFlight';
  aircraftInfo: Array<FlightAircraftInformation>;
  arrivalDateTime?: Maybe<Array<FlightDateTime>>;
  billedTime: FlightDuration;
  /** Each of these are new fields that can be resolved by *this service* */
  billing?: Maybe<Array<Billing>>;
  cancellationDateTime?: Maybe<FlightDateTime>;
  departureDateTime?: Maybe<Array<FlightDateTime>>;
  destination: FlightAirportFbo;
  flightDate: Scalars['LocalDate'];
  flightId: Scalars['ID'];
  flightTime: FlightDuration;
  flightType: Scalars['String'];
  fmsFlight?: Maybe<FmsFlight>;
  /** @deprecated Use origin field instead */
  fromAirport?: Maybe<Airport>;
  isOnPeakDay?: Maybe<Scalars['Boolean']>;
  leg: Scalars['Int'];
  origin: FlightAirportFbo;
  pricePerSeat: Currency;
  reservations: Array<ShuttleReservation>;
  /** Wheels Up provides the number of available seats for a shuttle flight */
  seatsOffered: Scalars['Int'];
  status: Array<FlightStatus>;
  /** @deprecated Soon will be deprecated and provided by segments attribute */
  techStops?: Maybe<Array<TechnicalStop>>;
  /** @deprecated Use destination field instead */
  toAirport?: Maybe<Airport>;
  travellerGroups: Array<ShuttleFlightTravellerGroup>;
};

export type ShuttleFlightTravellerGroup = FlightTravellerGroup & {
  __typename?: 'ShuttleFlightTravellerGroup';
  /**
   * Total number of available seats for this flight group
   * Number of available seats = (Shuttle Flight available seats) - (passengers.size + unknownPassengers of all flight groups)
   */
  availableSeats?: Maybe<Scalars['Int']>;
  luggage?: Maybe<Array<FlightLuggage>>;
  passengers: Array<FlightPassenger>;
  status?: Maybe<Array<TravellerGroupStatus>>;
  /** Number of passengers with missing information */
  unknownPassengers?: Maybe<Scalars['Int']>;
};

export type ShuttleReservation = Reservation & {
  __typename?: 'ShuttleReservation';
  bookingMethod?: Maybe<Scalars['String']>;
  details: Array<ShuttleReservationDetails>;
  origination?: Maybe<Scalars['String']>;
  reservationId: Scalars['ID'];
  reservationNumber: Scalars['String'];
  segments: Array<ShuttleFlight>;
  span: ReservationSpan;
  tripType: TripType;
};

export type ShuttleReservationDetails = ReservationDetails & {
  __typename?: 'ShuttleReservationDetails';
  booker: ReservationBooker;
  bookingDate?: Maybe<Scalars['LocalDateTime']>;
  member: Member;
  paymentBreakdown?: Maybe<Array<TripPaymentBreakdown>>;
  priceBreakdown: Array<TripPriceBreakDown>;
  status?: Maybe<Array<ReservationStatus>>;
};

export type SingleSearch = {
  __typename?: 'SingleSearch';
  /**  If this element is true, the trip is international, and members are subject to additional fees, and requirements. */
  internationalTrip?: Maybe<Scalars['Boolean']>;
  /**  Whether this result is part of a legacy program */
  legacyProgram?: Maybe<Scalars['Boolean']>;
  /**  A set of messages that should be displayed to the user */
  messages?: Maybe<Array<FlightSearchMessage>>;
  /**  If true, the price given is for a non-member (ie fly user) */
  nonMemberResponse?: Maybe<Scalars['Boolean']>;
  /**  will always be ClientSingleSearchResult for members site/apps */
  results?: Maybe<Array<SingleSearchResult>>;
  /**  A unique identifier for this search */
  searchId: Scalars['String'];
  /**  The time that the result become unbookable at. */
  searchResultsExpiresAt?: Maybe<Scalars['LocalDateTime']>;
  /**  If true, the result should not be displayed to the user */
  showNoResultsOverride: Scalars['Boolean'];
  /**  An optional description to display when showNoResultsOverride is true */
  showNoResultsOverrideDescription?: Maybe<
    Scalars['String']
  >;
};

export type SingleSearchAircraftDetails = {
  __typename?: 'SingleSearchAircraftDetails';
  clientId: Scalars['String'];
  displayDetails: AircraftDisplayDetails;
  resultId: Scalars['String'];
};

/**  Represents whether the aircraft is on or off the wheels up fleet. */
export enum SingleSearchAircraftSourcingType {
  OffFleet = 'OFF_FLEET',
  OnFleet = 'ON_FLEET',
}

/**  An internal type used to track audit log messages for a single search. */
export type SingleSearchAuditLogMessage = {
  __typename?: 'SingleSearchAuditLogMessage';
  dateTimeStamp?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  messageType?: Maybe<Scalars['String']>;
};

export type SingleSearchDepartureReplacement = {
  __typename?: 'SingleSearchDepartureReplacement';
  /**  The time difference between the original and replacement departure times */
  suggestionTimeFromOriginalDeparture: DateGrid;
};

export type SingleSearchDestinationReplacement = {
  __typename?: 'SingleSearchDestinationReplacement';
  /**  The distance between the destination and replacement airports */
  suggestionDistanceFromDestinationAirport: Distance;
};

export type SingleSearchFlightFees = {
  __typename?: 'SingleSearchFlightFees';
  /**  The amount of the fee in a specific instance */
  feeAmount: Currency;
  /**  A long description of the fee */
  feeDescription?: Maybe<Scalars['String']>;
  /**  A short header of the fee */
  feeType?: Maybe<Scalars['String']>;
};

export type SingleSearchMemberUpsellBlock = {
  __typename?: 'SingleSearchMemberUpsellBlock';
  asteriskCopy?: Maybe<Scalars['String']>;
  copy: Scalars['String'];
};

/**  A type of tag that can be applied to search results when searching for flights. */
export type SingleSearchOfferTag = {
  __typename?: 'SingleSearchOfferTag';
  /**  The background color of the tag. If null, the color should be be inherited from the parent. */
  backgroundColor?: Maybe<Scalars['String']>;
  /**  If an icon may be displayed with the tag, this is the URL for that icon. */
  iconUrl?: Maybe<Scalars['String']>;
  /**  The order that the tag should be displayed in, lowest being first. */
  sortOrder?: Maybe<Scalars['Int']>;
  /**  The user text to display for the tag. */
  text?: Maybe<Scalars['String']>;
  /**  The color of the text for the tag. If null, the color should be be inherited from the parent. */
  textColor?: Maybe<Scalars['String']>;
};

export type SingleSearchOriginReplacement = {
  __typename?: 'SingleSearchOriginReplacement';
  /**  The distance between the original and replacement airports */
  suggestionDistanceFromOriginAirport: Distance;
};

/**  A pricing scheme for a single search result. */
export type SingleSearchPricingScheme =
  | InstantBookSingleSearchPricing
  | RfqSingleSearchPricing;

export type SingleSearchReplacement = {
  __typename?: 'SingleSearchReplacement';
  /**  The leg that was changed. */
  leg: Scalars['Int'];
  /**  The type of replacement that was made. */
  replacementMetadata: Array<SingleSearchReplacementDescription>;
};

export type SingleSearchReplacementDescription =
  | SingleSearchDepartureReplacement
  | SingleSearchDestinationReplacement
  | SingleSearchOriginReplacement;

/**
 * This interface is used to represent a search result that comes from a single search.
 * At its core, it is a potential flight that can be booked, and expires after a certain amount of time.
 */
export type SingleSearchResult = {
  /**  The aircraft information for this result */
  aircraftDetails: FsSingleSearchAircraftDetails;
  /**
   * Whether the aircraft is on or off the wheels up fleet.
   * Depending on the source, the aircraft may be available for instant booking.
   */
  aircraftSource: SingleSearchAircraftSourcingType;
  /**  Populated for alternate option result */
  alternateOptionMetadata?: Maybe<AlternateOptionMetadata>;
  categoryResult: Scalars['Boolean'];
  /**  An optional URL for a cover image that *may* be displayed to the user, depending on the client. */
  coverImageURL?: Maybe<Scalars['String']>;
  /**  needed to view feasible messages if false */
  feasible: Scalars['Boolean'];
  /**  The sum of the flight hours of the individual legs */
  flightTimeInHours: Scalars['Float'];
  /**  A classification of the type of trip that this result represents based on how many legs it has. */
  flightTripType?: Maybe<FlightTripType>;
  /**  If true, the result can be booked instantly, otherwise the user will need to request a quote, or contact support. */
  instantBook: Scalars['Boolean'];
  /**  A list of stops and information about the stops */
  legs: Array<SearchLeg>;
  /**  Info on the pricing for this result */
  price: SingleSearchPricingScheme;
  /**  the result ID needed to move onto the 'Your Trip' booking page. */
  resultId: Scalars['ID'];
  /**  The order that the results should be sorted into, lowest being first. */
  sortOrder: Scalars['Int'];
  /**
   * The number of extra destinations that the flight may stop at between the source and destination
   * Will be equal to the sum of the stops of the individual legs
   */
  stops?: Maybe<Scalars['Int']>;
};

/**
 * A statement document is a single statement for a given month.
 * It contains a list of items, which are the individual charges and credits for that month.
 */
export type StatementDocument = {
  __typename?: 'StatementDocument';
  /**  The items in this statement */
  documentItems?: Maybe<Array<MemberStatementDocument>>;
  /**  The month for which this statement is for */
  statementMonth: Scalars['ISO8601Date'];
};

export type StatementList = {
  __typename?: 'StatementList';
  /**  The end date for the statement range */
  endDate: Scalars['ISO8601Date'];
  /**  The start date for the statement range */
  startDate: Scalars['ISO8601Date'];
  /**  The contents of the statement */
  statements?: Maybe<Array<StatementDocument>>;
};

export type TechnicalStop = {
  __typename?: 'TechnicalStop';
  endDateTime?: Maybe<Array<FlightDateTime>>;
  flightStopId: Scalars['ID'];
  startDateTime?: Maybe<Array<FlightDateTime>>;
  stopLocation: FlightAirportFbo;
  stopType?: Maybe<Scalars['String']>;
};

export enum TemperatureUnit {
  Celsius = 'CELSIUS',
  Fahrenheit = 'FAHRENHEIT',
}

/**  A tooltip is a small piece of text that can be displayed to the user to provide more information. */
export type ToolTip = {
  __typename?: 'ToolTip';
  description?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type TravellerGroupStatus = {
  __typename?: 'TravellerGroupStatus';
  application: Scalars['String'];
  status: Scalars['String'];
};

export type TripAccountingSummary = {
  __typename?: 'TripAccountingSummary';
  billings?: Maybe<Array<TripBillingItem>>;
  payments?: Maybe<Array<TripPaymentItem>>;
  pricings?: Maybe<Array<TripPricingItem>>;
};

export enum TripBillingCategory {
  Credit = 'CREDIT',
  Discount = 'DISCOUNT',
  Fee = 'FEE',
  Fund = 'FUND',
  Subtotal = 'SUBTOTAL',
  Total = 'TOTAL',
}

export type TripBillingItem = {
  __typename?: 'TripBillingItem';
  amount?: Maybe<Currency>;
  category?: Maybe<TripBillingCategory>;
  code?: Maybe<Scalars['String']>;
  flight?: Maybe<Flight>;
  label?: Maybe<Scalars['String']>;
  memberSaving?: Maybe<Scalars['Boolean']>;
  metadata?: Maybe<TripBillingMetadata>;
};

export type TripBillingMetadata = {
  __typename?: 'TripBillingMetadata';
  aircraft?: Maybe<Aircraft>;
  airport?: Maybe<Airport>;
  amount?: Maybe<Currency>;
  category?: Maybe<BillingMetadataCategory>;
  fbo?: Maybe<Fbo>;
  isPercentage?: Maybe<Scalars['Boolean']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  wuProcessingFeeType?: Maybe<Scalars['String']>;
};

export type TripBookingFlightPassengerInput = {
  email?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  isLead?: InputMaybe<Scalars['Boolean']>;
  isPet?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  passengerId: Scalars['ID'];
  phone?: InputMaybe<Scalars['String']>;
};

export type TripBookingRequest = {
  clientId: Scalars['String'];
  legDetails: Array<TripBookingRequestLegDetail>;
  /** Required for booking but not for calculating price breakdown */
  paymentDetails?: InputMaybe<
    Array<RsBookingPaymentDetailsInput>
  >;
  resultId: Scalars['String'];
};

export type TripBookingRequestLegDetail = {
  luggage?: InputMaybe<Array<FlightLuggageInput>>;
  passengers: Array<TripBookingFlightPassengerInput>;
  requestLegId: Scalars['String'];
};

export type TripPaymentBreakdown = {
  __typename?: 'TripPaymentBreakdown';
  code: Scalars['String'];
  label: Scalars['String'];
  metadata?: Maybe<Scalars['Object']>;
  sortOrder?: Maybe<Scalars['Int']>;
  value: Currency;
};

/**
 * Tells the type of a trip payment.
 * Is null if the payment is not yet paid, or is in the PENDING state.
 */
export type TripPaymentDetail =
  | Account
  | AlternatePaymentItem
  | PaymentCard;

export type TripPaymentItem = {
  __typename?: 'TripPaymentItem';
  amount?: Maybe<Currency>;
  details?: Maybe<TripPaymentDetail>;
  invoices: Array<Invoice>;
  label?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type TripPriceBreakDown = {
  __typename?: 'TripPriceBreakDown';
  audits?: Maybe<Array<TripPriceBreakdownAudit>>;
  category: Scalars['String'];
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  metadata?: Maybe<Array<TripPriceBreakdownMetaData>>;
  sortOrder: Scalars['Int'];
  value: Currency;
};

export type TripPriceBreakdownAudit = {
  __typename?: 'TripPriceBreakdownAudit';
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  metadata?: Maybe<Array<TripPriceBreakdownMetaData>>;
  sortOrder: Scalars['Int'];
  value: Scalars['Object'];
};

export type TripPriceBreakdownMetaData = {
  __typename?: 'TripPriceBreakdownMetaData';
  key: Scalars['String'];
  value?: Maybe<Scalars['Object']>;
};

export enum TripPricingCode {
  MarketPrice = 'MARKET_PRICE',
  Saving = 'SAVING',
}

export type TripPricingItem = {
  __typename?: 'TripPricingItem';
  amount?: Maybe<Currency>;
  code?: Maybe<TripPricingCode>;
  description?: Maybe<Scalars['String']>;
  item?: Maybe<Scalars['String']>;
};

export type TripRfqPriceBreakdown = {
  __typename?: 'TripRFQPriceBreakdown';
  category: Scalars['String'];
  displayPriceRange: Scalars['Boolean'];
  label: Scalars['String'];
  maxPrice?: Maybe<Currency>;
  minPrice?: Maybe<Currency>;
  sortOrder: Scalars['Int'];
};

export type TripSearchLegSummary = {
  __typename?: 'TripSearchLegSummary';
  arrivalDateTime: FlightDateTime;
  billableHours: Scalars['Float'];
  conditionalAirport: Scalars['Boolean'];
  departureDateTime: FlightDateTime;
  destination: Airport;
  flightTime: FlightDuration;
  legPrice: Currency;
  luggage?: Maybe<Array<TripSearchLuggage>>;
  numberOfPassengers: Scalars['Int'];
  numberOfPets?: Maybe<Scalars['Int']>;
  origin: Airport;
  passengerCountDetails?: Maybe<PassengerCountDetails>;
  peakDay: Scalars['Boolean'];
  possibleFuelStop: Scalars['Boolean'];
  requestLegId: Scalars['String'];
  sortOrder: Scalars['Int'];
  stops: Scalars['Int'];
};

export type TripSearchLuggage = {
  __typename?: 'TripSearchLuggage';
  count?: Maybe<Scalars['Int']>;
  luggageType: LuggageType;
};

export type TripSearchSummary = {
  __typename?: 'TripSearchSummary';
  aircraftInfo: AircraftInformation;
  clientId: Scalars['String'];
  instantBook: Scalars['Boolean'];
  legs: Array<TripSearchLegSummary>;
  /** This flag is needed because instantBook flag is being overridden by feasible flag for Front end. */
  originalInstantBook: Scalars['Boolean'];
  priceBreakdown: Array<RsPriceBreakdown>;
  resultId: Scalars['String'];
};

export type TripSearchSummaryRequest = {
  clientId: Scalars['String'];
  resultId: Scalars['String'];
};

export enum TripType {
  MultiCity = 'MULTI_CITY',
  OneWay = 'ONE_WAY',
  RoundTrip = 'ROUND_TRIP',
}

export type WuMemberAddress = {
  __typename?: 'WUMemberAddress';
  wuMemberAddressId: Scalars['ID'];
};

export type Weather = {
  __typename?: 'Weather';
  condition: ConditionCode;
  temperatureMax: Scalars['Float'];
  temperatureMin: Scalars['Float'];
  unit: TemperatureUnit;
};

export type WeatherOutput = {
  __typename?: 'WeatherOutput';
  /** Request object that was used to fetch the weather */
  request: Scalars['Object'];
  response?: Maybe<Weather>;
};

export type WeatherRequestInput = {
  /** Either airport code is required or latitude and longitude is required */
  airportCode?: InputMaybe<Scalars['String']>;
  /** Required if using latitude and longitude */
  ianaTimeZone?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  /** Either airport code is required or latitude and longitude is required */
  latitude?: InputMaybe<Scalars['Float']>;
  /** Either airport code is required or latitude and longitude is required */
  longitude?: InputMaybe<Scalars['Float']>;
  weatherDate: Scalars['LocalDateTime'];
};

export enum DocumentType {
  Credits = 'CREDITS',
  Funds = 'FUNDS',
  Other = 'OTHER',
}

export type GetPagedBillingQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<
    | Array<InputMaybe<BillingStatus>>
    | InputMaybe<BillingStatus>
  >;
  type?: InputMaybe<
    | Array<InputMaybe<PaymentMethod>>
    | InputMaybe<PaymentMethod>
  >;
}>;

export type GetPagedBillingQuery = {
  __typename?: 'Query';
  billingForMemberPaged: {
    __typename?: 'BillingConnection';
    totalCount: number;
    pageInfo: {
      __typename?: 'PageInfo';
      startCursor?: string | null;
      endCursor?: string | null;
      hasNextPage: boolean;
    };
    edges: Array<{
      __typename?: 'BillingEdge';
      node: {
        __typename?: 'Billing';
        billingId: string;
        methodLabel?: string | null;
        status?: BillingStatus | null;
        createDate?: any | null;
        detail?:
          | { __typename: 'FlightCateringOrderResults' }
          | { __typename: 'GroundTransportResults' }
          | { __typename: 'TripAccountingSummary' }
          | null;
        flight?:
          | {
              __typename?: 'PrivateFlight';
              fromAirport?: {
                __typename?: 'Airport';
                code: string;
              } | null;
              toAirport?: {
                __typename?: 'Airport';
                code: string;
              } | null;
            }
          | {
              __typename?: 'SharedFlight';
              fromAirport?: {
                __typename?: 'Airport';
                code: string;
              } | null;
              toAirport?: {
                __typename?: 'Airport';
                code: string;
              } | null;
            }
          | {
              __typename?: 'ShuttleFlight';
              fromAirport?: {
                __typename?: 'Airport';
                code: string;
              } | null;
              toAirport?: {
                __typename?: 'Airport';
                code: string;
              } | null;
            }
          | null;
        amount?: {
          __typename?: 'Currency';
          amount: number;
          symbol: string;
          unit: string;
        } | null;
        reservation?:
          | {
              __typename?: 'PrivateReservation';
              reservationNumber: string;
            }
          | {
              __typename?: 'SharedReservation';
              reservationNumber: string;
            }
          | {
              __typename?: 'ShuttleReservation';
              reservationNumber: string;
            }
          | null;
      };
    }>;
  };
};

export type GetStatementsQueryVariables = Exact<{
  accountTypes?: InputMaybe<
    Array<AccountType> | AccountType
  >;
}>;

export type GetStatementsQuery = {
  __typename?: 'Query';
  statementsForMember?: {
    __typename?: 'StatementList';
    statements?: Array<{
      __typename?: 'StatementDocument';
      statementMonth: any;
      documentItems?: Array<{
        __typename?: 'MemberStatementDocument';
        documentType: DocumentType;
        fileName: string;
        memberStatementDocumentId: string;
        downloadUrl: string;
        account?: {
          __typename?: 'Account';
          accountId: string;
          number: string;
        } | null;
        credit?: {
          __typename?: 'Credit';
          creditId: string;
          number: string;
        } | null;
      }> | null;
    }> | null;
  } | null;
  accountsForMember?: Array<{
    __typename?: 'Account';
    accountId: string;
    number: string;
    accountType: AccountType;
    startDate: any;
    balances: {
      __typename?: 'AccountBalance';
      currentBalance: {
        __typename?: 'Currency';
        amount: number;
        unit: string;
        symbol: string;
      };
      projectedBalance: {
        __typename?: 'Currency';
        amount: number;
        unit: string;
        symbol: string;
      };
    };
  }> | null;
  creditsForMember?: Array<{
    __typename?: 'Credit';
    creditId: string;
    number: string;
    description: string;
    enabled: boolean;
  }> | null;
};

export type GetStatementsV2QueryVariables = Exact<{
  accountTypes?: InputMaybe<
    Array<AccountType> | AccountType
  >;
}>;

export type GetStatementsV2Query = {
  __typename?: 'Query';
  statementsForMember?: {
    __typename?: 'StatementList';
    statements?: Array<{
      __typename?: 'StatementDocument';
      statementMonth: any;
      documentItems?: Array<{
        __typename?: 'MemberStatementDocument';
        documentType: DocumentType;
        fileName: string;
        memberStatementDocumentId: string;
        downloadUrl: string;
        account?: {
          __typename?: 'Account';
          accountId: string;
          number: string;
        } | null;
        credit?: {
          __typename?: 'Credit';
          creditId: string;
          number: string;
        } | null;
      }> | null;
    }> | null;
  } | null;
  accountsForMember?: Array<{
    __typename?: 'Account';
    name: string;
    accountId: string;
    number: string;
    accountType: AccountType;
    startDate: any;
    guaranteeExpirationDate?: any | null;
    forfeitDate?: any | null;
    balances: {
      __typename?: 'AccountBalance';
      currentBalance: {
        __typename?: 'Currency';
        amount: number;
        unit: string;
        symbol: string;
      };
      projectedBalance: {
        __typename?: 'Currency';
        amount: number;
        unit: string;
        symbol: string;
      };
    };
  }> | null;
  creditsForMember?: Array<{
    __typename?: 'Credit';
    creditId: string;
    number: string;
    description: string;
    enabled: boolean;
  }> | null;
};

export type BillingByIdQueryVariables = Exact<{
  billingIds?: InputMaybe<
    Array<Scalars['ID']> | Scalars['ID']
  >;
}>;

export type BillingByIdQuery = {
  __typename?: 'Query';
  billingById?: Array<{
    __typename?: 'Billing';
    billingId: string;
    createDate?: any | null;
    method?: PaymentMethod | null;
    methodLabel?: string | null;
    status?: BillingStatus | null;
    detail?:
      | {
          __typename?: 'FlightCateringOrderResults';
          results: Array<{
            __typename?: 'FlightCateringOrder';
            flightCateringOrderId: string;
          }>;
        }
      | {
          __typename?: 'GroundTransportResults';
          results: Array<{
            __typename?: 'GroundTransport';
            category?: string | null;
            groundTransportId: string;
            groundTransportType?: {
              __typename?: 'GroundTransportType';
              groundTransportTypeId: string;
            } | null;
          }>;
        }
      | {
          __typename?: 'TripAccountingSummary';
          billings?: Array<{
            __typename?: 'TripBillingItem';
            category?: TripBillingCategory | null;
            code?: string | null;
            label?: string | null;
            amount?: {
              __typename?: 'Currency';
              unit: string;
              symbol: string;
              amount: number;
            } | null;
          }> | null;
          payments?: Array<{
            __typename?: 'TripPaymentItem';
            label?: string | null;
            name?: string | null;
            amount?: {
              __typename?: 'Currency';
              unit: string;
              symbol: string;
              amount: number;
            } | null;
            invoices: Array<{
              __typename?: 'Invoice';
              invoiceId: string;
            }>;
          }> | null;
        }
      | null;
    account?: {
      __typename?: 'Account';
      accountId: string;
    } | null;
    amount?: {
      __typename?: 'Currency';
      amount: number;
      symbol: string;
      unit: string;
    } | null;
    flight?:
      | {
          __typename?: 'PrivateFlight';
          flightId: string;
          billing?: Array<{
            __typename?: 'Billing';
            billingId: string;
            createDate?: any | null;
            method?: PaymentMethod | null;
            methodLabel?: string | null;
            status?: BillingStatus | null;
          }> | null;
          fromAirport?: {
            __typename?: 'Airport';
            code: string;
          } | null;
          toAirport?: {
            __typename?: 'Airport';
            code: string;
          } | null;
        }
      | {
          __typename?: 'SharedFlight';
          flightId: string;
          billing?: Array<{
            __typename?: 'Billing';
            billingId: string;
            createDate?: any | null;
            method?: PaymentMethod | null;
            methodLabel?: string | null;
            status?: BillingStatus | null;
          }> | null;
          fromAirport?: {
            __typename?: 'Airport';
            code: string;
          } | null;
          toAirport?: {
            __typename?: 'Airport';
            code: string;
          } | null;
        }
      | {
          __typename?: 'ShuttleFlight';
          flightId: string;
          billing?: Array<{
            __typename?: 'Billing';
            billingId: string;
            createDate?: any | null;
            method?: PaymentMethod | null;
            methodLabel?: string | null;
            status?: BillingStatus | null;
          }> | null;
          fromAirport?: {
            __typename?: 'Airport';
            code: string;
          } | null;
          toAirport?: {
            __typename?: 'Airport';
            code: string;
          } | null;
        }
      | null;
    invoice?: {
      __typename?: 'Invoice';
      invoiceId: string;
    } | null;
    reservation?:
      | {
          __typename?: 'PrivateReservation';
          reservationId: string;
        }
      | {
          __typename?: 'SharedReservation';
          reservationId: string;
        }
      | {
          __typename?: 'ShuttleReservation';
          reservationId: string;
        }
      | null;
  }> | null;
};

export type GetPricingForDatesQueryVariables = Exact<{
  flightCalendarRequest: FlightCalendarRequest;
}>;

export type GetPricingForDatesQuery = {
  __typename?: 'Query';
  pricingForDates: Array<{
    __typename?: 'DatePrice';
    date: any;
    price?: {
      __typename?: 'Currency';
      amount: number;
      symbol: string;
      unit: string;
    } | null;
  }>;
};

export type GetMemberAdvantageQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetMemberAdvantageQuery = {
  __typename?: 'Query';
  memberAdvantage?:
    | {
        __typename: 'MemberLoyalty';
        earnings?: Array<
          | {
              __typename?: 'MemberEarningCredit';
              code: string;
              spendingTarget: {
                __typename?: 'Currency';
                amount: number;
                unit: string;
                symbol: string;
              };
              accruedExpenses: {
                __typename?: 'Currency';
                amount: number;
                unit: string;
                symbol: string;
              };
            }
          | {
              __typename?: 'MemberEarningMedallion';
              code: string;
              earnedMedallion?: {
                __typename?: 'MemberEarningMedallionEntity';
                name: string;
                pendingApplication: boolean;
              } | null;
              spendingTarget: {
                __typename?: 'Currency';
                amount: number;
                unit: string;
                symbol: string;
              };
              accruedExpenses: {
                __typename?: 'Currency';
                amount: number;
                unit: string;
                symbol: string;
              };
            }
        > | null;
      }
    | { __typename: 'MemberNoAdvantage'; _?: string | null }
    | {
        __typename: 'MemberSaving';
        year: number;
        flightsWithoutSurgeProtection: {
          __typename?: 'Percentage';
          value: number;
          formatted: string;
        };
        flightsWithSurgeProtection: {
          __typename?: 'Percentage';
          value: number;
          formatted: string;
        };
        totalSavings: {
          __typename?: 'Currency';
          amount: number;
          unit: string;
          symbol: string;
        };
      }
    | null;
};

export type GetMemberBalanceQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetMemberBalanceQuery = {
  __typename?: 'Query';
  creditsBalanceByMember?: {
    __typename?: 'Currency';
    amount: number;
    symbol: string;
    unit: string;
  } | null;
};

export type ApplyEarnedMedallionMutationVariables = Exact<{
  [key: string]: never;
}>;

export type ApplyEarnedMedallionMutation = {
  __typename?: 'Mutation';
  applyEarnedMedallion?: {
    __typename?: 'MemberEarningMedallion';
    code: string;
    spendingTarget: {
      __typename?: 'Currency';
      amount: number;
      unit: string;
      symbol: string;
    };
    accruedExpenses: {
      __typename?: 'Currency';
      amount: number;
      unit: string;
      symbol: string;
    };
    earnedMedallion?: {
      __typename?: 'MemberEarningMedallionEntity';
      name: string;
      pendingApplication: boolean;
    } | null;
  } | null;
};

export type GetAlternateAirportsQueryVariables = Exact<{
  clientId: Scalars['String'];
}>;

export type GetAlternateAirportsQuery = {
  __typename?: 'Query';
  clientSingleSearchAlternateAirportOptions: {
    __typename?: 'SingleSearch';
    searchId: string;
    nonMemberResponse?: boolean | null;
    messages?: Array<{
      __typename?: 'FlightSearchMessage';
      message?: string | null;
    }> | null;
    results?: Array<{
      __typename?: 'ClientSingleSearchResult';
      feasible: boolean;
      flightTimeInHours: number;
      flightTripType: FlightTripType;
      resultId: string;
      instantBook: boolean;
      legs: Array<{
        __typename?: 'SearchLeg';
        possibleFuelStop?: boolean | null;
        flightTimeInHours: number;
        departureDateTime: any;
        toAirport: {
          __typename?: 'Airport';
          name: string;
          displayCode: string;
          displayName: string;
          address: {
            __typename?: 'ADSAddress';
            city?: string | null;
            country: string;
            stateCode?: string | null;
            countryISOCode2?: string | null;
            countryISOCode3?: string | null;
          };
        };
        fromAirport: {
          __typename?: 'Airport';
          name: string;
          displayCode: string;
          displayName: string;
          address: {
            __typename?: 'ADSAddress';
            city?: string | null;
            stateCode?: string | null;
            countryISOCode2?: string | null;
            countryISOCode3?: string | null;
          };
        };
      }>;
      alternateOptionMetadata?: {
        __typename?: 'AlternateOptionMetadata';
        description:
          | {
              __typename: 'AlternateSearchOptionCustoms';
              _?: string | null;
            }
          | {
              __typename: 'AlternateSearchOptionSavings';
              savingsPercentile: number;
            };
        replaced: Array<{
          __typename?: 'SingleSearchReplacement';
          leg: number;
          replacementMetadata: Array<
            | {
                __typename: 'SingleSearchDepartureReplacement';
              }
            | {
                __typename: 'SingleSearchDestinationReplacement';
                suggestionDistanceFromDestinationAirport: {
                  __typename?: 'Distance';
                  unit: DistanceUnit;
                  value: number;
                };
              }
            | {
                __typename: 'SingleSearchOriginReplacement';
                suggestionDistanceFromOriginAirport: {
                  __typename?: 'Distance';
                  unit: DistanceUnit;
                  value: number;
                };
              }
          >;
        }>;
      } | null;
      price:
        | {
            __typename: 'InstantBookSingleSearchPricing';
            crossOutPrice?: {
              __typename?: 'Currency';
              amount: number;
              symbol: string;
              unit: string;
            } | null;
            displayPrice: {
              __typename?: 'Currency';
              amount: number;
              symbol: string;
              unit: string;
            };
          }
        | {
            __typename: 'RFQSingleSearchPricing';
            displayPriceRange: boolean;
            maxPrice?: {
              __typename?: 'Currency';
              amount: number;
              symbol: string;
              unit: string;
            } | null;
            minPrice?: {
              __typename?: 'Currency';
              amount: number;
              symbol: string;
              unit: string;
            } | null;
          };
      aircraftDetails: {
        __typename?: 'FSSingleSearchAircraftDetails';
        aircraftCategory: string;
        aircraftType: string;
        view: {
          __typename?: 'FSSSADView';
          displayHeadline: string;
          displayDescription: string;
        };
      };
    }> | null;
  };
};

export type GetUpcomingAirportsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetUpcomingAirportsQuery = {
  __typename?: 'Query';
  pwUpcomingFlights: Array<{
    __typename?: 'PwFlightSummary';
    flight:
      | {
          __typename?: 'PrivateFlight';
          flightId: string;
          reservation: {
            __typename?: 'PrivateReservation';
            reservationId: string;
          };
          fromAirport?: {
            __typename?: 'Airport';
            displayCode: string;
          } | null;
          toAirport?: {
            __typename?: 'Airport';
            displayCode: string;
          } | null;
        }
      | {
          __typename?: 'SharedFlight';
          flightId: string;
          fromAirport?: {
            __typename?: 'Airport';
            displayCode: string;
          } | null;
          toAirport?: {
            __typename?: 'Airport';
            displayCode: string;
          } | null;
        }
      | {
          __typename?: 'ShuttleFlight';
          flightId: string;
          fromAirport?: {
            __typename?: 'Airport';
            displayCode: string;
          } | null;
          toAirport?: {
            __typename?: 'Airport';
            displayCode: string;
          } | null;
        };
  }>;
};

export type GetMarketingNewsByCategoryQueryVariables = Exact<{
  memberTypeCode: MemberTypeCode;
  category: MarketingNewsCategory;
}>;

export type GetMarketingNewsByCategoryQuery = {
  __typename?: 'Query';
  marketingNewsByCategory?: Array<{
    __typename?: 'MarketingNews';
    heading: string;
    body: string;
    cta: string;
    imageUrl: string;
    subheading: string;
    newsPage: {
      __typename?: 'NewsPage';
      newsPageId: string;
    };
  }> | null;
};

export type GetUpcomingFlightsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetUpcomingFlightsQuery = {
  __typename?: 'Query';
  upcomingFlights?: {
    __typename?: 'FlightConnection';
    edges?: Array<{
      __typename?: 'FlightEdge';
      node:
        | {
            __typename?: 'PrivateFlight';
            flightId: string;
            leg: number;
            flightType: string;
            reservation: {
              __typename?: 'PrivateReservation';
              reservationId: string;
              reservationNumber: string;
              tripType: TripType;
              segments: Array<{
                __typename?: 'PrivateFlight';
                aircraftInfo: Array<{
                  __typename?: 'FlightAircraftInformation';
                  aircraft:
                    | {
                        __typename?: 'Aircraft';
                        tailNumber: string;
                        aircraftType?: {
                          __typename?: 'AircraftType';
                          displayDetails: {
                            __typename?: 'AircraftDisplayDetails';
                            category?: string | null;
                            imageUrl?: string | null;
                          };
                        } | null;
                      }
                    | {
                        __typename?: 'AircraftCabin';
                        name?: string | null;
                        images?: Array<{
                          __typename?: 'AircraftImage';
                          photoUrl: string;
                        }> | null;
                      }
                    | { __typename?: 'AircraftType' }
                    | {
                        __typename?: 'SingleSearchAircraftDetails';
                      };
                }>;
                travellerGroup: {
                  __typename?: 'PrivateFlightTravellerGroup';
                  passengers: Array<{
                    __typename?: 'FlightPassenger';
                    flightPassengerId: string;
                  }>;
                };
              }>;
              span: {
                __typename?: 'ReservationSpan';
                startDate: any;
                endDate?: any | null;
              };
            };
            travellerGroup: {
              __typename?: 'PrivateFlightTravellerGroup';
              availableSeats?: number | null;
              unknownPassengers?: number | null;
              passengers: Array<{
                __typename?: 'FlightPassenger';
                flightPassengerId: string;
              }>;
            };
            destination: {
              __typename?: 'FlightAirportFBO';
              airport: {
                __typename?: 'Airport';
                ianaTimeZone: string;
                displayName: string;
                displayCode: string;
                name: string;
                address: {
                  __typename?: 'ADSAddress';
                  stateCode?: string | null;
                };
              };
              fbo?: {
                __typename?: 'FBO';
                displayName: string;
                telephone?: string | null;
                address?: {
                  __typename?: 'ADSAddress';
                  city?: string | null;
                  country: string;
                  streetLine1?: string | null;
                  streetLine2?: string | null;
                  stateCode?: string | null;
                  postalCode?: string | null;
                } | null;
              } | null;
              weather?: {
                __typename?: 'AirportWeather';
                weather?: {
                  __typename?: 'Weather';
                  condition: ConditionCode;
                  temperatureMax: number;
                  temperatureMin: number;
                  unit: TemperatureUnit;
                } | null;
              } | null;
            };
            aircraftInfo: Array<{
              __typename?: 'FlightAircraftInformation';
              type: FlightAircraftInformationType;
              aircraft:
                | {
                    __typename?: 'Aircraft';
                    displayDetails: {
                      __typename?: 'AircraftDisplayDetails';
                      name?: string | null;
                      category?: string | null;
                      imageUrl?: string | null;
                    };
                  }
                | {
                    __typename?: 'AircraftCabin';
                    displayDetails: {
                      __typename?: 'AircraftDisplayDetails';
                      name?: string | null;
                      category?: string | null;
                      imageUrl?: string | null;
                    };
                  }
                | {
                    __typename?: 'AircraftType';
                    displayDetails: {
                      __typename?: 'AircraftDisplayDetails';
                      name?: string | null;
                      category?: string | null;
                      imageUrl?: string | null;
                    };
                  }
                | {
                    __typename?: 'SingleSearchAircraftDetails';
                  };
            }>;
            departureDateTime?: Array<{
              __typename?: 'FlightDateTime';
              dateTime: any;
              type: FlightDatetimeType;
            }> | null;
            arrivalDateTime?: Array<{
              __typename?: 'FlightDateTime';
              dateTime: any;
              type: FlightDatetimeType;
            }> | null;
            origin: {
              __typename?: 'FlightAirportFBO';
              airport: {
                __typename?: 'Airport';
                ianaTimeZone: string;
                displayName: string;
                displayCode: string;
                name: string;
                address: {
                  __typename?: 'ADSAddress';
                  stateCode?: string | null;
                };
              };
              fbo?: {
                __typename?: 'FBO';
                displayName: string;
                telephone?: string | null;
                address?: {
                  __typename?: 'ADSAddress';
                  city?: string | null;
                  country: string;
                  streetLine1?: string | null;
                  streetLine2?: string | null;
                  stateCode?: string | null;
                  postalCode?: string | null;
                } | null;
              } | null;
              weather?: {
                __typename?: 'AirportWeather';
                weather?: {
                  __typename?: 'Weather';
                  condition: ConditionCode;
                  temperatureMax: number;
                  temperatureMin: number;
                  unit: TemperatureUnit;
                } | null;
              } | null;
            };
            status: Array<{
              __typename?: 'FlightStatus';
              status: string;
            }>;
            flightTime: {
              __typename?: 'FlightDuration';
              timeFormatted: string;
            };
          }
        | {
            __typename?: 'SharedFlight';
            flightId: string;
            leg: number;
            flightType: string;
            reservations: Array<{
              __typename?: 'SharedReservation';
              reservationId: string;
              reservationNumber: string;
            }>;
            travellerGroups: Array<{
              __typename?: 'SharedFlightTravellerGroup';
              passengers: Array<{
                __typename?: 'FlightPassenger';
                flightPassengerId: string;
              }>;
            }>;
            destination: {
              __typename?: 'FlightAirportFBO';
              airport: {
                __typename?: 'Airport';
                ianaTimeZone: string;
                displayName: string;
                displayCode: string;
                name: string;
                address: {
                  __typename?: 'ADSAddress';
                  stateCode?: string | null;
                };
              };
              fbo?: {
                __typename?: 'FBO';
                displayName: string;
                telephone?: string | null;
                address?: {
                  __typename?: 'ADSAddress';
                  city?: string | null;
                  country: string;
                  streetLine1?: string | null;
                  streetLine2?: string | null;
                  stateCode?: string | null;
                  postalCode?: string | null;
                } | null;
              } | null;
              weather?: {
                __typename?: 'AirportWeather';
                weather?: {
                  __typename?: 'Weather';
                  condition: ConditionCode;
                  temperatureMax: number;
                  temperatureMin: number;
                  unit: TemperatureUnit;
                } | null;
              } | null;
            };
            aircraftInfo: Array<{
              __typename?: 'FlightAircraftInformation';
              type: FlightAircraftInformationType;
              aircraft:
                | {
                    __typename?: 'Aircraft';
                    displayDetails: {
                      __typename?: 'AircraftDisplayDetails';
                      name?: string | null;
                      category?: string | null;
                      imageUrl?: string | null;
                    };
                  }
                | {
                    __typename?: 'AircraftCabin';
                    displayDetails: {
                      __typename?: 'AircraftDisplayDetails';
                      name?: string | null;
                      category?: string | null;
                      imageUrl?: string | null;
                    };
                  }
                | {
                    __typename?: 'AircraftType';
                    displayDetails: {
                      __typename?: 'AircraftDisplayDetails';
                      name?: string | null;
                      category?: string | null;
                      imageUrl?: string | null;
                    };
                  }
                | {
                    __typename?: 'SingleSearchAircraftDetails';
                  };
            }>;
            departureDateTime?: Array<{
              __typename?: 'FlightDateTime';
              dateTime: any;
              type: FlightDatetimeType;
            }> | null;
            arrivalDateTime?: Array<{
              __typename?: 'FlightDateTime';
              dateTime: any;
              type: FlightDatetimeType;
            }> | null;
            origin: {
              __typename?: 'FlightAirportFBO';
              airport: {
                __typename?: 'Airport';
                ianaTimeZone: string;
                displayName: string;
                displayCode: string;
                name: string;
                address: {
                  __typename?: 'ADSAddress';
                  stateCode?: string | null;
                };
              };
              fbo?: {
                __typename?: 'FBO';
                displayName: string;
                telephone?: string | null;
                address?: {
                  __typename?: 'ADSAddress';
                  city?: string | null;
                  country: string;
                  streetLine1?: string | null;
                  streetLine2?: string | null;
                  stateCode?: string | null;
                  postalCode?: string | null;
                } | null;
              } | null;
              weather?: {
                __typename?: 'AirportWeather';
                weather?: {
                  __typename?: 'Weather';
                  condition: ConditionCode;
                  temperatureMax: number;
                  temperatureMin: number;
                  unit: TemperatureUnit;
                } | null;
              } | null;
            };
            status: Array<{
              __typename?: 'FlightStatus';
              status: string;
            }>;
            flightTime: {
              __typename?: 'FlightDuration';
              timeFormatted: string;
            };
          }
        | {
            __typename?: 'ShuttleFlight';
            flightId: string;
            leg: number;
            flightType: string;
            reservations: Array<{
              __typename?: 'ShuttleReservation';
              reservationId: string;
              reservationNumber: string;
            }>;
            travellerGroups: Array<{
              __typename?: 'ShuttleFlightTravellerGroup';
              availableSeats?: number | null;
              passengers: Array<{
                __typename?: 'FlightPassenger';
                flightPassengerId: string;
              }>;
            }>;
            destination: {
              __typename?: 'FlightAirportFBO';
              airport: {
                __typename?: 'Airport';
                ianaTimeZone: string;
                displayName: string;
                displayCode: string;
                name: string;
                address: {
                  __typename?: 'ADSAddress';
                  stateCode?: string | null;
                };
              };
              fbo?: {
                __typename?: 'FBO';
                displayName: string;
                telephone?: string | null;
                address?: {
                  __typename?: 'ADSAddress';
                  city?: string | null;
                  country: string;
                  streetLine1?: string | null;
                  streetLine2?: string | null;
                  stateCode?: string | null;
                  postalCode?: string | null;
                } | null;
              } | null;
              weather?: {
                __typename?: 'AirportWeather';
                weather?: {
                  __typename?: 'Weather';
                  condition: ConditionCode;
                  temperatureMax: number;
                  temperatureMin: number;
                  unit: TemperatureUnit;
                } | null;
              } | null;
            };
            aircraftInfo: Array<{
              __typename?: 'FlightAircraftInformation';
              type: FlightAircraftInformationType;
              aircraft:
                | {
                    __typename?: 'Aircraft';
                    displayDetails: {
                      __typename?: 'AircraftDisplayDetails';
                      name?: string | null;
                      category?: string | null;
                      imageUrl?: string | null;
                    };
                  }
                | {
                    __typename?: 'AircraftCabin';
                    displayDetails: {
                      __typename?: 'AircraftDisplayDetails';
                      name?: string | null;
                      category?: string | null;
                      imageUrl?: string | null;
                    };
                  }
                | {
                    __typename?: 'AircraftType';
                    displayDetails: {
                      __typename?: 'AircraftDisplayDetails';
                      name?: string | null;
                      category?: string | null;
                      imageUrl?: string | null;
                    };
                  }
                | {
                    __typename?: 'SingleSearchAircraftDetails';
                  };
            }>;
            departureDateTime?: Array<{
              __typename?: 'FlightDateTime';
              dateTime: any;
              type: FlightDatetimeType;
            }> | null;
            arrivalDateTime?: Array<{
              __typename?: 'FlightDateTime';
              dateTime: any;
              type: FlightDatetimeType;
            }> | null;
            origin: {
              __typename?: 'FlightAirportFBO';
              airport: {
                __typename?: 'Airport';
                ianaTimeZone: string;
                displayName: string;
                displayCode: string;
                name: string;
                address: {
                  __typename?: 'ADSAddress';
                  stateCode?: string | null;
                };
              };
              fbo?: {
                __typename?: 'FBO';
                displayName: string;
                telephone?: string | null;
                address?: {
                  __typename?: 'ADSAddress';
                  city?: string | null;
                  country: string;
                  streetLine1?: string | null;
                  streetLine2?: string | null;
                  stateCode?: string | null;
                  postalCode?: string | null;
                } | null;
              } | null;
              weather?: {
                __typename?: 'AirportWeather';
                weather?: {
                  __typename?: 'Weather';
                  condition: ConditionCode;
                  temperatureMax: number;
                  temperatureMin: number;
                  unit: TemperatureUnit;
                } | null;
              } | null;
            };
            status: Array<{
              __typename?: 'FlightStatus';
              status: string;
            }>;
            flightTime: {
              __typename?: 'FlightDuration';
              timeFormatted: string;
            };
          };
    }> | null;
  } | null;
};

export type GetActiveAlertsQueryVariables = Exact<{
  collectionOrGroupTypeName?: InputMaybe<Scalars['String']>;
  isCollection?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetActiveAlertsQuery = {
  __typename?: 'Query';
  pwActiveAlerts: Array<{
    __typename?: 'PwAlert';
    alertGroup: PwAlertGroup;
    alertId?: string | null;
    alertType: PwAlertType;
    entityId?: string | null;
    payload?:
      | {
          __typename?: 'PwAlertFBOChange';
          airport: {
            __typename?: 'Airport';
            displayCode: string;
            displayName: string;
            address: {
              __typename?: 'ADSAddress';
              city?: string | null;
              stateCode?: string | null;
              countryISOCode3?: string | null;
              postalCode?: string | null;
            };
          };
        }
      | {
          __typename?: 'PwAlertMembershipExpiring';
          expirationDate?: string | null;
        }
      | {
          __typename?: 'PwAlertMissingPassengerInfo';
          flightNumber: string;
          unknownPassengerCount?: number | null;
          reservation?:
            | {
                __typename?: 'PrivateReservation';
                reservationId: string;
                segments: Array<{
                  __typename?: 'PrivateFlight';
                  leg: number;
                }>;
              }
            | {
                __typename?: 'SharedReservation';
                reservationId: string;
                segments: Array<{
                  __typename?: 'SharedFlight';
                  leg: number;
                }>;
              }
            | {
                __typename?: 'ShuttleReservation';
                reservationId: string;
                segments: Array<{
                  __typename?: 'ShuttleFlight';
                  leg: number;
                }>;
              }
            | null;
        }
      | {
          __typename?: 'PwAlertPassenger';
          alertPassengerId: string;
          firstName?: string | null;
          lastName?: string | null;
          isPet?: boolean | null;
        }
      | {
          __typename?: 'PwAlertScheduleUpdate';
          flightNumber: string;
          fromAirport: {
            __typename?: 'Airport';
            displayCode: string;
            displayName: string;
            address: {
              __typename?: 'ADSAddress';
              city?: string | null;
              stateCode?: string | null;
              countryISOCode3?: string | null;
              postalCode?: string | null;
            };
          };
          toAirport: {
            __typename?: 'Airport';
            displayCode: string;
            displayName: string;
            address: {
              __typename?: 'ADSAddress';
              city?: string | null;
              stateCode?: string | null;
              countryISOCode3?: string | null;
              postalCode?: string | null;
            };
          };
        }
      | {
          __typename?: 'PwAlertTailNumberChange';
          newAircraft: {
            __typename?: 'Aircraft';
            tailNumber: string;
          };
        }
      | null;
  }>;
};

export type GetAccountActiveAlertsQueryVariables = Exact<{
  collectionOrGroupTypeName?: InputMaybe<Scalars['String']>;
  isCollection?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetAccountActiveAlertsQuery = {
  __typename?: 'Query';
  pwActiveAlerts: Array<{
    __typename?: 'PwAlert';
    alertGroup: PwAlertGroup;
    alertId?: string | null;
    alertType: PwAlertType;
    entityId?: string | null;
    payload?:
      | { __typename?: 'PwAlertFBOChange' }
      | {
          __typename?: 'PwAlertMembershipExpiring';
          expirationDate?: string | null;
        }
      | { __typename?: 'PwAlertMissingPassengerInfo' }
      | { __typename?: 'PwAlertPassenger' }
      | { __typename?: 'PwAlertScheduleUpdate' }
      | { __typename?: 'PwAlertTailNumberChange' }
      | null;
  }>;
};

export type GetMyTripsActiveAlertsQueryVariables = Exact<{
  collectionOrGroupTypeName?: InputMaybe<Scalars['String']>;
  isCollection?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetMyTripsActiveAlertsQuery = {
  __typename?: 'Query';
  pwActiveAlerts: Array<{
    __typename?: 'PwAlert';
    alertGroup: PwAlertGroup;
    alertId?: string | null;
    alertType: PwAlertType;
    entityId?: string | null;
    payload?:
      | { __typename?: 'PwAlertFBOChange' }
      | { __typename?: 'PwAlertMembershipExpiring' }
      | {
          __typename?: 'PwAlertMissingPassengerInfo';
          flightNumber: string;
          unknownPassengerCount?: number | null;
          reservation?:
            | {
                __typename?: 'PrivateReservation';
                reservationId: string;
                segments: Array<{
                  __typename?: 'PrivateFlight';
                  leg: number;
                }>;
              }
            | {
                __typename?: 'SharedReservation';
                reservationId: string;
                segments: Array<{
                  __typename?: 'SharedFlight';
                  leg: number;
                }>;
              }
            | {
                __typename?: 'ShuttleReservation';
                reservationId: string;
                segments: Array<{
                  __typename?: 'ShuttleFlight';
                  leg: number;
                }>;
              }
            | null;
        }
      | {
          __typename?: 'PwAlertPassenger';
          alertPassengerId: string;
          firstName?: string | null;
          lastName?: string | null;
          isPet?: boolean | null;
        }
      | { __typename?: 'PwAlertScheduleUpdate' }
      | { __typename?: 'PwAlertTailNumberChange' }
      | null;
  }>;
};

export type GetMemberSalesPersonsQueryVariables = Exact<{
  memberId: Scalars['ID'];
}>;

export type GetMemberSalesPersonsQuery = {
  __typename?: 'Query';
  member?: {
    __typename?: 'Member';
    salesPersons?: Array<{
      __typename?: 'MemberSalesPerson';
      memberSalesPersonId: string;
      primary: boolean;
      salesPerson: {
        __typename?: 'SalesPerson';
        firstName: string;
        lastName: string;
        phone?: string | null;
        salesPersonId: string;
        email?: string | null;
      };
      salesPersonType: {
        __typename?: 'MemberSalesPersonType';
        code: string;
      };
    }> | null;
  } | null;
};

export const GetPagedBillingDocument = `
    query GetPagedBilling($first: Int, $after: String, $status: [BillingStatus], $type: [PaymentMethod]) {
  billingForMemberPaged(
    first: $first
    after: $after
    status: $status
    type: $type
  ) {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
    }
    totalCount
    edges {
      node {
        billingId
        detail {
          __typename
        }
        methodLabel
        status
        createDate
        flight {
          fromAirport {
            code
          }
          toAirport {
            code
          }
        }
        amount {
          amount
          symbol
          unit
        }
        reservation {
          reservationNumber
        }
      }
    }
  }
}
    `;
export const useGetPagedBillingQuery = <
  TData = GetPagedBillingQuery,
  TError = unknown
>(
  variables?: GetPagedBillingQueryVariables,
  options?: UseQueryOptions<
    GetPagedBillingQuery,
    TError,
    TData
  >,
) =>
  useQuery<GetPagedBillingQuery, TError, TData>(
    variables === undefined
      ? ['GetPagedBilling']
      : ['GetPagedBilling', variables],
    useAxios<
      GetPagedBillingQuery,
      GetPagedBillingQueryVariables
    >(GetPagedBillingDocument).bind(null, variables),
    options,
  );
useGetPagedBillingQuery.document = GetPagedBillingDocument;

useGetPagedBillingQuery.getKey = (
  variables?: GetPagedBillingQueryVariables,
) =>
  variables === undefined
    ? ['GetPagedBilling']
    : ['GetPagedBilling', variables];
export const GetStatementsDocument = `
    query GetStatements($accountTypes: [AccountType!]) {
  statementsForMember(accountTypes: $accountTypes) {
    statements {
      statementMonth
      documentItems {
        account {
          accountId
          number
        }
        credit {
          creditId
          number
        }
        documentType
        fileName
        memberStatementDocumentId
        downloadUrl
      }
    }
  }
  accountsForMember(types: $accountTypes) {
    accountId
    number
    accountType
    startDate
    balances {
      currentBalance {
        amount
        unit
        symbol
      }
      projectedBalance {
        amount
        unit
        symbol
      }
    }
  }
  creditsForMember {
    creditId
    number
    description
    enabled
  }
}
    `;
export const useGetStatementsQuery = <
  TData = GetStatementsQuery,
  TError = unknown
>(
  variables?: GetStatementsQueryVariables,
  options?: UseQueryOptions<
    GetStatementsQuery,
    TError,
    TData
  >,
) =>
  useQuery<GetStatementsQuery, TError, TData>(
    variables === undefined
      ? ['GetStatements']
      : ['GetStatements', variables],
    useAxios<
      GetStatementsQuery,
      GetStatementsQueryVariables
    >(GetStatementsDocument).bind(null, variables),
    options,
  );
useGetStatementsQuery.document = GetStatementsDocument;

useGetStatementsQuery.getKey = (
  variables?: GetStatementsQueryVariables,
) =>
  variables === undefined
    ? ['GetStatements']
    : ['GetStatements', variables];
export const GetStatementsV2Document = `
    query GetStatementsV2($accountTypes: [AccountType!]) {
  statementsForMember(accountTypes: $accountTypes) {
    statements {
      statementMonth
      documentItems {
        account {
          accountId
          number
        }
        credit {
          creditId
          number
        }
        documentType
        fileName
        memberStatementDocumentId
        downloadUrl
      }
    }
  }
  accountsForMember(types: $accountTypes) {
    name
    accountId
    number
    accountType
    startDate
    balances {
      currentBalance {
        amount
        unit
        symbol
      }
      projectedBalance {
        amount
        unit
        symbol
      }
    }
    guaranteeExpirationDate
    forfeitDate
  }
  creditsForMember {
    creditId
    number
    description
    enabled
  }
}
    `;
export const useGetStatementsV2Query = <
  TData = GetStatementsV2Query,
  TError = unknown
>(
  variables?: GetStatementsV2QueryVariables,
  options?: UseQueryOptions<
    GetStatementsV2Query,
    TError,
    TData
  >,
) =>
  useQuery<GetStatementsV2Query, TError, TData>(
    variables === undefined
      ? ['GetStatementsV2']
      : ['GetStatementsV2', variables],
    useAxios<
      GetStatementsV2Query,
      GetStatementsV2QueryVariables
    >(GetStatementsV2Document).bind(null, variables),
    options,
  );
useGetStatementsV2Query.document = GetStatementsV2Document;

useGetStatementsV2Query.getKey = (
  variables?: GetStatementsV2QueryVariables,
) =>
  variables === undefined
    ? ['GetStatementsV2']
    : ['GetStatementsV2', variables];
export const BillingByIdDocument = `
    query BillingById($billingIds: [ID!]) {
  billingById(billingIds: $billingIds) {
    detail {
      ... on FlightCateringOrderResults {
        results {
          flightCateringOrderId
        }
      }
      ... on GroundTransportResults {
        results {
          category
          groundTransportId
          groundTransportType {
            groundTransportTypeId
          }
        }
      }
      ... on TripAccountingSummary {
        billings {
          amount {
            unit
            symbol
            amount
          }
          category
          code
          label
        }
        payments {
          amount {
            unit
            symbol
            amount
          }
          invoices {
            invoiceId
          }
          label
          name
        }
      }
    }
    account {
      accountId
    }
    amount {
      amount
      symbol
      unit
    }
    billingId
    createDate
    flight {
      billing {
        billingId
        createDate
        method
        methodLabel
        status
      }
      flightId
      fromAirport {
        code
      }
      toAirport {
        code
      }
    }
    invoice {
      invoiceId
    }
    method
    methodLabel
    reservation {
      reservationId
    }
    status
  }
}
    `;
export const useBillingByIdQuery = <
  TData = BillingByIdQuery,
  TError = unknown
>(
  variables?: BillingByIdQueryVariables,
  options?: UseQueryOptions<
    BillingByIdQuery,
    TError,
    TData
  >,
) =>
  useQuery<BillingByIdQuery, TError, TData>(
    variables === undefined
      ? ['BillingById']
      : ['BillingById', variables],
    useAxios<BillingByIdQuery, BillingByIdQueryVariables>(
      BillingByIdDocument,
    ).bind(null, variables),
    options,
  );
useBillingByIdQuery.document = BillingByIdDocument;

useBillingByIdQuery.getKey = (
  variables?: BillingByIdQueryVariables,
) =>
  variables === undefined
    ? ['BillingById']
    : ['BillingById', variables];
export const GetPricingForDatesDocument = `
    query GetPricingForDates($flightCalendarRequest: FlightCalendarRequest!) {
  pricingForDates(flightCalendarRequest: $flightCalendarRequest) {
    date
    price {
      amount
      symbol
      unit
    }
  }
}
    `;
export const useGetPricingForDatesQuery = <
  TData = GetPricingForDatesQuery,
  TError = unknown
>(
  variables: GetPricingForDatesQueryVariables,
  options?: UseQueryOptions<
    GetPricingForDatesQuery,
    TError,
    TData
  >,
) =>
  useQuery<GetPricingForDatesQuery, TError, TData>(
    ['GetPricingForDates', variables],
    useAxios<
      GetPricingForDatesQuery,
      GetPricingForDatesQueryVariables
    >(GetPricingForDatesDocument).bind(null, variables),
    options,
  );
useGetPricingForDatesQuery.document = GetPricingForDatesDocument;

useGetPricingForDatesQuery.getKey = (
  variables: GetPricingForDatesQueryVariables,
) => ['GetPricingForDates', variables];
export const GetMemberAdvantageDocument = `
    query GetMemberAdvantage {
  memberAdvantage {
    __typename
    ... on MemberLoyalty {
      earnings {
        code
        spendingTarget {
          amount
          unit
          symbol
        }
        accruedExpenses {
          amount
          unit
          symbol
        }
        ... on MemberEarningMedallion {
          earnedMedallion {
            name
            pendingApplication
          }
        }
      }
    }
    ... on MemberSaving {
      year
      flightsWithoutSurgeProtection {
        value
        formatted
      }
      flightsWithSurgeProtection {
        value
        formatted
      }
      totalSavings {
        amount
        unit
        symbol
      }
    }
    ... on MemberNoAdvantage {
      _
    }
  }
}
    `;
export const useGetMemberAdvantageQuery = <
  TData = GetMemberAdvantageQuery,
  TError = unknown
>(
  variables?: GetMemberAdvantageQueryVariables,
  options?: UseQueryOptions<
    GetMemberAdvantageQuery,
    TError,
    TData
  >,
) =>
  useQuery<GetMemberAdvantageQuery, TError, TData>(
    variables === undefined
      ? ['GetMemberAdvantage']
      : ['GetMemberAdvantage', variables],
    useAxios<
      GetMemberAdvantageQuery,
      GetMemberAdvantageQueryVariables
    >(GetMemberAdvantageDocument).bind(null, variables),
    options,
  );
useGetMemberAdvantageQuery.document = GetMemberAdvantageDocument;

useGetMemberAdvantageQuery.getKey = (
  variables?: GetMemberAdvantageQueryVariables,
) =>
  variables === undefined
    ? ['GetMemberAdvantage']
    : ['GetMemberAdvantage', variables];
export const GetMemberBalanceDocument = `
    query GetMemberBalance {
  creditsBalanceByMember {
    amount
    symbol
    unit
  }
}
    `;
export const useGetMemberBalanceQuery = <
  TData = GetMemberBalanceQuery,
  TError = unknown
>(
  variables?: GetMemberBalanceQueryVariables,
  options?: UseQueryOptions<
    GetMemberBalanceQuery,
    TError,
    TData
  >,
) =>
  useQuery<GetMemberBalanceQuery, TError, TData>(
    variables === undefined
      ? ['GetMemberBalance']
      : ['GetMemberBalance', variables],
    useAxios<
      GetMemberBalanceQuery,
      GetMemberBalanceQueryVariables
    >(GetMemberBalanceDocument).bind(null, variables),
    options,
  );
useGetMemberBalanceQuery.document = GetMemberBalanceDocument;

useGetMemberBalanceQuery.getKey = (
  variables?: GetMemberBalanceQueryVariables,
) =>
  variables === undefined
    ? ['GetMemberBalance']
    : ['GetMemberBalance', variables];
export const ApplyEarnedMedallionDocument = `
    mutation ApplyEarnedMedallion {
  applyEarnedMedallion {
    code
    spendingTarget {
      amount
      unit
      symbol
    }
    accruedExpenses {
      amount
      unit
      symbol
    }
    earnedMedallion {
      name
      pendingApplication
    }
  }
}
    `;
export const useApplyEarnedMedallionMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    ApplyEarnedMedallionMutation,
    TError,
    ApplyEarnedMedallionMutationVariables,
    TContext
  >,
) =>
  useMutation<
    ApplyEarnedMedallionMutation,
    TError,
    ApplyEarnedMedallionMutationVariables,
    TContext
  >(
    ['ApplyEarnedMedallion'],
    useAxios<
      ApplyEarnedMedallionMutation,
      ApplyEarnedMedallionMutationVariables
    >(ApplyEarnedMedallionDocument),
    options,
  );
export const GetAlternateAirportsDocument = `
    query GetAlternateAirports($clientId: String!) {
  clientSingleSearchAlternateAirportOptions(clientId: $clientId) {
    messages {
      message
    }
    searchId
    results {
      feasible
      flightTimeInHours
      flightTripType
      legs {
        toAirport {
          name
          displayCode
          displayName
          address {
            city
            country
            stateCode
            countryISOCode2
            countryISOCode3
          }
        }
        fromAirport {
          name
          displayCode
          displayName
          address {
            city
            stateCode
            countryISOCode2
            countryISOCode3
          }
        }
        possibleFuelStop
        flightTimeInHours
        departureDateTime
      }
      alternateOptionMetadata {
        description {
          __typename
          ... on AlternateSearchOptionSavings {
            savingsPercentile
          }
          ... on AlternateSearchOptionCustoms {
            _
          }
        }
        replaced {
          leg
          replacementMetadata {
            __typename
            ... on SingleSearchOriginReplacement {
              suggestionDistanceFromOriginAirport {
                unit
                value
              }
            }
            ... on SingleSearchDestinationReplacement {
              suggestionDistanceFromDestinationAirport {
                unit
                value
              }
            }
          }
        }
      }
      price {
        __typename
        ... on InstantBookSingleSearchPricing {
          crossOutPrice {
            amount
            symbol
            unit
          }
          displayPrice {
            amount
            symbol
            unit
          }
        }
        ... on RFQSingleSearchPricing {
          displayPriceRange
          maxPrice {
            amount
            symbol
            unit
          }
          minPrice {
            amount
            symbol
            unit
          }
        }
      }
      aircraftDetails {
        aircraftCategory
        aircraftType
        view {
          displayHeadline
          displayDescription
        }
      }
      resultId
      instantBook
    }
    nonMemberResponse
  }
}
    `;
export const useGetAlternateAirportsQuery = <
  TData = GetAlternateAirportsQuery,
  TError = unknown
>(
  variables: GetAlternateAirportsQueryVariables,
  options?: UseQueryOptions<
    GetAlternateAirportsQuery,
    TError,
    TData
  >,
) =>
  useQuery<GetAlternateAirportsQuery, TError, TData>(
    ['GetAlternateAirports', variables],
    useAxios<
      GetAlternateAirportsQuery,
      GetAlternateAirportsQueryVariables
    >(GetAlternateAirportsDocument).bind(null, variables),
    options,
  );
useGetAlternateAirportsQuery.document = GetAlternateAirportsDocument;

useGetAlternateAirportsQuery.getKey = (
  variables: GetAlternateAirportsQueryVariables,
) => ['GetAlternateAirports', variables];
export const GetUpcomingAirportsDocument = `
    query GetUpcomingAirports {
  pwUpcomingFlights {
    flight {
      fromAirport {
        displayCode
      }
      toAirport {
        displayCode
      }
      ... on PrivateFlight {
        reservation {
          reservationId
        }
      }
      flightId
    }
  }
}
    `;
export const useGetUpcomingAirportsQuery = <
  TData = GetUpcomingAirportsQuery,
  TError = unknown
>(
  variables?: GetUpcomingAirportsQueryVariables,
  options?: UseQueryOptions<
    GetUpcomingAirportsQuery,
    TError,
    TData
  >,
) =>
  useQuery<GetUpcomingAirportsQuery, TError, TData>(
    variables === undefined
      ? ['GetUpcomingAirports']
      : ['GetUpcomingAirports', variables],
    useAxios<
      GetUpcomingAirportsQuery,
      GetUpcomingAirportsQueryVariables
    >(GetUpcomingAirportsDocument).bind(null, variables),
    options,
  );
useGetUpcomingAirportsQuery.document = GetUpcomingAirportsDocument;

useGetUpcomingAirportsQuery.getKey = (
  variables?: GetUpcomingAirportsQueryVariables,
) =>
  variables === undefined
    ? ['GetUpcomingAirports']
    : ['GetUpcomingAirports', variables];
export const GetMarketingNewsByCategoryDocument = `
    query GetMarketingNewsByCategory($memberTypeCode: MemberTypeCode!, $category: MarketingNewsCategory!) {
  marketingNewsByCategory(memberTypeCode: $memberTypeCode, category: $category) {
    heading
    newsPage {
      newsPageId
    }
    body
    cta
    imageUrl
    subheading
  }
}
    `;
export const useGetMarketingNewsByCategoryQuery = <
  TData = GetMarketingNewsByCategoryQuery,
  TError = unknown
>(
  variables: GetMarketingNewsByCategoryQueryVariables,
  options?: UseQueryOptions<
    GetMarketingNewsByCategoryQuery,
    TError,
    TData
  >,
) =>
  useQuery<GetMarketingNewsByCategoryQuery, TError, TData>(
    ['GetMarketingNewsByCategory', variables],
    useAxios<
      GetMarketingNewsByCategoryQuery,
      GetMarketingNewsByCategoryQueryVariables
    >(GetMarketingNewsByCategoryDocument).bind(
      null,
      variables,
    ),
    options,
  );
useGetMarketingNewsByCategoryQuery.document = GetMarketingNewsByCategoryDocument;

useGetMarketingNewsByCategoryQuery.getKey = (
  variables: GetMarketingNewsByCategoryQueryVariables,
) => ['GetMarketingNewsByCategory', variables];
export const GetUpcomingFlightsDocument = `
    query GetUpcomingFlights {
  upcomingFlights {
    edges {
      node {
        flightId
        leg
        destination {
          airport {
            ianaTimeZone
            displayName
            displayCode
            name
            address {
              stateCode
            }
          }
          fbo {
            address {
              city
              country
              streetLine1
              streetLine2
              stateCode
              postalCode
            }
            displayName
            telephone
          }
          weather {
            weather {
              condition
              temperatureMax
              temperatureMin
              unit
            }
          }
        }
        flightType
        aircraftInfo {
          type
          aircraft {
            ... on Aircraft {
              displayDetails {
                name
                category
                imageUrl
              }
            }
            ... on AircraftCabin {
              displayDetails {
                name
                category
                imageUrl
              }
            }
            ... on AircraftType {
              displayDetails {
                name
                category
                imageUrl
              }
            }
          }
        }
        departureDateTime {
          dateTime
          type
        }
        arrivalDateTime {
          dateTime
          type
        }
        origin {
          airport {
            ianaTimeZone
            displayName
            displayCode
            name
            address {
              stateCode
            }
          }
          fbo {
            address {
              city
              country
              streetLine1
              streetLine2
              stateCode
              postalCode
            }
            displayName
            telephone
          }
          weather {
            weather {
              condition
              temperatureMax
              temperatureMin
              unit
            }
          }
        }
        ... on PrivateFlight {
          reservation {
            reservationId
            reservationNumber
            segments {
              aircraftInfo {
                aircraft {
                  ... on Aircraft {
                    tailNumber
                    aircraftType {
                      displayDetails {
                        category
                        imageUrl
                      }
                    }
                  }
                  ... on AircraftCabin {
                    name
                    images {
                      photoUrl
                    }
                  }
                }
              }
              travellerGroup {
                passengers {
                  flightPassengerId
                }
              }
            }
            tripType
            span {
              startDate
              endDate
            }
          }
          travellerGroup {
            availableSeats
            passengers {
              flightPassengerId
            }
            unknownPassengers
          }
        }
        ... on SharedFlight {
          reservations {
            reservationId
            reservationNumber
          }
          travellerGroups {
            passengers {
              flightPassengerId
            }
          }
        }
        ... on ShuttleFlight {
          reservations {
            reservationId
            reservationNumber
          }
          travellerGroups {
            availableSeats
            passengers {
              flightPassengerId
            }
          }
        }
        status {
          status
        }
        flightTime {
          timeFormatted
        }
      }
    }
  }
}
    `;
export const useGetUpcomingFlightsQuery = <
  TData = GetUpcomingFlightsQuery,
  TError = unknown
>(
  variables?: GetUpcomingFlightsQueryVariables,
  options?: UseQueryOptions<
    GetUpcomingFlightsQuery,
    TError,
    TData
  >,
) =>
  useQuery<GetUpcomingFlightsQuery, TError, TData>(
    variables === undefined
      ? ['GetUpcomingFlights']
      : ['GetUpcomingFlights', variables],
    useAxios<
      GetUpcomingFlightsQuery,
      GetUpcomingFlightsQueryVariables
    >(GetUpcomingFlightsDocument).bind(null, variables),
    options,
  );
useGetUpcomingFlightsQuery.document = GetUpcomingFlightsDocument;

useGetUpcomingFlightsQuery.getKey = (
  variables?: GetUpcomingFlightsQueryVariables,
) =>
  variables === undefined
    ? ['GetUpcomingFlights']
    : ['GetUpcomingFlights', variables];
export const GetActiveAlertsDocument = `
    query GetActiveAlerts($collectionOrGroupTypeName: String, $isCollection: Boolean) {
  pwActiveAlerts(
    collectionOrGroupTypeName: $collectionOrGroupTypeName
    isCollection: $isCollection
  ) {
    alertGroup
    alertId
    alertType
    entityId
    payload {
      ... on PwAlertPassenger {
        alertPassengerId
        firstName
        lastName
        isPet
      }
      ... on PwAlertMissingPassengerInfo {
        reservation {
          reservationId
          segments {
            leg
          }
        }
        flightNumber
        unknownPassengerCount
      }
      ... on PwAlertMembershipExpiring {
        expirationDate
      }
      ... on PwAlertFBOChange {
        airport {
          displayCode
          displayName
          address {
            city
            stateCode
            countryISOCode3
            postalCode
          }
        }
      }
      ... on PwAlertTailNumberChange {
        newAircraft {
          tailNumber
        }
      }
      ... on PwAlertScheduleUpdate {
        flightNumber
        fromAirport {
          displayCode
          displayName
          address {
            city
            stateCode
            countryISOCode3
            postalCode
          }
        }
        toAirport {
          displayCode
          displayName
          address {
            city
            stateCode
            countryISOCode3
            postalCode
          }
        }
      }
    }
  }
}
    `;
export const useGetActiveAlertsQuery = <
  TData = GetActiveAlertsQuery,
  TError = unknown
>(
  variables?: GetActiveAlertsQueryVariables,
  options?: UseQueryOptions<
    GetActiveAlertsQuery,
    TError,
    TData
  >,
) =>
  useQuery<GetActiveAlertsQuery, TError, TData>(
    variables === undefined
      ? ['GetActiveAlerts']
      : ['GetActiveAlerts', variables],
    useAxios<
      GetActiveAlertsQuery,
      GetActiveAlertsQueryVariables
    >(GetActiveAlertsDocument).bind(null, variables),
    options,
  );
useGetActiveAlertsQuery.document = GetActiveAlertsDocument;

useGetActiveAlertsQuery.getKey = (
  variables?: GetActiveAlertsQueryVariables,
) =>
  variables === undefined
    ? ['GetActiveAlerts']
    : ['GetActiveAlerts', variables];
export const GetAccountActiveAlertsDocument = `
    query GetAccountActiveAlerts($collectionOrGroupTypeName: String, $isCollection: Boolean) {
  pwActiveAlerts(
    collectionOrGroupTypeName: $collectionOrGroupTypeName
    isCollection: $isCollection
  ) {
    alertGroup
    alertId
    alertType
    entityId
    payload {
      ... on PwAlertMembershipExpiring {
        expirationDate
      }
    }
  }
}
    `;
export const useGetAccountActiveAlertsQuery = <
  TData = GetAccountActiveAlertsQuery,
  TError = unknown
>(
  variables?: GetAccountActiveAlertsQueryVariables,
  options?: UseQueryOptions<
    GetAccountActiveAlertsQuery,
    TError,
    TData
  >,
) =>
  useQuery<GetAccountActiveAlertsQuery, TError, TData>(
    variables === undefined
      ? ['GetAccountActiveAlerts']
      : ['GetAccountActiveAlerts', variables],
    useAxios<
      GetAccountActiveAlertsQuery,
      GetAccountActiveAlertsQueryVariables
    >(GetAccountActiveAlertsDocument).bind(null, variables),
    options,
  );
useGetAccountActiveAlertsQuery.document = GetAccountActiveAlertsDocument;

useGetAccountActiveAlertsQuery.getKey = (
  variables?: GetAccountActiveAlertsQueryVariables,
) =>
  variables === undefined
    ? ['GetAccountActiveAlerts']
    : ['GetAccountActiveAlerts', variables];
export const GetMyTripsActiveAlertsDocument = `
    query GetMyTripsActiveAlerts($collectionOrGroupTypeName: String, $isCollection: Boolean) {
  pwActiveAlerts(
    collectionOrGroupTypeName: $collectionOrGroupTypeName
    isCollection: $isCollection
  ) {
    alertGroup
    alertId
    alertType
    entityId
    payload {
      ... on PwAlertPassenger {
        alertPassengerId
        firstName
        lastName
        isPet
      }
      ... on PwAlertMissingPassengerInfo {
        reservation {
          reservationId
          segments {
            leg
          }
        }
        flightNumber
        unknownPassengerCount
      }
    }
  }
}
    `;
export const useGetMyTripsActiveAlertsQuery = <
  TData = GetMyTripsActiveAlertsQuery,
  TError = unknown
>(
  variables?: GetMyTripsActiveAlertsQueryVariables,
  options?: UseQueryOptions<
    GetMyTripsActiveAlertsQuery,
    TError,
    TData
  >,
) =>
  useQuery<GetMyTripsActiveAlertsQuery, TError, TData>(
    variables === undefined
      ? ['GetMyTripsActiveAlerts']
      : ['GetMyTripsActiveAlerts', variables],
    useAxios<
      GetMyTripsActiveAlertsQuery,
      GetMyTripsActiveAlertsQueryVariables
    >(GetMyTripsActiveAlertsDocument).bind(null, variables),
    options,
  );
useGetMyTripsActiveAlertsQuery.document = GetMyTripsActiveAlertsDocument;

useGetMyTripsActiveAlertsQuery.getKey = (
  variables?: GetMyTripsActiveAlertsQueryVariables,
) =>
  variables === undefined
    ? ['GetMyTripsActiveAlerts']
    : ['GetMyTripsActiveAlerts', variables];
export const GetMemberSalesPersonsDocument = `
    query GetMemberSalesPersons($memberId: ID!) {
  member(memberId: $memberId) {
    salesPersons {
      memberSalesPersonId
      primary
      salesPerson {
        firstName
        lastName
        phone
        salesPersonId
        email
      }
      salesPersonType {
        code
      }
    }
  }
}
    `;
export const useGetMemberSalesPersonsQuery = <
  TData = GetMemberSalesPersonsQuery,
  TError = unknown
>(
  variables: GetMemberSalesPersonsQueryVariables,
  options?: UseQueryOptions<
    GetMemberSalesPersonsQuery,
    TError,
    TData
  >,
) =>
  useQuery<GetMemberSalesPersonsQuery, TError, TData>(
    ['GetMemberSalesPersons', variables],
    useAxios<
      GetMemberSalesPersonsQuery,
      GetMemberSalesPersonsQueryVariables
    >(GetMemberSalesPersonsDocument).bind(null, variables),
    options,
  );
useGetMemberSalesPersonsQuery.document = GetMemberSalesPersonsDocument;

useGetMemberSalesPersonsQuery.getKey = (
  variables: GetMemberSalesPersonsQueryVariables,
) => ['GetMemberSalesPersons', variables];
