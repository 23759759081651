import { ICardAircraftDetails } from './CardAircraftDetails.interfaces';
import {
  StyledCard,
  StyledMedia,
  StyledIcon,
  StyledItems,
  StyledItem,
  StyledInfo,
} from './CardAircraftDetails.styles';
import { ImageCarousel, Typography } from '@atoms';
import { solidColors } from '@constants/styles/colors.constants';
import { typography } from '@constants/styles/typography.constants';

const CardAircraftDetails = ({
  id,
  title,
  headline,
  images,
  leftImage,
  rightImage,
  items,
}: ICardAircraftDetails) => {
  const imagesArray = images.map((item) => item.url);
  return (
    <StyledCard data-name="CardAircraftDetails">
      <StyledMedia>
        <ImageCarousel
          imageURLs={imagesArray}
          heightRem={10.625}
          widthRem={24}
          arrowsInside={true}
          arrowsSize="small"
        />
      </StyledMedia>
      <StyledInfo>
        <Typography
          variant={typography.heading04Large}
          color={solidColors.midnight}
          className="title"
        >
          {title}
        </Typography>
        <Typography
          variant={typography.heading02Large}
          color={solidColors.midnight}
        >
          {headline}
        </Typography>
        <StyledItems>
          {items.map((item) => (
            <StyledItem key={item.title}>
              {item.image && (
                <StyledIcon
                  src={item.image.url}
                  filter={item.image.tintColor}
                  alt={`${item.title} - icon`}
                />
              )}
              <Typography
                variant={typography.heading05Large}
                color={solidColors.midnight}
              >
                {item.title}
              </Typography>
            </StyledItem>
          ))}
        </StyledItems>
      </StyledInfo>
    </StyledCard>
  );
};

export default CardAircraftDetails;
