/*
  This file should be used to globally reference feature flags. This will
  allow us to easily change the name of a feature flag in one place, and
  have it update everywhere else in the app.

  Please also comment on the same line when the cleanup of the flag should occur.
*/

export enum FeatureFlags {
  ACTIVITY = 'activity',
  NATIVE_MANAGE_CREDIT_CARDS = 'native_manage_credit_cards',
  USE_ALTERNATE_AIRPORTS = 'use_alternate_airports',
  USE_NATIVE_HUB = 'use_native_hub',
  DASHBOARD_REDESIGN = 'dashboard_redesign',
  USE_NATIVE_MY_TRIPS = 'use_native_my_trips',
  USE_GQL_ACCOUNT_ALERTS = 'use_gql_account_alerts',
}
