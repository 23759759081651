import { ICardImageCarousel } from './CardImageCarouselHeadlineSubcopyCTA.interfaces';
import {
  Typography,
  ArrowIconStyled,
  ImageCarousel,
} from '@atoms';
import { typography } from '@constants/styles/typography.constants';

import {
  CardMainContainer,
  LeftSideCard,
  RightSideCard,
  CardContainer,
  CardBody,
  CardCTAButton,
} from './CardImageCarouselHeadlineSubcopyCTA.styles';

const CardImageCarousel = ({
  headline,
  copy,
  widthRem,
  heightRem,
  images,
  button,
}: ICardImageCarousel) => {
  return (
    <CardMainContainer
      heightRem={heightRem}
      widthRem={widthRem}
    >
      <LeftSideCard>
        <CardContainer>
          <Typography
            variant={typography.heading01Large}
            truncate={1}
          >
            {headline}
          </Typography>
          <CardBody
            variant={typography.body2}
            truncate={3}
          >
            {copy}
          </CardBody>
          <CardCTAButton
            action={{
              actionMethod: button.action?.actionMethod,
            }}
            styleType={button?.styleType}
            title={button?.title}
            nativeIcon={<ArrowIconStyled />}
          />
        </CardContainer>
      </LeftSideCard>

      <RightSideCard>
        <ImageCarousel
          imageURLs={images.map((i) => i.image.url)}
          heightRem={heightRem}
          widthRem={widthRem / 1.84}
          arrowsInside
        />
      </RightSideCard>
    </CardMainContainer>
  );
};

export default CardImageCarousel;
