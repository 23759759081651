import { Container } from '@components/library';
import styled from 'styled-components';

const CarouselListWrapper = styled(Container)`
  display: flex;
  overflow: hidden;
  justify-content: space-around;
`;

export default CarouselListWrapper;
