import React, { useState } from 'react';
import { IButton } from '@atoms/Button/Button.interfaces';
import { useDispatch } from 'react-redux';
import { setInputValue } from '@components/organisms/SubPagePetAddEdit/SubPagePetAddEdit.slice';
import { Typography } from '@atoms';

import { useAppSelector } from '@app/hooks';
import { CustomCheckbox, TextGroupPillText } from '..';
import { typography } from '@constants/styles/typography.constants';
import {
  PetTypeOtherFormField,
  StyledContainer,
  StyledTextInput,
  StyledInputContainer,
  StyledInputCheckboxContainer,
} from './PetInformationTypeForm.styles';
import { IPet } from '@components/types';
import { solidColors } from '@constants/styles/colors.constants';
import CarouselScrollHorizontal from '@components/organisms/CarouselScrollHorizontal/CarouselScrollHorizontal';

interface IPetInformationTypeForm {
  petTypeDogButton: IButton;
  petTypeCatButton: IButton;
  petTypeOtherButton: IButton;
  petTypeTitle: string;
  pet?: IPet;
}

const PetInformationTypeForm = ({
  petTypeDogButton,
  petTypeTitle,
  petTypeCatButton,
  petTypeOtherButton,
  pet,
}: IPetInformationTypeForm) => {
  const dispatch = useDispatch();
  const [petTypeIsOther, setPetTypeIsOther] = useState(
    pet?.petType === 'Other',
  );
  const [error, setError] = React.useState('');

  const pets = ['Dog', 'Cat', 'Other'];

  const handleInputChange = (
    inputKey: string,
    value: string | boolean,
  ) => {
    dispatch(
      setInputValue({
        key: inputKey,
        value: value,
      }),
    );
  };
  const {
    petType,
    isHypoallergenic,
    petTypeDescription,
    isServiceAnimal,
  } = useAppSelector((state) => state.subPetAddEdit);

  const handlePetTypeChange = (
    e:
      | React.MouseEvent
      | React.ChangeEvent<HTMLInputElement>,
  ) => {
    e.preventDefault();
    const { id } = e.currentTarget;
    handleInputChange('petType', id);
    setPetTypeIsOther(id === 'Other');
  };

  const renderPetTypeButtons = () => {
    return pets.map((pet) => {
      return (
        <TextGroupPillText
          key={pet}
          id={pet}
          onClick={handlePetTypeChange}
          copy={pet}
          selected={pet === petType}
          width="85px"
        />
      );
    });
  };

  return (
    <StyledContainer>
      <Typography variant={typography.heading03Large}>
        {petTypeTitle}
      </Typography>
      <StyledInputContainer>
        <CarouselScrollHorizontal>
          {renderPetTypeButtons()}
        </CarouselScrollHorizontal>
        {petTypeIsOther && (
          <PetTypeOtherFormField>
            <StyledTextInput
              variant="large"
              name="otherPet"
              value={petTypeDescription}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                handleInputChange(
                  'petTypeDescription',
                  e.target.value,
                )
              }
              setErrorMessage={(message: string) => {
                setError(message);
              }}
              errorMessage={error}
              label="Animal type"
              maxLength={150}
            />
          </PetTypeOtherFormField>
        )}
      </StyledInputContainer>

      <StyledInputCheckboxContainer>
        <CustomCheckbox
          onChange={(e) =>
            handleInputChange(
              'isServiceAnimal',
              e.target.checked,
            )
          }
          checked={isServiceAnimal || false}
          label={
            <>
              <div>
                <Typography variant={typography.body1}>
                  Service Animal
                </Typography>
              </div>
              <Typography
                variant={typography.body1}
                color={solidColors.darkGray}
              >
                Emotional Support Animals are not Service
                Animals.
              </Typography>
            </>
          }
        />
      </StyledInputCheckboxContainer>
      <StyledInputCheckboxContainer>
        <CustomCheckbox
          onChange={(e) =>
            handleInputChange(
              'isHypoallergenic',
              e.target.checked,
            )
          }
          checked={isHypoallergenic || false}
          label={
            <>
              <div>
                <Typography variant={typography.body1}>
                  Hypoallergenic
                </Typography>
              </div>
              <Typography
                variant={typography.body1}
                color={solidColors.darkGray}
              >
                Hypoallergenic pets do not shed.
              </Typography>
            </>
          }
        />
      </StyledInputCheckboxContainer>
    </StyledContainer>
  );
};

export default PetInformationTypeForm;
