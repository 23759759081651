import styled from 'styled-components';
import { solidColors } from '@constants/styles/colors.constants';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@library';

export const StyledAccordion = styled(Accordion)`
  box-shadow: 0 -1px 0 0 ${solidColors.mediumGray.color};
  width: 100%;
  border-radius: 0px;
  padding: 0px;

  &.Mui-expanded {
    margin: 0px;
  }

  &.MuiAccordion-rounded:first-child{
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
`;

export const StyledAccordionSummary = styled(
  AccordionSummary,
)`
  padding: 0;

  .MuiAccordionSummary-content {
    padding: 30px 0;
    margin: 0px;
  }

  .MuiSvgIcon-root {
    fill: ${solidColors.upBlue.color};
  }
`;

export const StyledAccordionDetails = styled(
  AccordionDetails,
)`
  padding: 0px 0px 32px;
`;
