import { gsap } from 'gsap';
import { useEffect, useRef } from 'react';
import { IconButton } from '@library';
import { hostedImage } from '@constants/images/images.constants';
import {
  BackIconStyled,
  CardHeaderStyled,
  CardStyled,
  CardContainer,
  PlaneImg,
  PageBackgroundStyled,
} from './LogInContainer.styles';
import { useNavigate } from 'react-router-dom';
import { BackgroundType } from '@atoms/PageBackground/PageBackground';

import { determinePathToSendUser } from './LoginContainer.utils';
import { paths } from '@routing/routerPaths';

interface ILogInContainer {
  isRedirecting?: boolean;
  backButtonPath?: string;
  children?: JSX.Element | JSX.Element[] | null;
}

const LogInContainer = ({
  backButtonPath,
  children,
  isRedirecting,
}: ILogInContainer) => {
  const navigate = useNavigate();
  const planeElement = useRef(null);
  const cardElement = useRef(null);

  useEffect(() => {
    if (isRedirecting) {
      const path = determinePathToSendUser();
      window.location.assign(path);
    }
  }, [isRedirecting]);

  return (
    <PageBackgroundStyled
      metadata={undefined}
      backgroundType={BackgroundType.SIGNIN}
      animate={true}
    >
      <>
        <CardContainer>
          <PlaneImg
            ref={planeElement}
            src={hostedImage.wheelsUpPlane}
          />
          <CardStyled
            variant='raisedLevel8dp'
            padding={32}
            ref={cardElement}
          >
            <CardHeaderStyled
              action={
                backButtonPath ? (
                  <IconButton
                    onClick={() => {
                      backButtonPath === paths.SIGNIN
                        ? window.location.replace(
                            paths.SIGNIN,
                          )
                        : navigate(backButtonPath);
                    }}
                  >
                    <BackIconStyled data-testid="back-icon-button" />
                  </IconButton>
                ) : null
              }
            />
            {children}
          </CardStyled>
        </CardContainer>
      </>
    </PageBackgroundStyled>
  );
};

export default LogInContainer;
