import { useEffect, useState } from 'react';
import { HeadlineSubtitleWithBackArrow } from '@components/molecules';
import PassengerInformationForm from '@components/molecules/PassengerInformationForm/PassengerInformationForm';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@wheelsup/wu-react-components';
import {
  ContainerStyled,
  Divider,
} from './SubPagePassengerAddEdit.styles';
import { ISubPagePassengerAddEdit } from './SubPagePassengerAddEdit.interfaces';
import DayOfTraveAlertsForm from '@components/molecules/DayOfTraveAlertsForm/DayOfTraveAlertsForm';
import { PageActionButtonContainer } from '../SubPagePassengerSelection/SubPagePassengerSelection.styles';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '@app/hooks';
import {
  cleanPassengerState,
  putPassenger,
  setInputValue,
  setPassenger,
} from './SubPagePassengerAddEdit.slice';
import {
  IPassenger,
  TextStyleType,
} from '@components/types';
import { setAllSelectedPassengers } from '@features/Fly/FlySearchTripDetailsSlice';
import { cloneDeep } from 'lodash';
import { solidColors } from '@constants/styles/colors.constants';
import TextGroupSwitch from '@components/molecules/TextGroupSwitch/TextGroupSwitch';
import { deconstructStyleType } from '@components/utils/deconstructStyleType';
import { typography } from '@constants/styles/typography.constants';

export default function SubPagePassengerAddEdit({
  passenger,
  passengerInfoTitle,
  passengerInfoSubtitle,
  prefixDropdown,
  suffixDropdown,
  genderDropdown,
  firstNameFormField,
  middleNameFormField,
  lastNameFormField,
  birthDateFormField,
  weightFormField,
  switchTextGroup,
  dayOfTravelTitle,
  dayOfTravelSubtitle = '',
  phoneFormField,
  emailFormField,
}: ISubPagePassengerAddEdit) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    selectedPassengersLeg,
    selectedLeg,
  } = useAppSelector((state) => state.searchTripDetails);

  const paramLeg = useParams<{ leg: string }>();
  const leg = paramLeg.leg || selectedLeg;

  const [dateError, setDateError] = useState(false);
  const [dateErrorMessage, setDateErrorMessage] = useState(
    'Missing birthdate',
  );
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [genderError, setGenderError] = useState(false);

  const {
    prefix,
    firstName,
    middleName,
    suffix,
    lastName,
    birthday,
    weight,
    gender,
    favorite,
    email,
    phone,
    isSuccesful,
  } = useAppSelector((state) => state.subPassengerAddEdit);

  const handleIsFavorite = () => {
    dispatch(
      setInputValue({
        key: 'favorite',
        value: !favorite,
      }),
    );
  };

  const handleEmailError = (isError: boolean) => {
    setEmailError(isError && email !== '');
  };

  const handleGenderError = () => {
    if (!gender || gender === '') {
      setGenderError(true);
    } else {
      setGenderError(false);
    }
  };

  const formatDate = (inputDate: string) => {
    const formattedDate = `${inputDate.slice(
      0,
      2,
    )}/${inputDate.slice(2, 4)}/${inputDate.slice(4, 8)}`;

    return new Date(formattedDate);
  };

  const handleBirthdayValidation = (birthday: string) => {
    const formattedBirthDate = formatDate(birthday);
    if (isNaN(formattedBirthDate.getDate())) {
      if (birthday === '') {
        setDateErrorMessage('Missing birthdate');
      } else {
        setDateErrorMessage('Invalid date');
      }
      setDateError(true);
      return true;
    } else {
      setDateError(false);
      return false;
    }
  };

  const handleSaveOnClick = () => {
    const formattedBirthDate = formatDate(birthday);
    const invalidBirthday = handleBirthdayValidation(
      birthday,
    );
    if (invalidBirthday) {
      return;
    }

    if (emailError) return;

    const passengerInput: IPassenger = {
      id: passenger?.id ?? undefined,
      prefix: prefix,
      firstName: firstName,
      middleName: middleName,
      lastName: lastName,
      suffix: suffix,
      gender: gender,
      dateOfBirth: formattedBirthDate,
      isFavPassenger: favorite,
      weight: Number(weight),
      email: email,
      phone: phone,
    };

    dispatch(putPassenger(passengerInput));
  };

  const isFormNotModified = () => {
    const currentValues = {
      prefix,
      firstName,
      middleName,
      suffix,
      lastName,
      birthday: formatDate(birthday),
      gender,
      weight,
      favorite,
      email,
      phone,
    };

    const passengerReduced = {
      prefix: passenger?.prefix,
      firstName: passenger?.firstName,
      middleName: passenger?.middleName,
      suffix: passenger?.suffix,
      lastName: passenger?.lastName,
      birthday: new Date(
        birthDateFormField.fieldCopy ?? '',
      ),
      gender: passenger?.gender,
      weight: passenger?.weight?.toString() ?? '0',
      favorite: passenger?.isFavPassenger,
      email: passenger?.email,
      phone: passenger?.phone,
    };
    if (passenger) {
      if (
        JSON.stringify(currentValues.birthday) === 'null' ||
        Number(currentValues.weight) === 0 ||
        currentValues.weight === ''
      ) {
        return true;
      }
    }

    return (
      JSON.stringify(currentValues) ===
      JSON.stringify(passengerReduced)
    );
  };

  const savePassengerDataInStore = () => {
    if (selectedPassengersLeg[Number(leg)] === undefined)
      return;

    const legPassengers = cloneDeep(selectedPassengersLeg);

    const updatedAllLegPassengers = legPassengers.map(
      (leg) => {
        const formattedBirthDate = formatDate(birthday);
        const newSelectedPassengers = leg.selectedPassengers.map(
          (p) => {
            if (p.id === passenger?.id) {
              p.prefix = prefix;
              p.firstName = firstName;
              p.middleName = middleName;
              p.lastName = lastName;
              p.suffix = suffix;
              p.gender = gender;
              p.dateOfBirth = formattedBirthDate;
              p.isFavPassenger = favorite;
              p.weight = Number(weight);
              p.email = email;
              p.phone = phone;
            }
            return p;
          },
        );
        return {
          ...leg,
          selectedPassengers: newSelectedPassengers,
        };
      },
    );
    dispatch(
      setAllSelectedPassengers(updatedAllLegPassengers),
    );
  };

  const handlePhoneError = (isError: boolean) =>
    setPhoneError(isError);

  useEffect(() => {
    if (passenger !== undefined) {
      handleBirthdayValidation(birthday);
      dispatch(
        setPassenger({
          ...passenger,
          dateOfBirth: new Date(
            birthDateFormField.fieldCopy ?? '',
          ),
        }),
      );
    }

    return function cleanup() {
      dispatch(cleanPassengerState());
    };
  }, [passenger]);

  useEffect(() => {
    if (isSuccesful) {
      // This logic should be handled by the middleware.
      // savePassengerDataInStore();
      navigate(-1);
    }
  }, [isSuccesful]);

  const {
    typographyType: switchTypographyType,

    colorType: switchColorType,
  } = deconstructStyleType(
    switchTextGroup.styleType as TextStyleType | undefined,
  );

  return (
    <div data-name="SubPagePassengerAddEdit">
      <>
        <ContainerStyled>
          <HeadlineSubtitleWithBackArrow
            headline={
              passenger === undefined
                ? 'Add a passenger'
                : 'Edit Passenger Details'
            }
            subtitle={
              passenger === undefined
                ? 'Back to Manage Passengers'
                : 'Back to Trip details'
            }
            action={() => navigate(-1)}
            color={solidColors.midnight}
            subtitleColor={solidColors.upBlue}
          />
        </ContainerStyled>
        <PassengerInformationForm
          passengerInfoTitle={passengerInfoTitle}
          passengerInfoSubtitle={passengerInfoSubtitle}
          prefixDropdown={prefixDropdown}
          suffixDropdown={suffixDropdown}
          genderDropdown={genderDropdown}
          firstNameFormField={firstNameFormField}
          middleNameFormField={middleNameFormField}
          lastNameFormField={lastNameFormField}
          birthdayFormField={birthDateFormField}
          weightFormField={weightFormField}
          isDateError={dateError}
          dateErrorMessage={dateErrorMessage}
          isGenderError={genderError}
          handleGenderValidation={handleGenderError}
          handleBirthdayValidation={
            handleBirthdayValidation
          }
          existingPassenger={!!passenger}
        />
        <DayOfTraveAlertsForm
          dayOfTravelTitle={dayOfTravelTitle}
          dayOfTravelSubtitle={dayOfTravelSubtitle}
          phoneFormField={phoneFormField}
          emailFormField={emailFormField}
          getEmailError={handleEmailError}
          getPhoneError={handlePhoneError}
        />
        <TextGroupSwitch
          copy={switchTextGroup.title}
          checked={favorite}
          onChange={handleIsFavorite}
          direction={
            (switchTextGroup.alignmentState as
              | 'left'
              | 'right') ?? 'left'
          }
          color={solidColors[switchColorType ?? 'midnight']}
          textStyle={
            typography[switchTypographyType ?? 'body1']
          }
        />
        <Divider />
        <PageActionButtonContainer>
          <Button
            variant="secondary"
            onPress={() => navigate(-1)}
          >
            nevermind
          </Button>
          <Button
            variant="primary"
            onPress={handleSaveOnClick}
            isDisabled={
              firstName === '' ||
              gender === '' ||
              lastName === '' ||
              birthday.length != 8 ||
              isFormNotModified() ||
              emailError ||
              phoneError
            }
          >
            save
          </Button>
        </PageActionButtonContainer>
      </>
    </div>
  );
}
