import styled from 'styled-components';
import { Button, Typography } from '@atoms';
import { solidColors } from '@constants/styles/colors.constants';

export const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
  position: relative;
  height: fit-content;
  padding: 50px;
  ${(props) => props.theme.breakpoints.down('md')} {
    padding: 0px;
  }
`;
export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const StyledTitleContainer = styled.div`
  margin: 10px 10px 20px 10px;
`;

export const StyledTitle = styled(Typography)``;

export const StyledLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  min-width: 250px;
`;

export const StyledInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 12px 12px;
  width: 100%;
`;

export const StyledCustomCheckbox = styled.div`
  margin: 0px 0px 12px;
`;

export const StyledTextGroupIconText = styled.div`
  padding: 12px 24px;
  border-bottom: 1px solid ${solidColors.mediumGray.color};
  width: 100%;

  &:first-of-type {
    border-top: 1px solid ${solidColors.mediumGray.color};
  }
`;

export const StyledAddEditButton = styled(Button)`
  padding-left: 22px;
  padding-right: 1rem;
  margin: 10px 0px;
  background-color: ${solidColors.upWhite.color};
  color: ${solidColors.upBlue.color};
`;
