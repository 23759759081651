import {
  CarouselListWrapper,
  CarouselListSlide,
} from '@atoms';
import { ICarouselList } from './CarouselList.interfaces';
import { WrapperInner } from './CarouselList.styles';

const CarouselList = ({ children }: ICarouselList) => {
  const activeSlides = Array.isArray(children) ? (
    children.map((card, index) => {
      const key = `${index}`;
      return (
        <CarouselListSlide key={key}>
          {card}
        </CarouselListSlide>
      );
    })
  ) : (
    <CarouselListSlide>{children}</CarouselListSlide>
  );

  return (
    <CarouselListWrapper data-name="CarouselList">
      <WrapperInner>{activeSlides}</WrapperInner>
    </CarouselListWrapper>
  );
};

export default CarouselList;
