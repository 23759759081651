import { CardAtom } from '@atoms';
import styled from 'styled-components';

export const CardFlightLegContainer = styled.div<{
  isMultiLeg?: boolean;
}>`
  display: flex;
  flex-direction: column;
  min-height: ${({ isMultiLeg }) =>
    isMultiLeg ? 'auto' : '255px'};
  min-width: 100%;
  width: auto;
  gap: 10px;
  height: auto;
  position: relative;
  ${({ isMultiLeg }) =>
    isMultiLeg ? 'margin-bottom: 50px;' : ''};
`;
