import { StyledCheckboxChecked } from './CheckboxChecked.styles';
import { ICheckboxChecked } from './CheckboxChecked.interfaces';
import { solidColors } from '@constants/styles/colors.constants';

export default function CheckboxChecked({
  background,
  borderColor,
  checkColor,
}: ICheckboxChecked) {
  return (
    <StyledCheckboxChecked
      $background={background}
      $borderColor={borderColor}
      $checkColor={checkColor}
      style={{ color: solidColors.upBlue.color }}
    />
  );
}
