import styled from 'styled-components';
import { CardAtom } from '@atoms';
import { solidColors } from '@constants/styles/colors.constants';
import { IMainContainer } from '@molecules/CardEvent/CardEvent.interfaces';

export const MainContainer = styled.div<IMainContainer>`
  height: ${({ height }) => height};
  width: ${({ width }) => width};
`;

export const FlexContainer = styled.div`
  display: flex;
`;

export const UpperSideCard = styled(CardAtom)`
  background-color: ${solidColors.mediumGray.color};
  border-radius: 2.5px 2.5px 0 0;
  max-width: none;
  height: 65%;
`;

export const BottomSideCard = styled(CardAtom)`
  border-radius: 0 0 2.5px 2.5px;
  max-width: none;
  height: 35%;
  display: flex;
`;

export const CardEventDetailContainer = styled.div`
  margin: 1rem;
`;
