import { Card as MuiCard } from '@library';
import styled from 'styled-components';
import { styleConstants } from '@constants/styles/styles.constants';

//Omar: customizing material-ui components using styled-components
// syntax is good old SCSS with direct values or optional property injection from a constant

const CardAtom = styled(MuiCard)<{
  $maxWidth?: string;
}>`
  max-width: ${({ $maxWidth }) => {
    return $maxWidth ?? styleConstants.card.maxWidth;
  }};
  border-radius: 8px;
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.12);
`;

export default CardAtom;
