export const getSimpleDateFormat = (date: Date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return (
    (month < 10 ? '0' + month : month) +
    '/' +
    (day < 10 ? '0' + day : day) +
    '/' +
    year
  );
};

export const getISODateTimeWithTimeZoneOffSet = (
  date: Date,
) => {
  const offset = date.getTimezoneOffset();
  return new Date(
    date.getTime() - offset * 60000,
  ).toISOString();
};

export const getDateFromMonthYearString = (
  monthYearString: string,
): Date => {
  const [monthString, yearString] = monthYearString.split(
    ' ',
  );
  // Parse the month string to get the month index (0-based)
  const monthIndex = new Date(
    `${monthString} 1, 2000`,
  ).getMonth();
  const date = new Date(parseInt(yearString), monthIndex);

  return date;
};

export const getMonthYearStringFromDate = (
  date: Date,
): string => {
  const month = date.toLocaleString('default', {
    month: 'long',
  });
  const year = date.getFullYear().toString();
  return `${month} ${year}`;
};

function getMonthName(month: string) {
  const monthNames: { [key: string]: string } = {
    '01': 'January',
    '02': 'February',
    '03': 'March',
    '04': 'April',
    '05': 'May',
    '06': 'June',
    '07': 'July',
    '08': 'August',
    '09': 'September',
    '10': 'October',
    '11': 'November',
    '12': 'December',
  };

  return monthNames[month];
}

export const generateMonthYearString = (date: string) => {
  const regex = /^(\d{4})-(\d{2})-\d{2}$/;
  const match = date.match(regex);
  let year = '';
  let month = '';
  if (match) {
    year = match[1];
    month = match[2];
  }
  return `${getMonthName(month)} ${year}`;
};

export const isDayBefore = (date1: Date, date2: Date) => {
  // Clone the input dates to avoid modifying the original dates
  const clonedDate1 = new Date(date1);
  const clonedDate2 = new Date(date2);

  // Reset the time to midnight (00:00:00) for both dates
  clonedDate1.setHours(0, 0, 0, 0);
  clonedDate2.setHours(0, 0, 0, 0);

  // Compare the dates at a day level
  return clonedDate1.getTime() < clonedDate2.getTime();
};

export const formatDateToYYYYMMDD = (
  date: Date,
): string => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1)
    .toString()
    .padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');

  return `${year}-${month}-${day}`;
};

export const getLastDayOfMonth = (date: Date) => {
  const lastDay = new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    0,
  );
  return lastDay.toISOString().slice(0, 10);
};
