import styled from 'styled-components';
import { solidColors } from '@constants/styles/colors.constants';
import { theme } from '@constants/styles/theme.constants';
import {
  CardActions,
  CardContent,
  CardHeader,
} from '@library';
import { Button, CardAtom, ImageImgix } from '@atoms';

export const CardAtomStyled = styled(CardAtom)<{
  width?: string;
  height?: string;
  leftOffset?: string;
  $isModalView?: boolean;
}>`
  border-radius: 8px !important;
  min-width: 21.25rem;
  max-height: 22rem;
  height: ${({ height }) => height ?? 'auto'};
  width: ${({ width }) => width ?? '21.25rem'};
  max-width: ${({ width }) => width ?? '68rem'};
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${({ $isModalView }) => {
    if ($isModalView) {
      return `width: 22rem;
        margin: 45vh auto;
        `;
    }
  }}

  ${(props) => props.theme.breakpoints.down('md')} {
    min-width: ${({ $width }) => $width ?? '100%'};
    max-width: 100%;
  }

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    min-width: 335px;
  }
`;

export const HeadlineStyled = styled.div`
  flex-wrap: nowrap;
  white-space: pre-line;
`;

export const SubCopyStyled = styled.div`
  margin: 0.25rem 0 0.5rem 0;

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    margin-top: 0.5rem;
  }
`;

export const HeaderStyled = styled(CardContent)`
  padding: 0 1rem 0 1rem !important;
`;

export const CardHeaderStyled = styled(CardHeader)`
  height: 1rem;
  padding: 0.2rem;
  padding-bottom: 0;
  margin-top: 1rem;
`;

export const CTAContainerStyled = styled(CardActions)`
  display: flex;
  justify-content: space-between;
  margin: 0 1rem 0 1rem;
`;

export const ButtonStyled = styled(Button)<{
  $multi?: boolean;
  $isModalView?: boolean;
}>`
  margin: 1rem 0 0 0 !important;
  ${({ $multi }) => !$multi && 'width: 100%;'};
  height: 45px;
  border-radius: ${({ $multi }) => ($multi ? '8px' : '0')};
  padding: 1rem 1rem 1rem 1rem;
  display: flex;
  justify-content: ${({ $multi, $isModalView }) =>
    $multi && !$isModalView ? 'flex-start' : 'center'};
`;

export const SingleButton = styled(Button)`
  margin: 0 0 0 1.5rem;
  width: fit-content;
  height: 40px;
  display: flex;
  justify-content: flex-start;
  padding: 1rem 0 1.5rem 0;
`;

export const ButtonPrimaryStyled = styled(Button)`
  border-right: solid 1px ${solidColors.mediumGray.color};
`;

export const StyledImage = styled(ImageImgix)`
  margin-bottom: 1rem;

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    margin-bottom: 1.5rem;
  }
`;

export const ImageContainerStyled = styled.div`
  display: flex;
  justify-content: center;
`;

export const SingleButtonContainer = styled.div`
  margin-top: 1rem;

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    margin-bottom: 0.5rem;
  }
`;
