import { Typography } from '@atoms';
import {
  Color,
  solidColors,
} from '@constants/styles/colors.constants';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import styled from 'styled-components';

export const Container = styled.div<{
  $paddingTop?: number;
}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 80px;
  width: fit-content;
  position: relative;
  ${({ $paddingTop }) => {
    return $paddingTop
      ? `padding-top: ${$paddingTop}rem`
      : '';
  }};
`;

export const BackArrowCopyContainer = styled.div`
  margin-left: -10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: fit-content;
  width: fit-content;
  cursor: pointer;
`;

export const BackArrowContainer = styled.div<{
  $hover: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;

  ${({ $hover }) => {
    if ($hover) {
      return `
                background-color: ${solidColors.blue5Percent.color};
                justify-content: center;
                align-items: center;
                transition: ease-in-out 0.2s;
            `;
    }
  }}
`;

export const BackArrow = styled(ArrowBackIosRoundedIcon)<{
  $color: Color;
  $hover: boolean;
}>`
  height: 15px;
  width: 15px;
  color: ${({ $color, $hover }) =>
    $hover ? solidColors.upBlue : $color.color};
  background-color: transparent;
  cursor: pointer;
`;
