import { useAppSelector } from '@app/hooks';
import { TextGroupCheckboxBodyCopyLink } from '@components/molecules';
import {
  setIsMultiLeg,
  setSelectedFLights,
} from '@features/MyTrips/MyTripsCancelSlice';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  IFlightLegItemSelectionInfo,
  ISubPageFlightLegSelection,
} from './SubPageFlightLegSelection.interfaces';

export default function SubPageFlightLegSelection({
  legs,
}: ISubPageFlightLegSelection) {
  const dispatch = useDispatch();

  const { selectedFlights } = useAppSelector(
    (state) => state.myTripsCancel,
  );

  const handleSelection = (
    e: React.ChangeEvent<HTMLInputElement>,
    flightId: string,
  ) => {
    if (e.target.checked) {
      dispatch(
        setSelectedFLights([...selectedFlights, flightId]),
      );
    } else {
      dispatch(
        setSelectedFLights(
          selectedFlights.filter((fid) => {
            return fid !== flightId;
          }),
        ),
      );
    }
  };

  useEffect(() => {
    if (legs.length > 1) dispatch(setIsMultiLeg(true));
    else dispatch(setIsMultiLeg(false));
  }, [legs]);

  return (
    <div>
      {legs?.map((i: IFlightLegItemSelectionInfo) => {
        return (
          <TextGroupCheckboxBodyCopyLink
            key={i.flightId}
            titleTextGroup={i.textGroup.data.titleTextGroup}
            isPreselected={
              i.textGroup.data.isPreselected ||
              selectedFlights.includes(i.flightId)
            }
            onChange={(e) => handleSelection(e, i.flightId)}
          />
        );
      })}
    </div>
  );
}
