import { StyledCheckboxUnchecked } from './CheckboxUnchecked.styles';
import { ICheckboxUnchecked } from './CheckboxUnchecked.interfaces';
import { solidColors } from '@constants/styles/colors.constants';

export default function CheckboxUnchecked({
  background,
  borderColor = solidColors.mediumGray.color,
}: ICheckboxUnchecked) {
  return (
    <StyledCheckboxUnchecked
      $background={background}
      $borderColor={borderColor}
      style={{ color: borderColor }}
    />
  );
}
