import React, { useState } from 'react';
import { Typography } from '@atoms';
import {
  Color,
  solidColors,
} from '@constants/styles/colors.constants';
import { typography } from '@constants/styles/typography.constants';
import {
  BackArrow,
  BackArrowContainer,
  BackArrowCopyContainer,
  Container,
} from './HeadlineSubtitleWithBackArrow.styles';
import { trackAnalytics } from '@services/mixpanelService/mixpanel.service';

export interface IHeadlineSubtitleWithBackArrow {
  headline: string;
  subtitle: string;
  color?: Color;
  subtitleColor?: Color;
  action?: (e?: React.MouseEvent) => void;
  paddingTop?: number;
}

function HeadlineSubtitleWithBackArrow({
  headline,
  subtitle,
  color = solidColors.upWhite,
  subtitleColor = solidColors.upWhite,
  action,
  paddingTop,
}: IHeadlineSubtitleWithBackArrow) {
  const [hover, setHover] = useState(false);
  const handleAction = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (action) {
      trackAnalytics({
        trackingTitle: 'Select_BackArrow_Button',
        properties: [],
      });
      action();
    }
  };

  const handleMouse = () => {
    setHover(!hover);
  };

  return (
    <Container
      $paddingTop={paddingTop}
      data-name={'HeadlineSubtitleWithBackArrow'}
    >
      <BackArrowCopyContainer
        onClick={action}
        onMouseEnter={handleMouse}
        onMouseLeave={handleMouse}
      >
        <BackArrowContainer
          $hover={hover}
          aria-label="back"
          onClick={handleAction}
        >
          <BackArrow
            $hover={hover}
            $color={solidColors.upBlue}
          />
        </BackArrowContainer>
        <Typography
          variant={typography.heading04Small}
          color={
            color !== undefined
              ? subtitleColor
              : solidColors.upBlue
          }
        >
          {subtitle}
        </Typography>
      </BackArrowCopyContainer>
      <Typography
        variant={typography.heading01Large}
        color={
          color !== undefined ? color : solidColors.midnight
        }
      >
        {headline}
      </Typography>
    </Container>
  );
}

export default HeadlineSubtitleWithBackArrow;
