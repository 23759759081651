import styled from 'styled-components';
import { CardAtom, Typography, Button } from '@atoms';
import { solidColors } from '@constants/styles/colors.constants';
import { theme } from '@constants/styles/theme.constants';

export const CardOversizedWrapper = styled(CardAtom)<{
  $styleOverrides?: boolean;
}>`
  display: flex;
  align-items: center;
  height: 31.25rem;
  ${({ $styleOverrides }) =>
    $styleOverrides
      ? `
                min-width: 1088px;
                width: 100%;
            `
      : 'min-width: 100%;'}
  ${(props) =>
    props.theme.breakpoints.down(
      theme.breakpoints.values.lg,
    )} {
    ${({ $styleOverrides }) =>
      $styleOverrides && 'min-width: 960px;'}
  }
  ${(props) =>
    props.theme.breakpoints.down(
      theme.breakpoints.values.md,
    )} {
    ${({ $styleOverrides }) =>
      $styleOverrides && 'min-width: 500px;'}
    flex-direction: column-reverse;
    height: auto;
  }
  ${(props) =>
    props.theme.breakpoints.down(
      theme.breakpoints.values.md,
    )} {
    ${({ $styleOverrides }) =>
      $styleOverrides && 'min-width: 368px;'}
  }
`;

export const CardContainer = styled.div`
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 100%;

    ${(props) =>
      props.theme.breakpoints.down(
        theme.breakpoints.values.md,
      )} {
            height: auto;
        }
    @media (max-width: 600px) {
`;

export const CardBody = styled(Typography)`
  margin-top: 0.5rem;
  margin-bottom: 2.5rem;
  flex-grow: 1;
  overflow: auto;

  ${(props) =>
    props.theme.breakpoints.down(
      theme.breakpoints.values.md,
    )} {
    display: block;
    margin-top: 8px;
    margin-bottom: 1.5rem;
  }
`;

export const FirstSideCard = styled.div<{
  $mobileWidth?: number;
}>`
  margin: auto 2rem auto 2rem;
  width: 100%;
  max-height: 500px;

  ${(props) =>
    props.theme.breakpoints.down(
      theme.breakpoints.values.md,
    )} {
    width: 100%;
    max-width: 100%;
    border-radius: 0 0 5px 5px;
    padding: 1.5rem 1rem;
    margin: 0;
    max-width: ${({ $mobileWidth }) =>
      $mobileWidth
        ? `${$mobileWidth}px`
        : '100%'} !important;
  }

  ${(props) =>
    props.theme.breakpoints.down(
      theme.breakpoints.values.sm,
    )} {
    padding: 0;
  }
`;

export const OversizedSideCard = styled.div`
  background-color: ${solidColors.mediumGray.color};
  border-radius: 0 5px 5px 0;
  width: 100%;

  ${(props) =>
    props.theme.breakpoints.down(
      theme.breakpoints.values.md,
    )} {
    width: 100%;
    max-width: 100%;
    height: 300px;
    overflow: hidden;
    border-radius: 5px 5px 0 0;
  }
`;

export const ResponsiveButton = styled(Button)`
  ${(props) =>
    props.theme.breakpoints.down(
      theme.breakpoints.values.sm,
    )} {
    width: 100%;
    border-radius: 0px 0px 8px 8px;
  }
`;

export const TextContainer = styled.div`
  ${(props) =>
    props.theme.breakpoints.down(
      theme.breakpoints.values.sm,
    )} {
    padding: 1.5rem 1rem 0 1rem;
  }
`;
