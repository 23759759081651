import { useFeatureFlags } from '@services/featureFlagService/featureFlagService.services';
import { TSAccountProtectionProvider } from '@transmit-security/riskid-reactjs-ts';
import { FlagsProvider } from 'flagged';
import ThemeWrapper from '@theme/ThemeWrapper';
import { AppProvider, BackgroundProvider } from '@services/contextService/context';
import { FlightSearchProvider } from '@components/molecules/SubPageFlightSearchV2/FlightSearchContext/FlightSearchContext';
import ProviderWrapper from '@components/utils/ProviderWrapper';
import CardSnackbar from '@components/molecules/CardSnackbar/CardSnackbar';
import { MemberTabPopup } from '@components/molecules';
import Router from '@routing/router';
import DevSettings from '@features/DevSettings/DevSettings';
import { FeatureFlags } from '@services/featureFlagService/constants';
import { isProd } from '@services/envService/envService';

const LayoutWrapper = () => {
  let transmit =
  '45623403.cea22533.h6xk30vz68g7tpgn4pnuk.riskid.security';
  if (isProd()) {
    transmit =
    'ce0323c8.898df8fa.3bndsusuaxgp6sgvezplh.riskid.security';
  }
  const {data: azureFlags, error} = useFeatureFlags();

  return (
    <>
      <TSAccountProtectionProvider clientId={transmit}>
        <FlagsProvider
          features={
            !error && azureFlags
              ? azureFlags
              : Object.entries(FeatureFlags).reduce((acc, [key, value]) => ({
                  ...acc,
                  [value]: false
                }), {})
            }
        >
          <ThemeWrapper>
            <BackgroundProvider>
              <FlightSearchProvider>
                <AppProvider>
                  <ProviderWrapper>
                    <CardSnackbar />
                    <MemberTabPopup />
                    {
                      !isProd() ? ( 
                        <DevSettings /> 
                      ) : null
                    }
                    <Router />
                  </ProviderWrapper>
                </AppProvider>
              </FlightSearchProvider>
            </BackgroundProvider>
          </ThemeWrapper>
        </FlagsProvider>
      </TSAccountProtectionProvider>
    </>
  );
};

export default LayoutWrapper;
