import styled from 'styled-components';
import { CardAtom } from '@atoms';
import { CardHeader, Icons } from '@library';
import { solidColors } from '@constants/styles/colors.constants';
import PageBackground from '@atoms/PageBackground/PageBackground';
import { theme } from '@constants/styles/theme.constants';
import { Elevation } from '@wheelsup/wu-react-components';

export const PageBackgroundStyled = styled(PageBackground)`
  height: 100vh;
`;

export const CardContainer = styled.div`
  margin-top: 12rem;
  text-align: center;
  position: relative;
`;

export const CardStyled = styled(Elevation)`
  max-width: 42rem !important;
  margin: 32px auto 0 auto;

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    max-width: 23rem !important;
  }
`;

export const CardHeaderStyled = styled(CardHeader)`
  display: table;
  padding-bottom: 0rem;
`;

export const BackIconStyled = styled(Icons.ArrowBack)`
  z-index: 2;
  font-size: 2rem;
  color: ${solidColors.upBlue.color};
`;

export const PlaneImg = styled.img`
  position: absolute;
  right: 67%;
  z-index: 1;
  pointer-events: none;
  @media (max-width: 1500px) {
    right: 71%;
  }
  @media (max-width: 1200px) {
    right: 75%;
  }
  @media (max-width: ${theme.breakpoints.values.md}px) {
    right: 100%;
  }
`;
