import { CardAtom } from '@atoms';
import { surfaceColors } from '@constants/styles/colors.constants';
import styled from 'styled-components';

export const BackgroundWrapper = styled.div<{
  blueBackground?: boolean;
  image?: string;
}>`
  height: 100%;
  display: flow-root;
  background-color: ${({ blueBackground }) =>
    blueBackground
      ? surfaceColors.backgroundMidnight.color
      : 'transparent'};
  background-image: ${({ image }) =>
    image && `url(${image})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  z-index: 0;
  width: 100vw;
  margin-left: 50%;
  transform: translateX(-50%);
`;

export const ChildContainer = styled.div`
  z-index: 1;
  position: relative;
  background: transparent;
`;

export const CardContainer = styled(CardAtom)`
  background-color: #ffffff;
  min-width: 100%;
  position: relative;
  height: fit-content;
  padding: 24px 32px;
`;
