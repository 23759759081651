import { useState, useEffect } from 'react';

interface IViewport {
  width: number;
  height: number;
}

export default function useViewport(): IViewport {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleWindowResize = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleWindowResize);
    return () =>
      window.removeEventListener(
        'resize',
        handleWindowResize,
      );
  }, []);
  return { width, height };
}
