import React, { useEffect, useState } from 'react';
import { IImgTitleCopyStepperData } from '@molecules/ImageTitleSubtitleStepperListItemView/ImageTitleSubtitleStepperListItemView.interfaces';
import { ImageTitleSubtitleStepperListItemView } from '@molecules';
import { IFlightLegItemLuggageInfoItem } from './FlightLegItemLuggageInfo.interfaces';

import { useDispatch } from 'react-redux';
import { useAppSelector } from '@app/hooks';
import {
  setHasDataActive,
  setLuggages,
  setTotalLuggagesPerLeg,
  setSameLuggagesFlag,
} from '@features/Fly/FlySearchTripDetailsSlice';
import { cloneDeep } from 'lodash';
import {
  IErrorPayload,
  setErrorPayload,
} from '../Error/ErrorSlice';
import {
  AddLuggageButtonContainer,
  LuggageItemsContainer,
  LuggageWrapperContainer,
  LuggageInputStyle
} from './FlightLegItemLuggageInfo.styles';
import { ButtonStyled } from '../FlightLegItemPassengerInfo/FlightLegItemPassengerInfo.styles';
import { useNavigate } from 'react-router-dom';
import TextGroupSwitch from '../TextGroupSwitch/TextGroupSwitch';
import { solidColors } from '@constants/styles/colors.constants';
import { trackAnalytics } from '@services/mixpanelService/mixpanel.service';
import { IKeyValue } from '@components/types';
import { TextInput, WidthOptions } from '@wheelsup/wu-react-components';
import { setLuggageErrorData, shouldShowLuggageInput } from './helper';

const FlightLegItemLuggageInfo = ({
  maximumTotalLuggage,
  flightItemTitle,
  listOfLuggageItems,
  sameLuggageForAllFlightsCopy,
  luggageCheckedAction,
  addLuggageCopy,
  addLuggageLeadingIcon,
  setHasMaxLuggage,
  quantityOfLegs,
  index,
  isWithinChangeWindow,
  flightId,
  isStepperVertical = false,
}: IFlightLegItemLuggageInfoItem) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { luggages, sameLuggagesFlag } = useAppSelector(
    (state) => state.searchTripDetails,
  );

  const luggagesStateData = useAppSelector(
    (state) => state.searchTripDetails,
  );

  const { errorPayload, error } = useAppSelector(
    (state) => state.flightLegItemLuggageInfo,
  );

  const luggagesCount = listOfLuggageItems?.map(
    (e) => e.data.stepper.defaultValue as number,
  );

  const [
    luggageStepperData,
    setLuggageStepperData,
  ] = useState<IImgTitleCopyStepperData[]>([]);

  const [lugsTotalCount, setLugsTotalCount] = useState(() =>
    luggagesCount?.reduce((a: number, b: number) => a + b),
  );

  const [
    hasMaxLuggageCapacity,
    setHasMaxLuggageCapacity,
  ] = useState(false);

  useEffect(() => {
    const hasLuggages =
      luggages[index]?.luggageData?.length > 0;
    setLuggageStepperData(
      hasLuggages
        ? luggages[index].luggageData
        : listOfLuggageItems,
    );
    const currNumLuggage =
      luggagesStateData?.luggagesPerLeg[index];
    setHasMaxLuggageCapacity(
      currNumLuggage >= maximumTotalLuggage ? true : false,
    );
    setHasMaxLuggage(
      currNumLuggage >= maximumTotalLuggage ? true : false,
    );
    luggagesCountHandle(
      hasLuggages
        ? luggages[index].luggageData
        : listOfLuggageItems,
    );
  }, [lugsTotalCount, luggages, listOfLuggageItems]);

  const luggagesCountHandle = (array: any[]) => {
    const total = array.reduce(
      (a, b) => a + (b.data.stepper.defaultValue || 0),
      0,
    );
    setLugsTotalCount(total);
    const dataPerLeg = {
      index: index,
      totalPerLeg: total,
    };
    dispatch(setTotalLuggagesPerLeg(dataPerLeg));
  };

  const getDataCount = async (
    count: number,
    i: number,
    type?: string,
  ) => {
    const cloneLuggagesData = cloneDeep(luggageStepperData);
    luggagesCountHandle(cloneLuggagesData);
    const newListOfLuggageItems = luggageStepperData?.map(
      (l, idx) => {
        if (idx !== i) {
          return l;
        }
        return {
          ...l,
          data: {
            ...l.data,
            error: setLuggageErrorData(l, count),
            stepper: {
              ...l.data.stepper,
              defaultValue: count,
            },
          },
        };
      },
    );
    luggagesCountHandle(newListOfLuggageItems);
    setLuggageStepperData(newListOfLuggageItems);
    index !== 0 &&
      sameLuggagesFlag &&
      dispatch(setSameLuggagesFlag(false));
    // case 1: sameLuggages is true, index is 0 - should update all legs
    if (sameLuggagesFlag && index === 0) {
      dispatch(
        setLuggages({
          luggages: newListOfLuggageItems,
          index: null,
          flightId: null,
        }),
      );
    } else {
      // case 2: sameLuggages is true, index is not 0 - should update index leg and remove same luggage flag
      // case 3: sameLuggages is false - should update index leg
      dispatch(
        setLuggages({
          luggages: newListOfLuggageItems,
          flightId: flightId,
          index: index,
        }),
      );
    }

    !luggagesStateData.hasDataActive &&
      dispatch(setHasDataActive(true));
  };

  const addLuggageAction = () => {
    const actionAnalytics: unknown = {
      key: 'Page Name',
      value: 'My Trip',
    };
    trackAnalytics({
      trackingTitle: 'Select_ManageLuggageResPage_CTA',
      properties: [actionAnalytics] as IKeyValue[],
    });
    navigate(`/manage-luggage/${flightId}`);
  };

  const isManageLuggage = window.location.pathname.includes(
    'manage-luggage',
  );
  const isBooking = window.location.pathname.includes(
    'fly',
  );

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>, i: number) => {
    const newListOfLuggageItems = luggageStepperData?.map(
      (l, idx) => {
        if (idx !== i) {
          return l;
        }
        return {
          ...l,
          data: {
            ...l.data,
            note: e.target.value,
            error: !e.target.value ? true : false
          },
        };
      },
    );

    setLuggageStepperData(newListOfLuggageItems);

    dispatch(
      setLuggages({
        luggages: newListOfLuggageItems,
        flightId: flightId,
        index: index,
      }),
    );
  };

  const isLuggageInputDisabled = (count: number | undefined) => {
    if (count !== undefined && count > 0) {
      return false;
    }

    return true;
  };

  const renderListOfLuggageItems = () => {
    return (
      <>
        {luggageStepperData?.map((item, i) => {
          const key = `${item.data.codeValue}-${i}`;
          return (
            <React.Fragment key={key}>
              <LuggageWrapperContainer>
                <ImageTitleSubtitleStepperListItemView
                  title={item.data.title}
                  headerSize="1rem"
                  copySize="1rem"
                  subtitle={item.data.subtitle}
                  image={item.data.image}
                  stepper={{
                    ...item.data.stepper,
                    hideControls: isManageLuggage
                      ? false
                      : item.data.stepper.hideControls,
                  }}
                  getDataCount={(state) =>
                    getDataCount(
                      state,
                      i,
                      item.data.codeValue,
                    )
                  }
                  hasMaxCapacity={hasMaxLuggageCapacity}
                  buttonSize="small"
                  isWithinChangeWindow={isWithinChangeWindow}
                  isStepperVertical={isStepperVertical}
                />
                  {shouldShowLuggageInput(item) &&
                    <LuggageInputStyle>
                      <TextInput
                        variant='large'
                        wrapperWidth={WidthOptions.INHERIT}
                        name='Luggage Details'
                        label='Luggage details (required)'
                        autoComplete='off'
                        isDisabled={isLuggageInputDisabled(item.data.stepper.defaultValue)}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOnChange(e, i)}
                        value={item.data.note}
                        errorMessage={item.data.error}
                      />
                    </LuggageInputStyle>
                  }
              </LuggageWrapperContainer>
              {isBooking &&
                sameLuggageForAllFlightsCopy &&
                i === listOfLuggageItems.length - 1 &&
                quantityOfLegs > 1 && (
                  <div
                    className="checkbox-wrapper"
                    style={{
                      border: 'none',
                    }}
                  >
                    <TextGroupSwitch
                      onChange={luggageCheckedAction}
                      checked={sameLuggagesFlag}
                      copy={sameLuggageForAllFlightsCopy}
                      direction={'right'}
                      color={solidColors.midnight}
                    />
                  </div>
                )}
            </React.Fragment>
          );
        })}
      </>
    );
  };

  useEffect(() => {
    if (error) {
      dispatch(
        setErrorPayload(errorPayload as IErrorPayload),
      );
    }
  }, [errorPayload, error, luggagesStateData.luggages]);

  return (
    <LuggageItemsContainer
      data-id="LuggageItemsContainer-FlightLegItemLuggageInfo"
      $isVertical={isStepperVertical}
      // 74 is the height of the luggage item + margin
      $minHeight={`${74 * listOfLuggageItems.length}px`}
    >
      {renderListOfLuggageItems()}
      {isWithinChangeWindow &&
        !isManageLuggage &&
        !isBooking && (
          <AddLuggageButtonContainer>
            <ButtonStyled
              title={addLuggageCopy ?? 'Add/Remove Luggage'}
              styleType="text_with_icon_leading"
              image={addLuggageLeadingIcon}
              action={{
                actionMethod: addLuggageAction,
              }}
            />
          </AddLuggageButtonContainer>
        )}
    </LuggageItemsContainer>
  );
};

export default FlightLegItemLuggageInfo;
